import React from 'react'
import { CompanyActions } from './actions'
import { ICompanyState, initialStateCompany } from './reducers'

export const CompanyContext = React.createContext<{
  state: ICompanyState
  dispatch: React.Dispatch<CompanyActions>
}>({
  state: initialStateCompany,
  dispatch: () => undefined
})
