import { TextField, TextFieldProps, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

type SearchInputProps = {
  enteredFilter: string
  setEnteredFilter: Function
  props?: TextFieldProps
}

export const SearchInput = ({
  enteredFilter,
  setEnteredFilter,
  props
}: SearchInputProps) => {
  const classes = useStyles()
  return (
    <TextField
      id='input-with-icon-grid'
      InputProps={{
        classes: {
          root: classes.searchTextField
        },
        disableUnderline: true,
        autoFocus: false
      }}
      InputLabelProps={{
        classes: {
          root: classes.root
        },
        shrink: false
      }}
      value={enteredFilter}
      autoFocus
      fullWidth
      placeholder='Buscar empresa'
      onChange={(e: any) => setEnteredFilter(e.target.value)}
      {...props}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.hint,
    '&.Mui-focused': {
      color: 'transparent'
    }
  },
  searchTextField: {
    marginLeft: '5px',
    color: theme.palette.text.hint,
    // fontWeight: theme.typography.fontWeightRegular,
    fontSize: '16px'
  },
  focused: {}
}))
