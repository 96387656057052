import React, { useRef, useState } from "react";
import { Button, Grid, InputAdornment, makeStyles, Menu, MenuItem, Typography, withStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";

import { PrimaryButton } from '../button/PrimaryButton';
import InputSearch from "../Inputs/InputSearch";
import { getImageFromList } from '../../assets/Icons';
import { ProgressBar } from "../../components/ProgressBar/ProgressBar";
import { ItemsList } from "./List/ItemsList";

interface jsonColor {
  primaryColor?: string,
  secondaryColor?: string,
  buttonColor?: string,
  textButtonColor?: string,
}

type MassiveLoadResultProps = {
  sucessfull?: number,
  replaced?: number,
  error?: number,
  onDownloadErrors: (any),
  //items?: Array<StateLoad | undefined>,
  items?: Array<any | undefined>,
  onContinue: (any),
  btnActive: boolean,
  personalizationColor?:jsonColor
}
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    background: "#FFFFFF !important",
  },
})((props: any) => (
  <Menu
    open={props.open}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const MassiveLoadResult = (props: MassiveLoadResultProps) => {
  const [search, setSearch] = useState<string | undefined>("");
  const searchRef = useRef<{ value: string }>(null);
  const classes = useStyles({personalizationColor:props.personalizationColor});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [itemsList, setItemsList] = useState<Array<any | undefined>>();
  const [red, setRed] = useState("");
  const [yellow, setYellow] = useState("");
  const [green, setGreen] = useState("");
  const options= [
    {
      label: "Reemplazados",
    },
    {
      label: "Errores",
    },
    {
      label: "Limpiar filtro",
    }
  ];

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      "&:focus": {
        backgroundColor: props.personalizationColor?.primaryColor! !== "" ? props.personalizationColor?.primaryColor! : theme.palette.primary.light,
        color: theme.palette.common.white,
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.common.white,
        },
      },
      "& .MuiListItem-button": {
        "&:hover": {
          backgroundColor: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  React.useEffect(() => {
    var total = props.sucessfull! + props.replaced! + props.error!;
    if (props.sucessfull) setGreen((props.sucessfull * 100 / total).toString());
    if (props.replaced) setYellow((props.replaced * 100 / total).toString());
    if (props.error) setRed((props.error * 100 / total).toString());
    setItemsList(props.items);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (_event: any, index: any) => {    
    setSelectedIndex(index);
    if(index==0) setItemsList(props.items?.filter(n => n?.state == "reemplazado"));
    else if(index==1) setItemsList(props.items?.filter(n => n?.state == "erroneo"));
    else if(index==2) setItemsList(props.items);
    setAnchorEl(null);
  };
  
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const filterList = () => {
    if (searchRef.current?.value!?.length < 1) {
      setItemsList(props.items);
    } else {
      let name: string = searchRef.current?.value!;
      setItemsList(props.items?.filter(n => n?.name.toLowerCase().includes(name.toLowerCase())));
    }
  }

  return (
    <Grid container>
      <Grid container style={{ paddingTop: "17px" }} spacing={3} direction="row">
        <Grid item>
          <Grid container spacing={1} direction="row">
            <Grid item>
              <img src={getImageFromList('successIcon')} alt="success" className={'imageInfo'} />
            </Grid>
            <Grid item>
              <Typography className={classes.messageTypography}>
                Exitosos: {props.sucessfull}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={1} direction="row">
            <Grid item>
              <img src={getImageFromList('refreshIcon')} alt="replaced" className={'imageInfo'} />
            </Grid>
            <Grid item>
              <Typography className={classes.messageTypography}>
                Reemplazados: {props.replaced}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={1} direction="row">
            <Grid item>
              <img src={getImageFromList('errorIcon')} alt="errors" className={'imageInfo'} />
            </Grid>
            <Grid item>
              <Typography className={classes.messageTypography}>
                Errores: {props.error}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ paddingTop: "17px" }} direction="row">
        <ProgressBar done={"100"} multipleColor={true} green={green} yellow={yellow} red={red} title='' />
      </Grid>
      <Grid container style={{ paddingTop: "34px" }} direction="row">
        <Grid item xs={6}>
          <Typography className={classes.message}>
            Detalle del cargue
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1} direction="row">
            <Grid item xs={9} lg={11} md={11} sm={11}>
              <InputSearch
                name="filtro"
                value={search}
                inputRef={searchRef}
                disabled={false}
                onChange={() => {
                  setSearch(
                    searchRef.current ? searchRef.current.value : undefined
                  );
                  filterList();
                }}               
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search style={{ color: "#C6C6C6" }} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                margin="dense"
                fullWidth
                classes={{root:classes.inputPropsColor}}
              />
            </Grid>
            <Grid item xs={1}>
              <Button style={{ marginTop: "19px", padding:"10px  10px" }} onClick={handleClick}><img src={getImageFromList('filterIcon')} /></Button>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {options.map((option: any, index: any) => (
                  <StyledMenuItem
                    key={option.label}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option.label}
                  </StyledMenuItem>
                ))}
              </StyledMenu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ paddingTop: "20px" }}>
        <div className={classes.itemList}>
          <ItemsList list={itemsList}></ItemsList>
        </div>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: "24px" }} justify="space-between">
        <Grid item>
          <PrimaryButton 
            title={'Descargar informe de errores'} 
            fullWidth={false} 
            personalizationColor={{primaryColor:'',secondaryColor:'',buttonColor:'white',textButtonColor: props.personalizationColor?.buttonColor! !=="" ? `${props.personalizationColor?.buttonColor}`:"rgb(29, 113, 184)"}}
            props={{ onClick: props.onDownloadErrors, style: { width: "270px", textTransform: "capitalize", border: props.personalizationColor?.buttonColor! !== "" ?  `1px solid ${props.personalizationColor?.buttonColor}`: "1px solid rgb(29, 113, 184)" }, startIcon: (<img src={getImageFromList('downloadIcon')} alt="downloadIcon"/>)}}></PrimaryButton>
        </Grid>
        <Grid item>
          {!props.btnActive ? (<PrimaryButton title={'Continuar'} fullWidth={false} personalizationColor={props.personalizationColor} props={{ onClick: props.onContinue, style: { width: "200px", textTransform: "capitalize"}, disabled:(((props.sucessfull==undefined?0:props.sucessfull)+(props.replaced==undefined?0:props.replaced))>0?false:true)}}></PrimaryButton>)
          :
          (<PrimaryButton
            title={'Continuar'}
            props={{onClick: props.onContinue,
                    disabled: true,
                  }}
            fullWidth={false}
        /> )
          }
        {/* <PrimaryButton title={'Continuar'} fullWidth={false} props={{ onClick: props.onContinue, style: { width: "200px", textTransform: "capitalize"}, disabled:(((props.sucessfull==undefined?0:props.sucessfull)+(props.replaced==undefined?0:props.replaced))>0?false:true)}}></PrimaryButton> */}
          {/* <PrimaryButton
              title={'Continuar'}
              props={{onClick: props.onContinue,
                      disabled: (((props.sucessfull== undefined ? 0 :props.sucessfull)+ (props.replaced == undefined ? 0 :props.replaced)) > 0 ? false:true),
                    }}
              fullWidth={false}
          /> */}
        {/* <PrimaryButton 
            title={'Continuar'} 
            fullWidth={false}
            props={{ 
              onClick: props.onContinue, 
              style: { width: "200px", textTransform: "capitalize" },
              disabled:(((props.sucessfull==undefined?0:props.sucessfull)+(props.replaced==undefined?0:props.replaced))>0?false:true)}}            
          >            
          </PrimaryButton> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MassiveLoadResult;

const useStyles = makeStyles((theme: any) => ({
  messageTypography: {
    marginTop: 4,
    color: '#6D6E71',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
  },
  itemList: {
    overflowY: "scroll",
    overflowX: "hidden",
    width: "110%",
    maxHeight: "300px",
  },
  message: (props: any) => ({
    marginTop: 27,
    color: props.personalizationColor.primaryColor! !== "" ? props.personalizationColor.primaryColor : theme.palette.primary.main,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
  }),
  //Borde inputSearch variante outlined
  inputPropsColor: (props: any) => ({
    color:'white',
    "& .Mui-focused fieldset": {
      borderColor: props.personalizationColor.primaryColor! !== "" ?  `${props.personalizationColor.primaryColor} !important` :"#1D71B8 !important",
      borderWidth: '2px !important',
      borderStyle: 'solid !important',
    }
  })
}));