import React from "react";

export const CertificateIcon = ({ width = "100%", color = '#009bdd', id = "" }) => {

  const numberWidth = parseInt(width.replace("px", ""));

  const height = numberWidth * 0.7755;
  const fill = color;

  return (<svg
    id={id}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      data-name="Sustracci\xF3n 13"
      d="M22.65 14.999H9.082l-.611-1.993a6 6 0 0 0 1.527-4 6.008 6.008 0 0 0-6-6.006 5.731 5.731 0 0 0-1.357.157V.999a1 1 0 0 1 1-1h19.008a1 1 0 0 1 1 1v13a1 1 0 0 1-.999 1Zm-9.537-7a1 1 0 0 0-1 1 1 1 0 0 0 1 1h7a1 1 0 0 0 1-1 1 1 0 0 0-1-1Zm0-4a1 1 0 0 0-1 1 1 1 0 0 0 1 1h4a1 1 0 0 0 1-1 1 1 0 0 0-1-1Z"
      fill={fill}
    />
    <g
      data-name="Grupo 3148"
      transform="translate(21176.955 -1206.17)"
      fill={fill}
    >
      <path
        data-name="Uni\xF3n 1"
        d="m-21172.842 1223.135-4 1.376 2.4-7.755h3.2l2.4 7.755Z"
      />
      <circle
        data-name="Elipse 57"
        cx={4}
        cy={4}
        r={4}
        transform="translate(-21176.955 1211.169)"
      />
    </g>
  </svg>)
};