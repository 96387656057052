import { ThemeProvider } from '@material-ui/styles'
import React, { createContext, ReactElement, useState } from 'react'
import getTheme from './GetTheme'

type ThemeProps = {
  currentTheme: 'dark' | 'light' | 'custom' | 'orange' | string
  setTheme: Function | null
}

export const CustomThemeContext = createContext<ThemeProps>({
  currentTheme: 'light',
  setTheme: null
})

type CustomThemeProviderProps = {
  children: ReactElement
}

export const CustomThemeProvider = ({ children }: CustomThemeProviderProps) => {
  const APP_THEME = 'APP_THEME'
  const currentTheme = localStorage.getItem(APP_THEME) || 'light'

  const [themeName, _setThemeName] = useState(currentTheme)

  const theme = getTheme({ name: themeName })

  const setThemeName = (name: string) => {
    localStorage.setItem(APP_THEME, name)
    _setThemeName(name)
  }

  const contextValue: ThemeProps = {
    currentTheme: themeName,
    setTheme: setThemeName
  }

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  )
}
