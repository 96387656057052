import React from 'react'

export const ConfigurationIcon = ({
    width = '21.973px',
    height = '21.97px',
    id
}: any) => {
    if(width === null)
        width = '21.973px';
        height = '21.97px';
        return (
            <svg
                id={id}
                width={width}
                height={height}
                viewBox="0 0 21.973 21.97"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                
                <path id="ico_configuración" data-name="ico configuración" d="M173.425,410.79h0a8.456,8.456,0,0,1-1.839-1.877l1.14-2a6.726,6.726,0,0,1-.429-1.061l-2.211-.649a8.5,8.5,0,0,1,.024-2.629l2.223-.607c.056-.181.124-.355.2-.528a4.207,4.207,0,0,1,.243-.51l-1.1-2.038a8.459,8.459,0,0,1,1.877-1.841l2,1.14a6.982,6.982,0,0,1,1.061-.427l.651-2.21a8.442,8.442,0,0,1,2.629.023l.607,2.223q.271.087.53.2a4.337,4.337,0,0,1,.51.243l2.035-1.1a8.41,8.41,0,0,1,1.841,1.875l-1.14,2a6.734,6.734,0,0,1,.429,1.061l2.211.649a8.431,8.431,0,0,1-.024,2.627l-2.221.609c-.058.18-.126.355-.2.528a4.226,4.226,0,0,1-.243.509l1.1,2.023a8.5,8.5,0,0,1-1.875,1.843l-2.008-1.128a6.385,6.385,0,0,1-1.061.429l-.649,2.21a8.43,8.43,0,0,1-2.627-.025l-.607-2.221c-.179-.058-.355-.124-.528-.2a4.531,4.531,0,0,1-.51-.244l-2.022,1.106Zm6.121-9.3a2.687,2.687,0,1,0,1.634,2.494A2.685,2.685,0,0,0,179.546,401.493Z" transform="translate(-10.891 -430.115) rotate(21)" fill="#6d6e71"/>
               
            </svg>
        )
}