import { MenuSettingsIcon } from './MenuSettingsIcon'
import { OrderIcon } from './OrderIcon'
import { PrivilegeIcon } from './PrivilegeIcon'
import { RoleIcon } from './RoleIcon'
import { TrashIcon } from './TrashIcon'
import { UserIcon } from './UserIcon'
import { CertificateIcon } from './CertificatesIcon';
import { SignatureIcon } from './SignatureIcon'
import { PersonIcon } from './Person';
import { DashboardIcon } from './DashboardIcon';
import { BackArrowMenuicon } from './BackArrowMenuicon';
import { MenuCoustumerCompanyIcon } from './MenuCoustumerCompanyIcon';
import { ConfigurationIcon } from './ConfigurationIcon';
import { ReportIcon } from './ReportIcon';
import { LicenseIcon } from './LicenseIcon';
import { PackageIcon } from './PackageIcon';
import { PaymentIcon } from './PaymentIcon';
import { PurchaseOrderIcon } from './PurchaseOrderIcon';

const iconList = [
  { name: 'menuSettings', icon: MenuSettingsIcon },
  { name: 'privilege', icon: PrivilegeIcon },
  { name: 'role', icon: RoleIcon },
  { name: 'user', icon: UserIcon },
  { name: 'order', icon: OrderIcon },
  { name: 'Trash', icon: TrashIcon },
  { name: 'Certificate', icon: CertificateIcon },
  { name: 'Signature', icon: SignatureIcon },
  { name: 'Person', icon: PersonIcon },
  { name: 'Dashboard', icon: DashboardIcon },
  { name: 'backArrowMenuicon', icon: BackArrowMenuicon },
  { name: 'menuCoustumerCompanyIcon', icon: MenuCoustumerCompanyIcon },
  { name: 'configurationIcon', icon: ConfigurationIcon},
  { name: 'reportIcon', icon: ReportIcon},
  { name: 'licenseIcon', icon: LicenseIcon},
  { name: 'packageIcon', icon: PackageIcon},
  { name: 'paymentIcon', icon: PaymentIcon},
  { name: 'purchaseOrderIcon', icon: PurchaseOrderIcon},
]

const getIconFromList = (name: string) => {
  return iconList.find((icon) => icon.name === name)
}

const getList = () => {
  return iconList
}

export { getIconFromList, getList }
