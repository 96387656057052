import { makeStyles, TextField, Theme } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'

const ControlledInputRoundedForm = ({
  inputProps,
  inputRef,
  stateShrink,
  setStateShrink,
  adornment,
  control,
  pattern,
  personalizationColor,
  ...props
}: any) => {
  const classes = useStyles({ personalizationColor: personalizationColor })
  const regexPattern = new RegExp(pattern)

  const handleSpecialCharacters = (text: string) => {
    if (pattern === undefined) {
      // validateShrink(text);
      return text
    }
    let value = text.replace(regexPattern, '')
    // validateShrink(value);
    return value
  }

  // const validateShrink = (text) => {
  //     if (text.length === 0) {
  //         if (typeof setStateShrink === 'function') {
  //             setStateShrink(state => ({ ...state, [`${props.name}`]: false }));
  //         }
  //     } else {
  //         if (typeof setStateShrink === 'function') {
  //             setStateShrink(state => ({ ...state, [`${props.name}`]: true }));
  //         }
  //     }

  // }
  return (
    <Controller
      control={control}
      name={props.name}
      {...props}
      render={({ field: { onChange, value, ...rest } }) => (
        <TextField
          variant='filled'
          size='small'
          margin={'dense'}
          InputProps={{
            classes,
            disableUnderline: true,
            inputProps,
            ...adornment
          }}
          InputLabelProps={{
            classes: {
              root: classes.inputHiddenLabel,
              focused: classes.focused,
              error: classes.error
            },
            shrink: !!setStateShrink ? stateShrink : props.shrink
          }}
          inputRef={inputRef}
          value={value}
          onChange={(e) => {
            onChange(handleSpecialCharacters(e.target.value))
          }}
          {...rest}
          {...props}
        />
      )}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      border: '1px solid #e2e2e1',
      overflow: 'hidden',
      borderRadius: 10,
      backgroundColor: '#FFFFFF',
      boxShadow: `0px 1px 1px #00000026`,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: '#fff !important'
      },
      '&$focused':  {
        // borderColor: theme.palette.primary.main,
        backgroundColor: '#fff'
      },
      '& .MuiInputBase-input.Mui-disabled': {
        backgroundColor: '#fff !important' 
      },
      '&.MuiFilledInput-root.Mui-disabled': {
        backgroundColor: '#fff !important'
      }
    },
    disabled: {
      backgroundColor: 'white !important'
    },
    focused: (props: any) => ({
      '&$focused':  {
        backgroundColor: '#fff',
        borderColor: props.personalizationColor.primaryColor !== '' ? props.personalizationColor.primaryColor : theme.palette.primary.main,
      }
    }),
    error: {
      '&$error': {
        borderColor: theme.palette.error.main
      }
    },
    //Color del texto del input cuando está activo
    inputHiddenLabel: {
      marginTop: '1px',
      color: theme.palette.text.disabled
    }
  }
})

export default ControlledInputRoundedForm
