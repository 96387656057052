import React from "react";

export const SignatureIcon = ({ width = "100%", color = '#009bdd', id = "" }) => {

  const numberWidth = parseInt(width.replace("px", ""));

  const height = numberWidth * 0.8074;

  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 23.714 19.148"
    >
      <path
        id="Trazado_2453"
        data-name="Trazado 2453"
        d="M-373.492,82.958a2.291,2.291,0,0,1-1.043-.264,1.683,1.683,0,0,1-.832-1.853c.232-2.2,2.906-5.9,5.531-7.513l.065-.236c.137-.5.272-.988.407-1.467a35.355,35.355,0,0,1-6.767,1.566.734.734,0,0,1-.827-.626.733.733,0,0,1,.626-.827,31.569,31.569,0,0,0,7.482-1.885c.95-3.132,1.964-5.518,3.542-5.967a2.39,2.39,0,0,1,2.329.75,1.868,1.868,0,0,1,.45,2.009c-.443,1.406-2.142,2.993-5.115,4.3-.162.55-.319,1.107-.471,1.653a2.821,2.821,0,0,1,3.007,1.151,2.153,2.153,0,0,1,.377,1.174c1.129-1.038,2.47-1.918,3.514-1.548a1.419,1.419,0,0,1,.873.8,3.443,3.443,0,0,1-.5,2.586l-.008.018q.4-.2,1.042-.582c.951-.564,4.813-3.357,5.283-3.748a.724.724,0,0,1,.083-.078.733.733,0,0,1,1.031.114.735.735,0,0,1,.15.591c-.065.356-.066.363-2.638,2.208-.566.406-2.468,1.765-3.162,2.175-1.32.782-2.684,1.591-3.4.427-.3-.5-.043-1.048.286-1.749a4.326,4.326,0,0,0,.48-1.372l-.016-.006c-.474-.17-2.288,1.239-3.911,3.309a.733.733,0,0,1-.975.164.733.733,0,0,1-.252-.956c.491-.942.838-2.269.551-2.665a1.337,1.337,0,0,0-1.369-.6,3.1,3.1,0,0,0-.924.307c-1.152,4.157-2.214,7.64-4.07,8.458A2.055,2.055,0,0,1-373.492,82.958Zm2.993-7.283a12.3,12.3,0,0,0-3.187,4.476c-.314.832-.228,1.188-.19,1.231a.662.662,0,0,0,.627.055C-372.231,80.988-371.3,78.4-370.5,75.675Zm5.721-10.4a.471.471,0,0,0-.129.018c-.752.214-1.47,1.764-2.127,3.708a6.519,6.519,0,0,0,3.107-2.8.594.594,0,0,0-.049-.493A1.065,1.065,0,0,0-364.777,65.279Z"
        transform="translate(376.964 -63.809)"
        fill={color}
      />
    </svg>
  );
};
