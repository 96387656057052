import { CompanyActions } from './actions'
import { ECompanyActionTypes, ICompanyBasicDataLicence } from './types'

export interface ICompanyState {
  basicData: ICompanyBasicDataLicence
}

export const initialStateCompany: ICompanyState = {
  basicData: {
    personTypeId: '',
    documentTypeId: undefined,
    documentTypeCode: '',
    documentNumber: '',
    dv: '',
    legalName: '',
    firstName: '',
    lastName: '',
    cellphoneNumber: '',
    email: '',
    fullAddress: '',
    department: undefined,
    cityId: undefined,
    city: '',
    legalDocumentTypeId: undefined,
    legalDocumentNumber: '',
    legalFirstName: '',
    legalLastName: '',
    addressLatGeocode: '',
    addressLntGeocode: '',
    idstate: undefined,
    addressAdditionalInformation: '',
    PaymentMethod: '',
    Reference: ''
  }
}

export function companyReducer(
  state: ICompanyState = initialStateCompany,
  action: CompanyActions
): ICompanyState {
  switch (action.type) {
    case ECompanyActionTypes.CREATE_BASIC_DATA:
      return { ...state, basicData: action.data }
    case ECompanyActionTypes.CREATE_PARAMETRIZATION:
      return { ...state }
    case ECompanyActionTypes.CREATE_PERSONALIZATION:
      return { ...state }

    default:
      return state
  }
}
