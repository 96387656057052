import { makeStyles } from '@material-ui/core'
import React from 'react'

const PageBackground = () => {
  const classes = useStyles()
  return (
    <div>
      <div className={`${classes.root} 'Page-background'`} />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    // backgroundImage: '../assets/images/gseBackground.jpg',
    // backgroundColor: '#dfdfde',
    backgroundSize: '',
    zIndex: -999999
  }
}))

export default PageBackground
