import { createTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles' {
  interface Theme {
    paletteUserMenu: {
      Icon: {
        primary: {
          light: string,
          main: string,
          dark: string,
        }
      }
    },
    paletteDataTable: {
      border: {
        borderRadius: string
      },
      table: {
        columnsBackgroundColor: string,
        columnsLetterColor: string,
        columnsArrowColor: string,
        trashIconColor: string
      }
    },
    paletteViewLayout: {
      title: {
        color: string
      },
      border: {
        border: string,
        borderRadius: string
      }
    },
    palleteFormLayout: {
      iconEspecialButton:{
        initial:{
          backgrounColor: string,
          border: string,
          color: string
        },
        hover: {
          backgroundColor: string,
          border: string,
          boxshadow: string,
          color: string
        }
      }
    },
    palettePrimaryButton: {
      color: string,
      colorHover: string,
      textColor: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    paletteUserMenu?: {
      Icon?: {
        primary?: {
          light?: string;
          main?: string;
          dark?: string;
        }
      }
    },
    paletteDataTable: {
      border: {
        borderRadius: string
      },
      table: {
        columnsBackgroundColor: string,
        columnsLetterColor: string,
        columnsArrowColor: string,
        trashIconColor: string
      }
    },
    paletteViewLayout: {
      title: {
        color: string
      },
      border: {
        border: string,
        borderRadius: string
      }
    },
    palleteFormLayout: {
      iconEspecialButton:{
        initial:{
          backgrounColor: string,
          border: string,
          color: string
        },
        hover: {
          backgroundColor: string,
          border: string,
          boxshadow: string,
          color: string
        }
      }
    },
    palettePrimaryButton: {
      color: string,
      colorHover: string,
      textColor: string
    }
  }
}


const OrangeTheme = createTheme({
  palette: {
    primary: {
      light: '#009BDD',
      main: '#F5AE19',
      dark: '#f57c00',
    },
    secondary: {
      dark: '#E45501',
      main: '#FA5A00',
      light: '#FA5A0026',
    },
    info: {
      light: '#D5D5D5',
      main: '#D6D6D6',
      dark: '#C1C1C1',
    },
    error: {
      light: '#FA5A00',
      main: '#D80000',
      dark: '#D80000',
    },
    action: {
      hover: '#ccc'
    },
    background: {
      paper: '#F5F5F5',
      default: 'transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF99 100%) 0% 0% no-repeat padding-box'
    },
    text: {
      primary: '#6D6E71',
      secondary: '#E55200',
      hint: '#4F4F4F'
    },
  },
  paletteUserMenu: {
    Icon: {
      primary: {
        light: "#009BDD",
        main: "#F5AE19",
        dark: "#1D71B8",
      }
    }
  },
  paletteDataTable: {
    border: {
      borderRadius: '10px'
    },
    table: {
      columnsBackgroundColor: "#F5AE19",
      columnsLetterColor: "#FFFFFF",
      columnsArrowColor: "#FFFFFF 0% 0% no-repeat padding-box;",
      trashIconColor: "#F5AE19"
    }
  },
  paletteViewLayout: {
    title: {
      color: "#003582"
    },
    border: {
      border: '1px solid #f5ae19',
      borderRadius: '10px;'
    }
  },
  palleteFormLayout: {
    iconEspecialButton:{
      initial:{
        backgrounColor: 'white',
        border: '1px solid #A8A8A8',
        color: '#6D6E71'
      },
      hover: {
        backgroundColor: '#FA5A00',
        border: 'none',
        boxshadow: 'none',
        color: 'white'
      }
    }
  },
  palettePrimaryButton: {
    color: "#F5AE19",
    colorHover: "#f57c00",
    textColor: 'white'
  },
  typography: {
    allVariants: {
      fontFamily: 'Muli'
    },

    subtitle1: {
      textAlign: 'left',
      font: 'normal normal 600 16px/20px Muli',
      letterSpacing: '0px',
      fontWeight: 600,
      color: '#6D6E71',
      opacity: 1
    },
    body1: {
      textAlign: 'left',
      font: 'normal normal 600 16px/20px Muli',
      letterSpacing: '0px',
      color: '#6D6E71',
      fontWeight: 600,
      opacity: 1
    },
    h6: {
      fontWeight: 'normal',
      fontFamily: 'Muli',
      color: '#6D6E71',
      fontSize: '14px'
    }
  }
})

export default OrangeTheme;
