import React from 'react'

export const LicenseIcon = ({
    width = '18.639px',
    height = '16px',
    id
}: any) => {
    if(width === null)
        width = '18.639px';
        height = '16px';
        return (
            <svg
                id={id}
                width={width}
                height={height}
                viewBox="0 0 18.639 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                
                <g id="ico_licencias" data-name="ico licencias" transform="translate(-1466 1308)">
                    <path id="Trazado_984" data-name="Trazado 984" d="M40.491,11.876a2.836,2.836,0,1,0-3.277.072l-1.982,5.838,3.632-1.138L42.5,17.787Z" transform="translate(1430.768 -1309.787)" fill="#6d6e71"/>
                    <path id="Trazado_985" data-name="Trazado 985" d="M53.307.75H37.59a.828.828,0,0,0-.827.827V5.326a3.964,3.964,0,0,1,5.379,5.739l.711,2.093H53.307a.827.827,0,0,0,.827-.827V1.577A.827.827,0,0,0,53.307.75ZM44.622,3.955h4.86a.62.62,0,1,1,0,1.241h-4.86a.62.62,0,0,1,0-1.241Zm7.445,6.1H44.622a.62.62,0,0,1,0-1.241h7.445a.62.62,0,1,1,0,1.241Zm0-2.482H44.622a.62.62,0,0,1,0-1.241h7.445a.62.62,0,1,1,0,1.241Z" transform="translate(1430.503 -1308.75)" fill="#6d6e71"/>
                </g>
               
            </svg>
        )
}