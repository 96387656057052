import React from 'react'

export const RoleIcon = ({
  style = {},
  width = '100%',
  className = '',
  viewBox = '0 0 32 32',
  color = '#009bdd',
  id
}: any) => {
  const fill = color
  return (
    <svg
      id={id}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={width}
      style={style}
      viewBox={viewBox}
      className={`svg-icon ${className || ''}`}
    >
      <g
        id='ico_roles_azul'
        data-name='ico roles azul'
        transform='translate(-427 -570)'
      >
        <g id='Grupo_470' data-name='Grupo 470' transform='translate(427 570)'>
          <path
            id='Trazado_771'
            data-name='Trazado 771'
            d='M441.8,582.914l-1.581.184-.488-.8a7.983,7.983,0,0,1-.955-2.307l-.219-.908,1.245-.984-.794-.636a14.122,14.122,0,0,0-4.377-.812v0c-2.54,0-7.635,1.277-7.635,3.817v3.123h14.724Z'
            transform='translate(-427 -566.94)'
            fill={fill}
          />
          <path
            id='Trazado_772'
            data-name='Trazado 772'
            d='M433.3,576.94h.02a3.46,3.46,0,1,0-.02,0Z'
            transform='translate(-425.688 -570)'
            fill={fill}
          />
        </g>
        <path
          id='Sustracción_1'
          data-name='Sustracción 1'
          d='M440.9,586.083h0a6.545,6.545,0,0,1-1.885-.782l.207-1.775a5.321,5.321,0,0,1-.628-.628l-1.777.207a6.552,6.552,0,0,1-.781-1.886l1.4-1.108c-.013-.146-.019-.292-.019-.438a3.382,3.382,0,0,1,.019-.438l-1.4-1.123a6.545,6.545,0,0,1,.781-1.885l1.775.207a5.307,5.307,0,0,1,.628-.628l-.206-1.777a6.539,6.539,0,0,1,1.885-.781l1.109,1.4c.146-.013.292-.019.438-.019a3.392,3.392,0,0,1,.438.019l1.121-1.4a6.54,6.54,0,0,1,1.885.781l-.207,1.777a5.312,5.312,0,0,1,.628.628l1.777-.207a6.542,6.542,0,0,1,.781,1.885l-1.4,1.11c.013.146.019.292.019.438a3.375,3.375,0,0,1-.019.438l1.4,1.109a6.548,6.548,0,0,1-.781,1.886l-1.777-.2a5.1,5.1,0,0,1-.628.628l.207,1.775a6.541,6.541,0,0,1-1.885.781l-1.108-1.4c-.146.013-.292.019-.438.019a3.382,3.382,0,0,1-.438-.019l-1.109,1.4Zm1.552-8.5a2.085,2.085,0,1,0,1.924,1.286A2.082,2.082,0,0,0,442.456,577.583Z'
          transform='translate(4.157 1.494)'
          fill={fill}
        />
      </g>
    </svg>
  )
}
