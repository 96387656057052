import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import { Controller } from 'react-hook-form'
import { FormHelperText } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import { CustomThemeProvider } from '../../theme/CustomThemeProvider'
type ControlledSwitchProps = {
  onChange?: Function
  isDisabled: boolean
  name: string
  control: any
  defaultValue?: any
  checked?: boolean
  value?: any
  ref?: any
  error?: boolean
  helperText?: string
}

const ControlledSwitchComponent = (
  {
    isDisabled,
    name,
    control,
    defaultValue,
    helperText
  }: ControlledSwitchProps,
  ...props: any
) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ fieldState: { error }, field: { onChange, value } }) => (
        <FormControl error={error ? true : false}>
          <AntSwitch
            disabled={isDisabled}
            onChange={(e) => onChange(e.target.checked)}
            {...props}
            value={value ? value : false}
            checked={value ? value : false}
          />
          <FormHelperText id='switch-form-label'>{helperText}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

const AntSwitch = withStyles((theme: any) => ({
  root: {
    width: 36,
    height: 18,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: `1px solid ${theme.palette.info.light}`
      }
    },
    '&$disabled': {
      color: theme.palette.common.white,
      '& + $track': {
        opacity: `${0.4} !important`
      }
    }
  },
  disabled: {},
  thumb: {
    width: 14,
    height: 14,
    margin: 1,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.info.light}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.info.dark
  },
  checked: {}
}))(Switch)

export const ControlledSwitch = (props: ControlledSwitchProps) => {
  return (
    <CustomThemeProvider>
      <ControlledSwitchComponent {...props} />
    </CustomThemeProvider>
  )
}
