import { InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core'
import React from 'react'

type InputEditableProps = {
  label: string
  value: string
  editable?: boolean
}

export const InputEditable = ({
  label,
  value,
  editable
}: InputEditableProps) => {
  const classes = useStyles()
  return (
    <TextField
      label={label}
      fullWidth
      value={value}
      InputLabelProps={{
        className: classes.label
      }}
      InputProps={{
        classes,
        disabled: !editable,
        endAdornment: editable ? (
          <InputAdornment position='end'>
            <span className={`material-icons ${classes.icon}`}>edit</span>
          </InputAdornment>
        ) : null
      }}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.typography.pxToRem(10),
    color: theme.palette.text.hint,
    // fontWeight: theme.typography.fontWeightRegular,
    fontSize: '16px',
  },
  label: {
    color: theme.palette.text.hint,
    fontSize: '12px'
  },
  icon: {
    color: theme.palette.primary.light
  },
  underline: {
    '&:before': {
      borderBottom: `1px solid ${theme.palette.info.main}`
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `1px solid ${theme.palette.primary.main}`
    },
    '&:after': {
      borderBottom: `1px solid ${theme.palette.primary.main}`
    }
  },
  disabled: {},
  focused: {},
  error: {}
}))
