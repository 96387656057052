/* eslint-disable no-alert */
import React, { useState } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Theme
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/styles'
import { CustomThemeProvider } from '../theme/CustomThemeProvider'
import { TopMenu } from '../views/TopMenu/TopMenu'

type AppBarProps = {
  title: string
  children?: JSX.Element
  selectedCustomer: string
  appName: string
  token: string
}

const ButtonAppBarComponent = ({
  title,
  children,
  selectedCustomer,
  appName,
  token
}: AppBarProps) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  return (
    <div className={classes.root}>
      <AppBar
        position='fixed'
        style={{ justifyContent: 'space-evenly' }}
        className={classes.appBar}
      >
        <Toolbar>
          <IconButton
            onClick={() => setOpen(!open)}
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='menu'
          >
            <MenuIcon />
          </IconButton>
          <Grid container justifyContent='space-between'>
            <Grid item lg={4}>
              {' '}
              <Typography variant='h6' className={classes.title}>
                {title}
              </Typography>
            </Grid>
            <Grid container item lg={8} alignItems='center'>
              <Grid container item lg={8}>
                <Grid item lg={4}>
                  {' '}
                  <Typography
                    variant='subtitle2'
                    className={classes.customerName}
                  >
                    {selectedCustomer}
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <TopMenu appName={appName} token={token} functions={[]}/>
                </Grid>
              </Grid>
              <Grid container item lg={4} direction='row'>
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export const ButtonAppBar = (props: AppBarProps) => (
  <CustomThemeProvider>
    <ButtonAppBarComponent {...props} />
  </CustomThemeProvider>
)

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 1
  },
  menuButton: {
    color: theme.palette.info.main
  },
  customerName: {},
  title: {},
  root: {}
}))
