import { useCallback, useState } from 'react';
import { UseAsyncProps } from '../models/HooksModels';



const useAsync = ({ asyncFunction }: any): UseAsyncProps => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [result, setResult] = useState<any>();

  const execute = useCallback(
    async (...params: any) => {
      try {
        setLoading(true);
        const response = await asyncFunction(...params);
        setResult(response);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    },
    [asyncFunction],
  );

  return {
    error,
    result,
    loading,
    execute,
  };
};

export default useAsync;
