import React from 'react'
import { Menu } from '@material-ui/core'

/**
 * TODO: rename to container menu
 */

const StyledMenu = React.forwardRef((props: any, ref) => {
  const { classes, anchorOrigin, transformOrigin, ...rest } = props
  return (
    <Menu
      ref={ref}
      elevation={0}
      PopoverClasses={{
        paper: props.classes.paper
      }}
      MenuListProps={{ style: { paddingTop: props.paddingtop, paddingBottom: props.paddingbottom } }}
      getContentAnchorEl={null}
      anchorOrigin={
        anchorOrigin
          ? {
            ...anchorOrigin
          }
          : {
            vertical: 'bottom',
            horizontal: 'right'
          }
      }
      transformOrigin={
        transformOrigin
          ? { ...transformOrigin }
          : {
            vertical: 'top',
            horizontal: 'right'
          }
      }
      {...rest}
    />
  )
})

export default StyledMenu
