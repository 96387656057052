import React, { useEffect } from 'react'
import { LIST_PERSON_TYPE, MESSAGES } from '../../../utils/const'
import * as yup from 'yup'
import {
  ICities,
  ICompanyBasicDataLicence,
  IDocumentType,
  IStatesAndCities,
  jsonColor
} from '../types'
import { IBasicData } from '../types'
import {
  createBasicData,
  //getCities,
  getCompanyData,
  //getDepartments,
  getDocumentType,
  //getPersonType,
  updateBasicData,
  getListStatesAndCitiesService,
  updateBiometricBasicData
} from '../Services/BasicDataServices'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useDialog from '../../../hooks/useAlertConfirm'
import useAsync from '../../../hooks/useAsync'
import { CompanyBasicDataView } from './CompanyBasicData'
import {
  CC,
  email,
  numberCellphone,
  onlyLetters,
  validationAddress
} from '../../../utils/mask'

type Colors = {
  buttonColor?: string
  buttonTextColor?: string
}

type CompanyBasicDataProps = {
  onSubmit?: Function
  token: string
  basicData?: ICompanyBasicDataLicence
  companyId: number | undefined
  isEdit?: boolean
  color?: Colors
  personalizationColor?: jsonColor
}

const BasicDataSchema = yup.object().shape({
  personTypeId: yup
    .string()
    .max(100, `${MESSAGES.max} 100`)
    .required(MESSAGES.required),
  documentTypeId: yup
    .string()
    .max(100, `${MESSAGES.max} 100`)
    .required(MESSAGES.required),
  documentNumber: yup
    .string()
    .max(100, `${MESSAGES.max} 100`)
    .required(MESSAGES.required),
  dv: yup.number().max(9, `${MESSAGES.max} 1`).required(MESSAGES.required),
  legalName: yup
    .string()
    .max(100, `${MESSAGES.max} 100`)
    .required(MESSAGES.required),
  email: yup
    .string()
    .email()
    .max(100, `${MESSAGES.max} 100`)
    .required(MESSAGES.required),
  cellphoneNumber: yup
    .number()
    .typeError(`${MESSAGES.typeError} un número válido`)
    .max(9999999999, `${MESSAGES.max} 10`)
    .required(MESSAGES.required),
  fullAddress: yup
    .string()
    .max(100, `${MESSAGES.max} 100`)
    .required(MESSAGES.required),
  department: yup.number().required(MESSAGES.required),
  // city: yup.number().required(MESSAGES.required),
  addressAdditionalInformation: yup
    .string()
    .nullable()
    .max(100, `${MESSAGES.max} 100`),
  legalDocumentTypeId: yup
    .string()
    .ensure()
    .when('personType', {
      is: (personType: number) => personType !== 1,
      then: yup.string().required(MESSAGES.required)
    }),
  legalDocumentNumber: yup
    .string()
    .max(100, `${MESSAGES.max} 100`)
    .ensure()
    .when('personType', {
      is: (personType: number) => personType !== 1,

      then: yup.string().required(MESSAGES.required)
    }),
  legalFirstName: yup
    .string()
    .max(100, `${MESSAGES.max} 100`)
    .ensure()
    .when('personType', {
      is: (personType: number) => personType !== 1,

      then: yup.string().required(MESSAGES.required)
    }),
  legalLastName: yup
    .string()
    .max(100, `${MESSAGES.max} 100`)
    .ensure()
    .when('personType', {
      is: (personType: number) => personType !== 1,

      then: yup.string().required(MESSAGES.required)
    }),
  idstate: yup.bool().required(MESSAGES.required)
})

const selectables = [
  'personTypeId',
  'legalDocumentTypeId',
  'documentTypeId',
  'cityId',
  'department',
  'idstate',
  'city'
]

export interface ISelectStates {
  departments: IStatesAndCities[]
  cities: ICities[] | undefined
  documentTypes: IDocumentType[]
  personTypes: IBasicData[]
}

export const BasicDataContainer = ({
  token,
  basicData,
  companyId,
  isEdit = false,
  color,
  personalizationColor
}: CompanyBasicDataProps) => {
  const form = useForm<ICompanyBasicDataLicence>({
    mode: 'onChange',
    resolver: yupResolver(BasicDataSchema),
    defaultValues: { ...basicData },
    shouldUnregister: false
  })
  const [isEditable, setIsEditable] = React.useState(isEdit)
  const [isNew] = React.useState(false)
  const { watch, setValue, reset } = form
  const [selectStates, setSelectStates] = React.useState<ISelectStates>({
    cities: [],
    departments: [],
    documentTypes: [],
    personTypes: []
  })
  const department = watch('department') || false
  const [isNatural, setIsNatural] = React.useState<boolean>()
  const [message, setMessage] = React.useState<string>()
  const [companyData, setCompanyData] =
    React.useState<ICompanyBasicDataLicence>()
  const { Dialog, onOpen } = useDialog({
    bodyText: message ? message : 'Empresa creada con éxito',
    cancelButtonText: 'Cerrar'
  })
  const { error, execute, loading, result } = useAsync({
    asyncFunction: isNew ? createBasicData : updateBasicData
  })

  //#region  effects region
  useEffect(() => {
    if (result) {
      if (result.status === 200) {
        console.log(companyId)
        if (companyId) {
          getCompanyData(companyId, token).then((response) => {
            if (response.status === 200) {
              const { records } = response.data.result
              const body = {
                personType: records.personTypeId,
                documentType: records.documentTypeId,
                documentNumber: records.documentNumber,
                legalName: records.legalName,
                cellphoneNumber: records.cellphoneNumber,
                email: records.email,
                cityId: records.cityId,
                fullAddress: records.fullAddress,
                addressAdditionalInformation:
                  records.addressAdditionalInformation,
                legalDocumentType: records.legalDocumentTypeId,
                legalDocumentNumber: records.legalDocumentNumber,
                legalFirstName: records.legalFirstName,
                legalLastName: records.legalLastName
              }
              updateBiometricBasicData(body, token)
            }
          })
        }
        onOpen()
        setMessage(result.data.result.records)
      }
    } else if (error) {
      onOpen()
      setMessage(error.message)
    }
  }, [result, error])

  // useEffect(() => {
  //   if (department) {
  //     const departmentSel = selectStates.departments.find((item) => item.id === department);
  //     const cities = departmentSel?.city;
  //     setSelectStates((oldStates) => ({ ...oldStates, cities }));
  //   }
  // }, [department]);

  useEffect(() => {
    //load first document types and departments
    getDocumentType(token).then((responseDocT) => {
      getListStatesAndCitiesService().then((reponseStateAnCities) => {
        const documentTypes =
          responseDocT.status === 200 ? responseDocT.data.result.records : []
        const departments =
          reponseStateAnCities.status === 200
            ? reponseStateAnCities.data.result.records
            : []
        setSelectStates((oldStates) => {
          return {
            ...oldStates,
            departments,
            documentTypes,
            personTypes: LIST_PERSON_TYPE
          }
        })
      })
    })
  }, [])

  useEffect(() => {
    //then listen departments an load cities
    if (department) {
      const departmentSel = selectStates.departments.find(
        (item) => item.id === department
      )
      const cities = departmentSel?.city
      setSelectStates((oldStates) => ({ ...oldStates, cities }))
    }
  }, [selectStates.departments.length, department])

  useEffect(() => {
    // listen cities, all select aren´t
    console.log('BasicDataContainer', token)

    if (companyId) {
      getCompanyData(companyId, token)
        .then((response) => {
          const { data, status } = response
          if (status === 200) {
            const { records } = data.result
            setCompanyData(records)
          }
        })
        .catch((error) => console.error(error))
    }
  }, [token])

  React.useEffect(() => {
    for (const key in companyData) {
      if (selectables.includes(key)) {
        if (key === 'personTypeId' && companyData.personTypeId)
          reset({
            personTypeId:
              companyData.personTypeId === 'J'
                ? '1'
                : companyData.personTypeId === 'N'
                ? '2'
                : ''
          })
        if (key === 'legalDocumentTypeId' && companyData.legalDocumentTypeId)
          setValue('legalDocumentTypeId', companyData.legalDocumentTypeId)

        if (key === 'cityId' && companyData.cityId) {
          setValue(
            'department',
            parseInt(companyData.cityId.toString().substring(0, 6))
          )
          setValue('city', companyData.cityId.toString())
        }
        if (key === 'documentTypeId' && companyData.documentTypeId) {
          setValue('documentTypeId', companyData.documentTypeId)
        }
        if (key === 'idstate' && companyData.idstate == 0) {
          setValue('idstate', (companyData.idstate = 1))
        } else if (key === 'idstate' && companyData.idstate == 1) {
          setValue('idstate', (companyData.idstate = 0))
        }
      } else {
        setValue(key, companyData[key])
      }
    }
  }, [companyData])

  //#endregion

  const formSubmit = async (data: ICompanyBasicDataLicence) => {
    let { idstate, ...rest } = data
    const params = isNew
      ? { ...rest, idstate: idstate ? 1 : 0 /* latitude: '', longitude: '' */ }
      : {
          data,
          cityId: data.city,
          idstate: idstate ? 1 : 0,
          customerId: companyId
        }
    execute(params, token)
  }

  const handleForm = (event: any) => {
    switch (event.target.name) {
      case 'documentNumber':
        setValue('documentNumber', CC(event.target.value))
        break
      case 'legalName':
        setValue('legalName', onlyLetters(event.target.value))
        break
      case 'email':
        setValue('email', email(event.target.value))
        break
      case 'cellphoneNumber':
        setValue('cellphoneNumber', numberCellphone(event.target.value))
        break
      case 'fullAddress':
        setValue('fullAddress', validationAddress(event.target.value))
        break
      case 'addressAdditionalInformation':
        setValue(
          'addressAdditionalInformation',
          validationAddress(event.target.value)
        )
        break
      case 'legalFirstName':
        setValue('legalFirstName', onlyLetters(event.target.value))
        break
      case 'legalLastName':
        setValue('legalLastName', onlyLetters(event.target.value))
        break
      default:
        break
    }
  }
  return (
    <div>
      <CompanyBasicDataView
        form={form}
        loading={loading}
        selectStates={selectStates}
        formSubmit={formSubmit}
        isNatural={isNatural}
        setIsNatural={setIsNatural}
        isEdit={isEditable}
        isNew={isNew}
        setIsEdit={setIsEditable}
        color={color}
        personalizationColor={personalizationColor}
        watch={watch}
        handleChangeForm={handleForm}
      />
      <Dialog />
    </div>
  )
}
