import React, { useState, useRef, useEffect, Fragment } from 'react'
import {
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
  Tooltip,
  useTheme
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import NestedMenuItem from 'material-ui-nested-menu-item'

// import { Link } from 'react-router-dom'
import { Menu } from '../models/MenuModels'
import { CustomThemeProvider } from '../theme/CustomThemeProvider'
import { makeId, runFunction } from '../utils/utils'
import { getIconFromList } from '../components/IconsLibrary'
import { useHistory } from 'react-router-dom';

type MenuProps = {
  menu: Menu[]
  haveDivider?: boolean
  haveHoverColor?: boolean
  props?: any
  events?: any
  functions: Array<any>
  isLargePadding: boolean
  functionOnClose?: Function
  jsonColor?: jsonColor
}

const DrawMenuComponent = React.forwardRef(
  ({ menu, haveDivider, haveHoverColor, props, events, functions, isLargePadding,functionOnClose ,jsonColor }: MenuProps, ref): JSX.Element => {
    const classes = useStyles({...props, isLargePadding})
    const theme = useTheme()
    const history = useHistory()
    const contextFunctions = useRef({})

    const confirmOpenUrl = (url: string) =>
      !url.includes(window.location.origin)

    const [, setSelectedPath] = useState('initialState')

    /**
     *
     * @param {number} index
     * @param {boolean} open
     * @param {string} url
     */
    const handleListItemClick = (
      _index: string,
      url: string
    ) => {
      window.open(url, '_blank')
    }

    /**
     *
     * @param {string} name
     * @param {string} url
     */
    const createPersonalizedIcon = (name: string, url: string, id: string, sizeIcon: string) => {
      const IconGet = getIconFromList(name)?.icon
      if (IconGet !== undefined) {
        return (
          <IconGet
            id={`${id}-svg`}
            color={
              haveHoverColor ?
                window.location.href.includes(url)
                  ? theme.palette.primary.light
                  : theme.palette.text.primary
                : theme.palette.text.primary
            }
            width={sizeIcon}
          />
        )
      } else {
        return <div></div>
      }
    }

    useEffect(() => {
      if (functions !== undefined && functions.length !== 0) {
        functions.map(item => {
          contextFunctions.current[item.name] = item.function;
        });
      }
    }, [])

    /**
     *
     * @param {Menu} item - item to be iterated
     * @returns
     */
    const drawMenuItem = (item: Menu) => {
      const id = makeId(10)
      var newName: string[] = item.name?.split(',');//Se agrega linea para Biometría

      return (
        <div className={classes.root}>
          <ListItem
            data-url={item.url}
            ref={ref}
            key={id}
            id={id}
            button
            selected={window.location.href.includes(item.url)}
            onClick={() => {
              if(newName !== undefined && newName?.length === 1)//Se valida para que no realiza el evento en el menú para Biometría Rol RNEC
              {
                if (item.isFunction) {
                  runFunction(item.functionName, contextFunctions.current)
                } else {
                  const confirmOpen = confirmOpenUrl(item.url);
                  if (confirmOpen) {
                    handleListItemClick(item.key, item.url)
                  } else {
                    history.push(item.url.replace(window.location.origin, ''));
                  }
                }
                setSelectedPath(item.url)
                if(functionOnClose !== undefined){
                  functionOnClose();
                }
              }
            }}
            classes={{
              root: classes.listItemRoot
            }}
            {...events}
          >
            {item.children !== undefined && item.children.length > 0 ? (
              <Grid container alignItems='center'>
                <ListItemIcon className={classes.spacingIconText}>
                  {!item.isPersonalizedIcon ? (
                    <span
                      id={`${id}-icon`}
                      className={`material-icons ${classes.icon}`}
                      style={{
                        fontSize: item.sizeIcon,
                        color: haveHoverColor
                          ? window.location.href.includes(item.url)
                            ? theme.palette.primary.light
                            : ''
                          : theme.palette.text.primary
                      }}
                    >
                      {item.iconName}
                    </span>
                  ) : (
                    createPersonalizedIcon(item.iconName, item.url, id, item.sizeIcon)
                  )}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                      <Fragment>
                        {newName?.map(itemNew => {
                          return  <Tooltip
                                    title={newName.length > 1 ? item.name.replace(',', ' - ') : ''}
                                    aria-label='item'
                                    style={{ marginTop: 4, marginLeft: 2 }}
                                  > 
                                    <Typography
                                      id={`${id}-typo`}
                                      style={{
                                        color:
                                          haveHoverColor ?
                                            window.location.href.includes(item.url)
                                              ? theme.palette.primary.light
                                              : theme.palette.text.primary
                                            : theme.palette.text.primary,
                                        fontSize: (newName.length > 1 ? 12 : 14)
                                      }}
                                      variant='subtitle1'
                                      className={classes.typography}
                                    >
                                      {
                                        newName.length > 1?
                                        `${itemNew.length > 15 ? itemNew.substring(0,15) + ".." : itemNew }` 
                                        : item.name
                                      }
                                    </Typography>
                                  </Tooltip>
                        })}
                    </Fragment>
                  }
                />
                <NestedMenuItem key={makeId(7)} parentMenuOpen >
                  {item.children.map((subItem) => drawMenuItem(subItem))}
                </NestedMenuItem>
              </Grid>
            ) : (
              <Grid container alignItems='center'>
                <ListItemIcon className={classes.spacingIconText}>
                  {!item.isPersonalizedIcon ? (
                    <span
                      id={`${id}-icon`}
                      className={`material-icons ${classes.icon}`}
                      style={{
                        fontSize: item.sizeIcon,
                        color: haveHoverColor
                          ? window.location.href.includes(item.url)
                            ? jsonColor?.primaryColor
                            : theme.palette.text.primary
                          : theme.palette.text.primary
                      }}
                    >
                      {item.iconName}
                    </span>
                  ) : (
                    createPersonalizedIcon(item.iconName, item.url, id, item.sizeIcon)
                  )}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Fragment>
                      {newName?.map(itemNew => {
                        return <Tooltip
                                  title={newName.length > 1 ? item.name.replace(',', ' - ') : ''}
                                  aria-label='item'
                                  style={{ marginTop: 4, marginLeft: 2 }}
                                > 
                                  <Typography
                                    id={`${id}-typo`}
                                    style={{
                                      color: haveHoverColor ?
                                        window.location.href.includes(item.url)
                                          ? jsonColor?.primaryColor
                                          : theme.palette.text.primary
                                        : theme.palette.text.primary,
                                      fontSize: (newName.length > 1 ? 12 : 14)
                                    }}
                                    variant='subtitle1'
                                    className={classes.typography}
                                    align='justify'
                                  >
                                    {
                                      newName.length > 1 ?
                                        `${itemNew.length > 15 ? itemNew.substring(0,15) + ".." : itemNew }` 
                                      : item.name
                                    }
                                  </Typography>
                                </Tooltip>
                      })}
                    </Fragment>
                  }
                />
              </Grid>
            )}
          </ListItem>
          {haveDivider ? (
            <Divider
              classes={{
                root: classes.divider
              }}
            />
          ) : null}
        </div>
      )
    }

    const drawledMenu = () => {
      let menuResult: {} | null | undefined = []
      if (menu.length) {
        menuResult = menu.map<JSX.Element>(drawMenuItem)
      }
      return <div>{menuResult}</div>
    }
    return drawledMenu()
  }
)

export const DrawMenu = React.forwardRef((props: MenuProps, ref: any) => {
  return (
    <CustomThemeProvider>
      <DrawMenuComponent {...props} ref={ref} />
    </CustomThemeProvider>
  )
})

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white
    }
  },
  typography: (props: any) => ({
    ...props.typography
  }),
  divider: {
    backgroundColor: theme.palette.info.main,
    marginLeft: '5px',
    marginRight: '5px',
    height: '1px'
  },
  icon: (props: any) => ({
    color: theme.palette.text.primary,
    ...props.icon
  }),
  spacingIconText:{
    minWidth:"40px"
  },
  listItemRoot: (props?: any) => ({
    '&.MuiTypography-colorTextSecondary': {
      color: theme.palette.common.white
    },
    paddingLeft: props.isLargePadding ? "30px" : "16px",
    ...props
  }),
  selectedMenuItemEnabled: { color: theme.palette.text.secondary },
  selectedMenuItemDisabled: { color: theme.palette.primary.light }
}))
