import React, { useCallback } from 'react'
import AlertConfirm from '../components/Alert/AlertConfirm'

interface ConfirmDialogProps {
  headerText?: string
  bodyText: string | JSX.Element
  confirmationButtonText?: string
  cancelButtonText?: string
  onCancelClick?: Function
  onConfirmClick?: Function
  props?: any
}

/**
 * handle alert confirm dialog highly customizable
 * @param {string} headerText - the header text of confirm dialog
 * @param {string}  bodyText - usually the message to show
 * @param {string} confirmationButtonText - text to show in accept confirm button the button shows only if the text is available
 * @param {string} cancelButtonText -text to show in cancel confirm button the button shows only if the text is available
 * @param {Function} onCancelClick - optional function, to make a custom action when cancel button is clicked
 * @param {Function} onConfirmClick - optional function, to make a custom action when accept button is clicked
 * @param {*} props - optional properties to alertConfirm component
 * @returns {{Dialog: JSX.Element, onOpen: Function, onClose: Function}}
 */
export default function useConfirmationDialog({
  headerText,
  bodyText,
  confirmationButtonText,
  cancelButtonText,
  onCancelClick,
  onConfirmClick,
  props
}: ConfirmDialogProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  const onOpen = () => {
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const Dialog = useCallback(
    () => (
      <AlertConfirm
        headerText={headerText}
        bodyText={bodyText}
        isOpen={isOpen}
        setOpen={setIsOpen}
        cancelButtonText={cancelButtonText}
        onConfirmClick={() => {
          if (onConfirmClick) {
            onConfirmClick()
          } else {
            setIsOpen(false)
          }
        }}
        onCancelClick={() => {
          if (onCancelClick) {
            onCancelClick()
          } else {
            setIsOpen(false)
          }
        }}
        confirmationButtonText={confirmationButtonText}
        {...props}
      />
    ),
    [isOpen]
  )

  return {
    Dialog,
    onOpen,
    onClose
  }
}
