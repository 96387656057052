import {
  makeStyles,
  MenuItem,
  TextField,
  TextFieldProps,
  Theme
} from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'
import { CustomThemeProvider } from '../../theme/CustomThemeProvider'

type InputRoundedProperties = {
  label: string
  options: Array<SelectOption>
  ref?: any
  props?: TextFieldProps
  name: string
  control: any
  defaultValue?: any
  rules?: any
  onChange?: Function
}

type SelectOption = {
  key: number
  value: string | number
  label: string
}

const SelectInputRoundedComponent = ({
  label,
  options,
  control,
  name,
  defaultValue,
  rules,
  onChange,
  props
}: InputRoundedProperties) => {
  const classes = useStyles()
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange: customChange, value, ...rest } }) => (
        <TextField
          {...rest}
          onChange={(e) => {
            if (onChange) onChange(e)
            customChange(e)
          }}
          value={value ? value : ''}
          select
          fullWidth
          classes={{
            root: classes.selectCustom
          }}
          label={label}
          variant='filled'
          InputProps={{
            disableUnderline: true,
            classes: {
              disabled: classes.selectDisabled
            }
          }}
          SelectProps={{
            color: 'primary'
          }}
          {...props}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    //  background: "#FFFFFF 0% 0% no-repeat padding-box",
    backgroundColor: '#FFFFFF !important',

    '& .MuiFilledInput-root': {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 3px #0000001A',
      border: '1px solid #D5D5D5',
      borderRadius: '10px',
      opacity: 1,
      margin: 2
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primary.light
    },
    '& .MuiInputBase-input': {
      textAlign: 'left',
      font: 'normal normal normal 16px/17px Muli',
      letterSpacing: '0px',
      color: '#575757',
      opacity: 1
    },
    '& .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconFilled': {
      color: theme.palette.primary.light
    }
  },

  selectCustom: {
    background: 'white',
      boxShadow: '0px 3px 3px #0000001A',
      border: '1px solid #D5D5D5',
      borderRadius: '10px',
    '& input': {
      
    },

    '& div': {
      background: 'none !important',
    },

    '& label': {
      fontSize: '16px',
      font: 'normal normal normal 16px/17px Muli',

    }
  },

  selectDisabled: {
    opacity: '1 !important'
  }
}))

export const SelectInputRounded = (props: InputRoundedProperties) => {
  return (
    <CustomThemeProvider>
      <SelectInputRoundedComponent {...props} />
    </CustomThemeProvider>
  )
}
