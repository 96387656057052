import { Avatar, Badge, createStyles, Grid, Theme } from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { makeStyles, withStyles } from '@material-ui/styles'
import React from 'react'
import { PrimaryButton } from '../../components/Button'
import { InputEditable } from '../../components/Inputs'
import { CustomModal } from '../../components/Modal/CustomModal'
import { User } from '../../models/UserModels'

type UserProfileProps = {
  onClose: Function
  userInfoComplete: UserInfoComplete
  setModalOpen: Function
  user: User
  isModalOpen: boolean
}

export const UserProfile = ({
  onClose,
  userInfoComplete,
  user,
  isModalOpen,
  setModalOpen
}: UserProfileProps) => {
  const classes = useStyles()

  return (
    <div>
      <CustomModal
        open={isModalOpen}
        onClose={onClose}
        children={
          <div>
            <Grid item container justify='center'>
              {user.profilePicture ? (
                <StyledBadge
                  badgeContent={
                    <div className={classes.circularBackground}>
                      <span className={`material-icons ${classes.span}`}>
                        photo_camera
                      </span>
                    </div>
                  }
                >
                  <Avatar
                    alt='Travis Howard'
                    className={classes.profilePicture}
                    src={user.profilePicture}
                  >
                    <AccountCircle
                      style={{ fontSize: 110, color: '#6D6E71' }}
                    />
                  </Avatar>
                </StyledBadge>
              ) : (
                <AccountCircle style={{ fontSize: 50 }} />
              )}
            </Grid>
            <div style={{ padding: '0px 35px' }}>
              <InputEditable label='Nombres' value={userInfoComplete.names} />
              <InputEditable
                label='Apellidos'
                value={userInfoComplete.surNames}
              />
              <InputEditable
                label='Tipo de documento'
                value={userInfoComplete.documentType}
              />
              <InputEditable
                label='Número de documento'
                value={userInfoComplete.documentNumber}
              />
              <InputEditable label='Email' value={userInfoComplete.email} />
              <InputEditable label='Teléfono' value={userInfoComplete.phone} />
              <InputEditable
                label=' Id de usuario'
                value={userInfoComplete.userName}
              />
            </div>
            <Grid
              container
              justifyContent='center'
              style={{
                width: '100%',
                marginTop: '10px',
                marginBottom: '40px'
              }}
            >
              {' '}
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent='center'
                spacing={4}
              >
                <Grid item lg={5}>
                  <PrimaryButton
                    title='Cancelar'
                    fullWidth={true}
                    props={{
                      onClick: () => setModalOpen(!isModalOpen)
                    }}
                  />
                </Grid>
                <Grid item lg={5}>
                  <PrimaryButton
                    title='Aceptar'
                    fullWidth={true}
                    props={{
                      onClick: () => setModalOpen(!isModalOpen),
                      disabled: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        }
      />
    </div>
  )
}

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      top: 85,
      right: 15,
    },
  }),
)(Badge);

const useStyles = makeStyles((theme: Theme) => {
  return {
    profilePicture: {
      marginTop: 12,
      marginBottom: 12,
      height: '94px',
      width: '94px'
    },
    circularBackground: {
      padding: '1px',
      background: theme.paletteUserMenu.Icon.primary.main,
      '-moz-border-radius': '50%',
      '-webkit-border-radius': '50%',
      borderRadius: '50%',
      borderColor: theme.paletteUserMenu.Icon.primary.main,
      borderStyle: 'solid',
      borderWidth: '1px'
    },
    span: {
      fontSize: '25px',
      color: theme.palette.common.white
    }
  }
})
