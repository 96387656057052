import { CustomError, CustomErrorAction, } from '../models/ActionsModels';
import { Enterprise, EnterpriseAction, } from '../models/EnterpriseModels'
import * as actionTypes from '../actions/types';

export const enterprisesResponse = (enterprises: Enterprise[],): EnterpriseAction => ({
  type: actionTypes.GET_ENTERPRISES,
  payload: enterprises,
});

export const enterpriseError = (error: CustomError): CustomErrorAction => ({
  type: actionTypes.GET_ERRORS,
  payload: error,
});
