import React from 'react'

export const MenuCoustumerCompanyIcon = ({
    width = '30px',
    id
}: any) => {
    if(width === null)
        width = '30px';
    return (
        <svg
            id={id}
            width={width}
            height={width}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19.5" fill="white" stroke="#FFDFD3" />
            <path d="M19.4968 10L13.2986 30.0704H7L10.2378 19.5892C10.2402 19.5859 10.2412 19.5818 10.2408 19.5777L13.1982 10H19.4968Z" fill="url(#paint0_linear_1215_9049)" />
            <path d="M26.7282 10L20.53 30.0704H14.2314L17.4043 19.7961L20.4297 10H26.7282Z" fill="url(#paint1_linear_1215_9049)" />
            <path d="M32.9998 30.0695H26.7041L25.5855 26.4509V26.448L24.0596 21.5071L27.2089 11.3125L32.9998 30.0695Z" fill="url(#paint2_linear_1215_9049)" />
            
            <path style={{mixBlendMode:'screen'}} opacity="0.4" d="M18.6969 10.5898L7.7998 29.4797L13.635 10.5898H18.6969Z" fill="white" />
            
            <path style={{mixBlendMode:'screen'}} opacity="0.4" d="M25.9283 10.5898L15.0312 29.4797L20.8664 10.5898H25.9283Z" fill="white" />
            
            <path style={{mixBlendMode:'multiply'}} opacity="0.3" d="M27.2094 11.3125L25.5861 26.4495L24.0596 21.5071L27.2094 11.3125Z" fill="black" />

            <path style={{mixBlendMode:'multiply'}} opacity="0.3" d="M26.7282 10L20.53 30.0704H14.2314C15.2822 30.0084 16.33 29.9819 17.3807 29.9347L18.9539 29.8874L20.3618 29.8547C20.8281 28.2225 21.3092 26.5933 21.8051 24.9672L22.5725 22.4584L23.3576 19.9526C23.8918 18.285 24.4319 16.6203 24.9898 14.9586C25.2731 14.1321 25.5506 13.2998 25.8428 12.4763C26.135 11.6529 26.4213 10.8202 26.7282 10Z" fill="black" />

            <path style={{mixBlendMode:'multiply'}} opacity="0.3" d="M19.4968 10L13.2986 30.0704H7C8.05075 30.0084 9.09854 29.9819 10.1493 29.9347L11.7225 29.8874L13.1303 29.8547C13.5967 28.2225 14.0778 26.5933 14.5736 24.9672L15.341 22.4584L16.1261 19.9526C16.6604 18.285 17.2005 16.6203 17.7583 14.9586C18.0417 14.1321 18.3191 13.2998 18.6113 12.4763C18.9035 11.6529 19.1898 10.8202 19.4968 10Z" fill="black" />

            <path style={{mixBlendMode:'multiply'}} opacity="0.3" d="M17.7735 10C17.012 10.0794 16.2505 10.1299 15.4861 10.1682C15.1053 10.1889 14.7246 10.2096 14.3438 10.2214C14.028 10.2332 13.7122 10.245 13.3964 10.2509C13.299 10.5962 13.2016 10.9389 13.1012 11.2839L12.7382 12.4855C12.6201 12.8836 12.4873 13.2824 12.3634 13.6809C12.2394 14.0793 12.1095 14.4778 11.9767 14.8733C11.8439 15.2688 11.714 15.6702 11.5724 16.0627C11.4307 16.4553 11.2979 16.8538 11.1532 17.2463C10.8669 18.0314 10.5688 18.8077 10.2412 19.578L13.1986 10.0003L17.7735 10Z" fill="black" />

            <path style={{mixBlendMode:'multiply'}} opacity="0.3" d="M25.1048 10C24.3256 10.0794 23.5464 10.1299 22.7672 10.1682C22.3776 10.1915 21.988 10.2092 21.5984 10.2214C21.2738 10.2332 20.952 10.245 20.6274 10.2535L20.3234 11.3075L19.9544 12.5354L19.5707 13.757C19.4438 14.1676 19.311 14.5716 19.1752 14.976C19.0394 15.3804 18.9066 15.7877 18.762 16.192C18.6174 16.5964 18.4846 17.001 18.334 17.3995C18.0448 18.2053 17.7378 19.0051 17.4043 19.7961L20.4296 10H25.1048Z" fill="black" />

            <path style={{mixBlendMode:'multiply'}} opacity="0.3" d="M29.8504 29.9305C30.7979 29.8983 31.7424 29.8715 32.6898 29.8511C32.4625 29.1693 32.2412 28.4875 32.0198 27.8027C31.766 27.0265 31.5181 26.2472 31.276 25.4648C30.7831 23.9067 30.3079 22.3394 29.8357 20.7751L29.1421 18.4195L28.4691 16.0583C28.2507 15.2702 28.0293 14.4825 27.8257 13.6915C27.6161 12.8975 27.4007 12.1091 27.2088 11.3125L32.9997 30.0695H26.7041C27.7519 30.0028 28.8026 29.9751 29.8504 29.9305Z" fill="black" />

            <defs>
                <linearGradient id="paint0_linear_1215_9049" x1="13.2484" y1="10.2208" x2="13.2484" y2="30.3313" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00F3FF" />
                    <stop offset="0.238" stop-color="#00EFFC" />
                    <stop offset="0.44" stop-color="#00E5F4" />
                    <stop offset="0.629" stop-color="#00D3E7" />
                    <stop offset="0.81" stop-color="#00BAD4" />
                    <stop offset="0.983" stop-color="#009BBC" />
                    <stop offset="1" stop-color="#0098BA" />
                </linearGradient>
                <linearGradient id="paint1_linear_1215_9049" x1="20.4798" y1="10.2208" x2="20.4798" y2="30.3313" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00F3FF" />
                    <stop offset="0.238" stop-color="#00EFFC" />
                    <stop offset="0.44" stop-color="#00E5F4" />
                    <stop offset="0.629" stop-color="#00D3E7" />
                    <stop offset="0.81" stop-color="#00BAD4" />
                    <stop offset="0.983" stop-color="#009BBC" />
                    <stop offset="1" stop-color="#0098BA" />
                </linearGradient>
                <linearGradient id="paint2_linear_1215_9049" x1="30.6485" y1="30.3133" x2="21.2033" y2="24.7201" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3E6896" />
                    <stop offset="0.247" stop-color="#355881" />
                    <stop offset="0.75" stop-color="#1F324E" />
                    <stop offset="1" stop-color="#131D32" />
                </linearGradient>
            </defs>
        </svg>
    )
}
