import React from "react";

export const PersonIcon = ({ width = "100%", color = '#009bdd', id="" }) => {

  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 21 21"
    >
      <path
        id="Icon_awesome-user-alt"
        data-name="Icon awesome-user-alt"
        d="M10.5,11.813A5.906,5.906,0,1,0,4.594,5.906,5.908,5.908,0,0,0,10.5,11.813Zm5.25,1.313H13.49a7.14,7.14,0,0,1-5.98,0H5.25A5.25,5.25,0,0,0,0,18.375v.656A1.969,1.969,0,0,0,1.969,21H19.031A1.969,1.969,0,0,0,21,19.031v-.656A5.25,5.25,0,0,0,15.75,13.125Z"
        fill={color}
      />
    </svg>
  );
}