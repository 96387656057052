import { Grid, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { PrimaryButton } from '../../../components/button/PrimaryButton'
import { ControlledSwitch } from '../../../components/Inputs/ControlledSwitch'
import { InputRounded } from '../../../components/Inputs/InputRounded'
import { SelectInputRounded } from '../../../components/Inputs/SelectInputRounded'
import { CustomTypography } from '../../../components/typography/CustomTypography'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreateParametersConfig, IBasicData, IParametrization } from '../types'
import {
  createParametrization,
  getParametrization,
  getRepositories,
  updateParametrization
} from '../Services/CompanyServices'
import useDialog from '../../../hooks/useAlertConfirm'
import useAsync from '../../../hooks/useAsync'
import FullLoader from '../../../components/Loader/FullLoader.component'
import { EditButton } from '../../../components/Button/EditButton'

const ParametrizationSchema = yup.object().shape({
  encryptedKey: yup.string(),
  generateATDP: yup.bool(),
  repository: yup.string(),
  templatePathATDP: yup.string(),
  urlATDP: yup.string(),
  userStamp: yup.string(),
  passwordStamp: yup.string(),
  signATDP: yup.bool(),
  signTyC: yup.bool(),
  userSign: yup.string(),
  passwordSign: yup.string(),
  automaticEmail: yup.bool()
})
//type ParametrizationFormData = yup.InferType<typeof ParametrizationSchema>;

const initialValues: IParametrization = {
  automaticEmail: false,
  encryptedKey: '',
  generateATDP: false,
  repository: '',
  templatePathATDP: '',
  urlATDP: '',
  userStamp: '',
  passwordStamp: '',
  signATDP: false,
  signTyC: false,
  userSign: '',
  passwordSign: ''
}

type Colors = {
  buttonColor?: string
  buttonTextColor?: string
}
export interface CompanyParametrizationData {
  token: string
  companyId: string
  color?: Colors
}

type IDataBool = {
  automaticEmail: boolean
  signTyC: boolean
  signATDP: boolean
  generateATDP: boolean
}

export const CompanyParametrization = ({
  token,
  companyId,
  color
}: CompanyParametrizationData) => {
  const [repositories, setRepositories] = React.useState<IBasicData[]>([])
  const [params, setParams] = React.useState<IParametrization | undefined>()
  useEffect(() => {
    if (!repositories.length) {
      getRepositories(token)
        .then((response) => {
          if (response.status === 200) {
            const repositories = response.data.result.records
            setRepositories(repositories)
          }
        })
        .catch((error) => console.error(error))
    }
  }, [])

  useEffect(() => {
    if (companyId) {
      getParametrization(token, companyId)
        .then((response) => {
          if (response.status === 200) {
            const { data } = response
            const { parameters } = data.result
            setParams(parameters)
            Object.entries(parameters).forEach((key) => {
              //setValue(key[0], key[1])
              const name: any = key[0]
              switch (name) {
                case 'automaticEmail':
                  setValue(
                    name,
                    JSON.parse(key[1] === 'false' ? 'false' : 'true')
                  )
                  break
                case 'generateATDP':
                  setValue(
                    name,
                    JSON.parse(key[1] === 'false' ? 'false' : 'true')
                  )
                  break
                case 'signATDP':
                  setValue(
                    name,
                    JSON.parse(key[1] === 'false' ? 'false' : 'true')
                  )
                  break
                case 'signTyC':
                  setValue(
                    name,
                    JSON.parse(key[1] === 'false' ? 'false' : 'true')
                  )
                  break
                default:
                  setValue(name, key[1])
                  break
              }
            })
          }
        })
        .catch((error) => console.error(error))
    }
  }, [companyId])

  const dataBol: IDataBool = {
    automaticEmail:
      params?.automaticEmail?.toString() === 'true' ? true : false,
    signTyC: params?.signTyC?.toString() === 'true' ? true : false,
    signATDP: params?.signATDP?.toString() === 'true' ? true : false,
    generateATDP: params?.generateATDP?.toString() === 'true' ? true : false
  }
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<IParametrization>({
    mode: 'onSubmit',
    resolver: yupResolver(ParametrizationSchema),
    defaultValues: params
      ? {
          ...params,
          automaticEmail: dataBol?.automaticEmail,
          signTyC: dataBol.signTyC,
          signATDP: dataBol.signATDP,
          generateATDP: dataBol.generateATDP
        }
      : initialValues,
    shouldUnregister: false
  })
  const [isEdit, setIsEdit] = React.useState(false)
  const [isNew] = React.useState(false)

  const { error, execute, loading, result } = useAsync({
    asyncFunction: isNew ? createParametrization : updateParametrization
  })
  const [message, setMessage] = React.useState<string>()
  const { Dialog, onOpen } = useDialog({
    bodyText: message ? message : 'Parametrizacion creada con éxito',
    cancelButtonText: 'Cerrar'
  })

  useEffect(() => {
    if (result) {
      if (result.status === 200) {
        setIsEdit(false)
        onOpen()
        setMessage(result.data.result.information)
      }
    } else if (error) {
      onOpen()
      setMessage(error.message)
    }
  }, [result, error])

  const formSubmit = (data: any) => {
    let processedData = {}
    for (const key in data) {
      processedData = { ...processedData, [key]: data[key].toString() }
    }
    const params: CreateParametersConfig = {
      companyId: 'c6937b97-8a44-454e-84c4-71a9a6d13455',
      parameters: processedData
    }
    execute(params, token)
  }

  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        formSubmit(data)
      })}
    >
      <Grid container justify='center' spacing={2}>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          spacing={1}
          justifyContent='space-between'
        >
          <Grid container item lg={4} md={8} sm={12} xs={12}>
            <InputRounded
              //defaultValue={initialValues.encryptedKey}
              name='encryptedKey'
              label='llave de encriptación'
              props={{
                name: 'encryptedKey',
                helperText: errors.encryptedKey?.message,
                error: errors.encryptedKey ? true : false,
                disabled: !isEdit
              }}
              control={control}
            />
          </Grid>
          <Grid
            container
            item
            lg={2}
            md={2}
            sm={3}
            xs={3}
            style={{ marginTop: 10, marginRight: 10 }}
            justifyContent='flex-end'
          >
            <EditButton
              isEditing={isEdit}
              onClick={() => {
                setIsEdit(!isEdit)
              }}
            />
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
          <Grid
            container
            item
            lg={3}
            md={6}
            sm={12}
            xs={12}
            spacing={2}
            alignItems='center'
            justifyContent='flex-start'
          >
            {/* subsection */}
            <Grid item lg={5} md={8} sm={4} xs={4}>
              <CustomTypography type='definition'>
                {'Generar ATDP'}
              </CustomTypography>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={6}>
              {' '}
              <ControlledSwitch
                error={errors.generateATDP ? true : false}
                helperText={errors.generateATDP?.message}
                //defaultValue={initialValues.generateATDP}
                control={control}
                name='generateATDP'
                isDisabled={!isEdit}
                //checked={true}
              />
            </Grid>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <SelectInputRounded
              //defaultValue={initialValues.repository}
              control={control}
              name='repository'
              label='Repositorio'
              props={{
                helperText: errors.repository?.message,
                error: errors.repository ? true : false,
                disabled: !isEdit
              }}
              options={repositories.map((item) => ({
                key: parseInt(item.id, 10),
                value: item.id,
                label: item.name
              }))}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputRounded
              label='Ruta plantilla ATDP'
              //defaultValue={initialValues.templatePathATDP}
              props={{
                name: 'templatePathATDP',
                helperText: errors.templatePathATDP?.message,
                error: errors.templatePathATDP ? true : false,
                disabled: !isEdit
              }}
              control={control}
              name='templatePathATDP'
            />
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputRounded
              label='URL ATDP'
              control={control}
              name='urlATDP'
              //defaultValue={initialValues.urlATDP}
              props={{
                name: 'urlATDP',
                helperText: errors.urlATDP?.message,
                error: errors.urlATDP ? true : false,
                disabled: !isEdit
              }}
            />
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
          {/*  user print section     */}
          <CustomTypography type='subtitle'>
            {'Usuario de estampa'}
          </CustomTypography>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent='flex-start'
          spacing={1}
        >
          {/*subsection estampa */}
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputRounded
              label='Usuario'
              name='userStamp'
              control={control}
              //defaultValue={initialValues.userStamp}
              props={{
                name: 'userStamp',
                helperText: errors.userStamp?.message,
                error: errors.userStamp ? true : false,
                disabled: !isEdit
              }}
            />{' '}
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            {' '}
            <InputRounded
              label='Contraseña'
              name='passwordStamp'
              control={control}
              //defaultValue={initialValues.passwordStamp}
              props={{
                name: 'passwordStamp',
                helperText: errors.passwordStamp?.message,
                error: errors.passwordStamp ? true : false,
                disabled: !isEdit
              }}
            />{' '}
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent='flex-start'
        >
          <Grid
            container
            item
            lg={4}
            md={6}
            sm={12}
            xs={12}
            spacing={2}
            alignItems='center'
            justifyContent='flex-start'
          >
            {' '}
            <Grid item lg={3} md={8} sm={5} xs={4}>
              <CustomTypography type='definition'>
                {'Firmar ATDP'}
              </CustomTypography>
            </Grid>
            <Grid item lg={6} md={4} sm={6} xs={6}>
              {' '}
              <ControlledSwitch
                error={errors.signATDP ? true : false}
                helperText={errors.signATDP?.message}
                //defaultValue={initialValues.signATDP}
                control={control}
                name='signATDP'
                isDisabled={!isEdit}
                //checked={true}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            spacing={2}
            alignItems='center'
            justifyContent='flex-start'
          >
            <Grid item lg={5} md={10} sm={6} xs={8}>
              <CustomTypography type='definition'>
                {'Firmar Términos y condiciones'}
              </CustomTypography>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={4}>
              {' '}
              <ControlledSwitch
                error={errors.signTyC ? true : false}
                helperText={errors.signTyC?.message}
                //defaultValue={initialValues.signTyC}
                control={control}
                name='signTyC'
                isDisabled={!isEdit}
                //checked={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          spacing={1}
          alignItems='center'
        >
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <CustomTypography type='definition'>
              {'Firma desatendida'}
            </CustomTypography>
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <InputRounded
              label='Usuario'
              name='userSign'
              control={control}
              //defaultValue={initialValues.userSign}
              props={{
                name: 'userSign',
                helperText: errors.userSign?.message,
                error: errors.userSign ? true : false,
                disabled: !isEdit
              }}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <InputRounded
              label='Contraseña'
              name='passwordSign'
              control={control}
              //defaultValue={initialValues.passwordSign}
              props={{
                name: 'passwordSign',
                helperText: errors.passwordSign?.message,
                error: errors.passwordSign ? true : false,
                disabled: !isEdit
              }}
            />
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12}>
          <CustomTypography type='subtitle'>
            {'Envío ATDP por correo electrónico al participante'}
          </CustomTypography>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          direction='row'
          spacing={1}
        >
          <Grid item lg={1} md={2} sm={6} xs={4}>
            {' '}
            <Typography>{'Automático'}</Typography>
          </Grid>
          <Grid item lg={1} md={6} sm={6} xs={4}>
            <ControlledSwitch
              error={errors.automaticEmail ? true : false}
              helperText={errors.automaticEmail?.message}
              //defaultValue={initialValues.automaticEmail}
              control={control}
              name='automaticEmail'
              isDisabled={!isEdit}
              //checked={true}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent='center'>
          <Grid item lg={2} md={3} sm={6} xs={12}>
            <PrimaryButton
              title={isNew ? 'Guardar' : 'Actualizar'}
              props={{
                disabled: !isEdit,
                type: 'submit'
              }}
              fullWidth={false}
              personalizationColor={{
                primaryColor: color?.buttonColor || '',
                textButtonColor: color?.buttonTextColor || ''
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog />
      <FullLoader open={loading} viewLoader={true} />
    </form>
  )
}
