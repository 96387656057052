import React from 'react'

export const BackArrowMenuicon = ({
    width = '25px',
    id
}: any) => {
    if(width === null)
        width = '25px';
    return (
        <svg id={id} width={width} height={width} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 12.7637C2 10.1115 3.05357 7.56797 4.92893 5.6926C6.8043 3.81724 9.34784 2.76367 12 2.76367C13.3132 2.76367 14.6136 3.02233 15.8268 3.52488C17.0401 4.02742 18.1425 4.76402 19.0711 5.6926C19.9997 6.62119 20.7362 7.72358 21.2388 8.93684C21.7413 10.1501 22 11.4505 22 12.7637C22 15.4158 20.9464 17.9594 19.0711 19.8347C17.1957 21.7101 14.6522 22.7637 12 22.7637C10.6868 22.7637 9.38642 22.505 8.17317 22.0025C6.95991 21.4999 5.85752 20.7633 4.92893 19.8347C3.05357 17.9594 2 15.4158 2 12.7637ZM18 11.7637H10L13.5 8.26367L12.08 6.84367L6.16 12.7637L12.08 18.6837L13.5 17.2637L10 13.7637H18V11.7637Z" fill="#6D6E71" />
        </svg>
    )

}