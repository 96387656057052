import {
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { PrimaryButton } from "../button/PrimaryButton";

interface LoadModalProps {
  open: boolean;
  icon: JSX.Element;
  title: string;
  message: string;
  handleConfirm: any;
  handleCancel: any;
  isConfirm: boolean;
  confirmText: string;
  cancelText: string;
  isCancel: boolean;
  messageLoadModal: string;
  flagMessageLoad: boolean;
  personalizationColor?:jsonColor
}

export const LoadModal: React.FC<LoadModalProps> = ({
  open,
  icon,
  title,
  message,
  handleConfirm,
  handleCancel,
  isConfirm,
  confirmText,
  cancelText,
  isCancel,
  messageLoadModal,
  flagMessageLoad,
  personalizationColor,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles({personalizationColor:personalizationColor});
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="custom-dialog"
      // disableBackdropClick
    >
      <Grid container direction="row-reverse">
        <Grid item lg={1} sm={1} xs={1} md={1}>
          <br/>
        </Grid>
      </Grid>
      {isConfirm && isCancel ? (
        <DialogContent>
          <Grid
            container
            spacing={2}
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justify="center"
              className={classes.message}
            >
              <Typography className={classes.titleTypography}>
                {title}
              </Typography>
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justify="center"
              className={classes.icon}
            >
              {icon}
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justify="center"
              className={classes.message}
            >
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.messageTypography}
              >
                {message}
              </Typography>
            </Grid>

            <Grid item>
              <PrimaryButton
                title={confirmText}
                props={{
                  onClick: handleConfirm,
                  style: { width: "100px", textTransform: "capitalize" },
                }}
                fullWidth={false}
                personalizationColor={personalizationColor}
              ></PrimaryButton>
            </Grid>
            <Grid item>
              <PrimaryButton
                title={cancelText}
                props={{
                  onClick: handleCancel,
                  style: { width: "100px", textTransform: "capitalize" },
                }}
                fullWidth={false}
                personalizationColor={personalizationColor}
              ></PrimaryButton>
            </Grid>
          </Grid>
        </DialogContent>
      ) : isConfirm && !isCancel ? (
        <DialogContent>
          <Grid
            container
            spacing={2}
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justify="center"
              className={classes.message}
            >
              <Typography className={classes.titleTypography}>
                {title}
              </Typography>
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justify="center"
              className={classes.icon}
            >
              {icon}
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justify="center"
              className={classes.message}
            >
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.messageTypography}
              >
                {message}
              </Typography>
            </Grid>

            <Grid item>
              <PrimaryButton
              title={confirmText}
              props={{
                onClick: () => { (confirmText === 'Aceptar') ? handleCancel() : handleConfirm() },
                style: { width: "100px", textTransform: "capitalize" }
                }}
                fullWidth={false}
                personalizationColor={personalizationColor}
                ></PrimaryButton>
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <Grid
            container
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justify="center"
              className={classes.message}
            >
              <Typography className={classes.titleTypography}>
                {title}
              </Typography>
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justify="center"
              className={classes.icon}
            >
              {icon}
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justify="center"
              className={classes.message}
            >
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.messageTypography}
              >
                {message}
              </Typography>
              <br/>
              {
                flagMessageLoad && (
                  <Typography
                  component="h1"
                  color="textPrimary"
                  className={classes.messageLoadTypography}
                >
                  {messageLoadModal}
                </Typography>
                )
              }
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles((theme: any) => ({
  icon: {
    height: 80,
    width: 92,
    margin: 10,
  },
  dialogTitle: {
    height: 12,
  },
  message: {
    marginBottom: 15,
    marginTop: -11,
  },
  messageTypography: {
    textAlign: "center",
    width: "50%",
    marginTop: 30,
    fontWeight: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(16),
    letterSpacing: 0,
  },
  titleTypography: (props: any) =>({
    textAlign: "center",
    color: props.personalizationColor.secondaryColor! !== "" ? props.personalizationColor.secondaryColor! : theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(20),
    letterSpacing: 0,
  }),
  messageLoadTypography: {
    textAlign: "center",
    width: "51%",
    marginTop: 15,
    fontWeight: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(16),
    letterSpacing: 0,
  },
}));
