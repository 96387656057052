import {
  makeStyles,
  Theme,
  Typography,
  TypographyProps
} from '@material-ui/core'
import React from 'react'

type CustomTypographyProps = {
  children: string
  type: 'title' | 'subtitle' | 'definition' | 'helperText' | 'alertError'
  props?: TypographyProps
}

export const CustomTypography = ({
  children,
  type,
  props
}: CustomTypographyProps) => {
  const classes = useStyles()
  const defineType = () => {
    switch (type) {
      case 'title':
        return classes.title
      case 'subtitle':
        return classes.subtitle
      case 'definition':
        return classes.definition
      case 'helperText':
        return classes.helperText
      case 'alertError':
        return classes.alertError
      default:
        return classes.basic
        break
    }
  }

  return (
    <Typography className={defineType()} {...props}>
      {children}
    </Typography>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  basic: {},
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '22px',
    opacity: 0.7
  },
  subtitle: {
    color: theme.palette.primary.light,
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: 0,
    textAlign: 'left'
  },
  helperText: {
    color: theme.palette.secondary.main,
    fontSize: '11px/17px',
    letterSpacing: 0
  },
  definition: {
    fontSize: '16px',
    color: '#575757',
    textAlign: 'left',
    letterSpacing: 0,
  },
  alertError: {
    color: '#FF0000',
    fontSize: '12px',
    letterSpacing: 0
  },
}))
