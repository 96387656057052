import { makeStyles, SvgIcon, Theme } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme:Theme) => ({
  btnNewUser: {
    flex: 1,
    textAlign: "right",
    paddingRight: "8px",
    width: "32px",
    height: "32px",
    marginRight: 40,
    background: `${theme.palette.common.white} 0% 0% no-repeat padding-box`,
    boxShadow: "0px 2px 2px #0000003D",
    border: `1px solid ${theme.palette.secondary.main}`,
    opacity: "1",
  },
  newUserIcon: (props: any) => ({
    stroke: props.personalizationcolor.secondaryColor !== "" ? props.personalizationcolor.secondaryColor : theme.palette.secondary.main,
  }),
}));

type NewUserIconProps = {
  personalizationcolor?: jsonColor
}

const NewUserIcon = ({personalizationcolor}: NewUserIconProps) => {
  const classes = useStyles(
    {personalizationcolor: personalizationcolor}
  );

  return (
    <SvgIcon
      className={classes.newUserIcon}
      viewBox="0 0 39 34"
      style={{ width: "1.9em", height: "2.1em" }}
    >
      <defs>
        <filter
          id="a"
          x="0"
          y="0"
          width="39.003"
          height="39.003"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" result="b" />
          <feFlood floodOpacity="0.239" />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(3.502 1.502)">
        <g
          style={{ filter: 'url("#a")' }}
          transform="matrix(1, 0, 0, 1, -3.5, -1.5)"
        >
          <path
            style={{ fill: "#fff" }}
            d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z"
            transform="translate(3.5 1.5)"
          />
        </g>
        <g transform="translate(9.685 9.685)">
          <line
            style={{
              fill: "none",
              strokeMiterlimit: 10,
              strokeWidth: "2.4px",
            }}
            y2="12.63"
            transform="translate(6.315)"
          />
          <line
            style={{
              fill: "none",
              strokeMiterlimit: 10,
              strokeWidth: "2.4px",
            }}
            x2="12.63"
            transform="translate(0 6.315)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default NewUserIcon;