import React from 'react'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

interface jsonColor {
  primaryColor?: string
  secondaryColor?: string
  buttonColor?: string
  textButtonColor?: string
}
interface EditButtonProps {
  isEditing?: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
  color?: jsonColor
}

export const EditButton = ({ isEditing, onClick, color }: EditButtonProps) => {
  const width = isEditing ? 136 : 80
  const colorPersonalization = color
  const classes = useStyles({
    width,
    colorPersonalization
  })
  return (
    <Button
      variant='contained'
      color='secondary'
      onClick={onClick}
      className={classes.button}
      startIcon={isEditing ? <CloseIcon /> : <EditIcon />}
    >
      {isEditing ? 'Cancelar edición' : 'Editar'}
    </Button>
  )
}
interface Props {
  width: number
  colorPersonalization?: jsonColor
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    background: '#FFFFFF',
    borderRadius: 3,
    border: '1px solid #A8A8A8',
    textTransform: 'capitalize',
    height: 30,
    width: (props: Props) => props.width,
    padding: '0 6px',
    color: `#575757 !important`,
    fontSize: 12,
    opacity: '1',
    '&&:hover': {
      background: (props: Props) =>
        !!props.colorPersonalization &&
        props.colorPersonalization.primaryColor !== ''
          ? props.colorPersonalization.primaryColor
          : theme.palette.secondary.main,
      color: `#FFFFFF !important`,
      border: 'none',
      boxShadow: 'none'
    }
  }
}))
