import React from 'react'
import { CircularProgress, Dialog, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    background: 'transparent 0% 0% no-repeat padding-box !important',
    opacity: 1,

    '& .MuiDialog-paper': {
      overflowY: 'hidden',

      width: 'auto'
    },
    '& .MuiDialog-paperWidthSm': {
      background: 'transparent  !important',
      opacity: 1,
      width: '50px !important'
    }
  }
}))

type FullLoaderProps = {
  handleClose?: any
  open: boolean,
  viewLoader: boolean
  circularProps?: {
    id: string,
    className?: string,
  }
}

const FullLoader = ({ handleClose, open, viewLoader, circularProps }: FullLoaderProps) => {
  const classes = useStyles()

  return (
    <Dialog
      onClose={handleClose}
      className={classes.root}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden'
        }
      }}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      {
        viewLoader ? <CircularProgress id={circularProps?.id ? circularProps?.id : 'main-circular-loading'} className={circularProps?.className ? circularProps?.className : 'circular-loading-cs'} /> : <div></div>
      }

    </Dialog>
  )
}

export default FullLoader
