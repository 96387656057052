import { IBasicData } from "../views/Company/types"

export const DRAWER_WIDTH = 300

export const MESSAGES = {
  required: 'Campo obligatorio *',
  max: 'longitud sobrepasada maximo :',
  typeError: 'el valor ingresado debe ser :'
}

export const LIST_PERSON_TYPE: IBasicData[] = [
  {
    id:'1',
    name:'Persona jurídica',
    code:'J'
  },
  {
    id:'2',
    name:'Persona natural',
    code:'N'
  }
]
