import React from 'react';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { DRAWER_WIDTH } from '../../utils/const';
import { useRef } from "react";
import { PrimaryButton } from "../button/PrimaryButton";
import { getImageFromList } from '../../assets/Icons';

interface jsonColor {
  primaryColor?: string,
  secondaryColor?: string,
  buttonColor?: string,
  textButtonColor?: string,
}
type MassiveLoadInfoProps = {
    title: string,
    onDownload: (any),
    onUpload: (any),
    personalizationColor?:jsonColor
}

const MassiveLoadInfo = (props:MassiveLoadInfoProps) => {
    const theme = useTheme();
    const fileUpload = useRef(document.createElement("input"));
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const drawerWidth = !isMobile ? DRAWER_WIDTH : 0;
    const padding = !isMobile ? "0px 40px 40px 40px" : 0;
    const classes = useStyles({
        drawerWidth: drawerWidth,
        padding: padding,
        isMobile,
        personalizationColor: props.personalizationColor
    });

    const upload = () => {
        fileUpload.current.click();
    }
    
    return (
            // <Grid container spacing={3}>
            <Grid container item lg={12} md={12} sm={12}>
                <Grid item xs={12} sm={4} md={12} lg={12} xl={12}>
                    <div className={classes.title}>{props.title}</div>
                    <br></br>
                    <img src={getImageFromList('step-by-step')} alt="step-by-step" style={{width:"100%"}} />                    
                </Grid>
                <Grid item xs={12} sm={4} md={12} lg={12} xl={12}>
                    <Grid container spacing={2} justifyContent="space-evenly" direction="row">
                        <Grid item>
                            <PrimaryButton title={'Descargar plantilla'} fullWidth={false} personalizationColor={props.personalizationColor} props={{ onClick: props.onDownload, startIcon: <img src={getImageFromList('downloadIcon')} alt="download" className={'imageInfo'} />, style: { textTransform: "capitalize" } }}></PrimaryButton>
                        </Grid>
                        <Grid item>
                            <PrimaryButton title={'Cargar plantilla masiva'} fullWidth={false} personalizationColor={props.personalizationColor} props={{ onClick: upload, startIcon: <img src={getImageFromList('uploadIcon')} alt="upload" className={'imageInfo'} />, style: { textTransform: "capitalize" } }}></PrimaryButton>
                            <input
                                type="file"
                                accept=".xlsx"
                                ref={fileUpload}
                                onChange={(e) => props.onUpload(e)}
                                hidden
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>        
    );
};

export default MassiveLoadInfo;

const useStyles = makeStyles((theme: any) => ({
    root: {
        flexGrow: 1
    },
    title: (props: any) => ({
        fontSize: props.isMobile ? "22px" : "24px",
        fontWeight: theme.typography.fontWeightBold,
        color: props.personalizationColor.primaryColor! !=="" ? props.personalizationColor.primaryColor!:theme.palette.primary.main,
        padding: "10px 0px 10px 0px",
        marginTop: 10,
    }),
}));