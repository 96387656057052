import { TextField, TextFieldProps, Theme, makeStyles} from "@material-ui/core";
import React from "react";
import { CustomThemeProvider } from "../../theme/CustomThemeProvider";
import { Controller } from "react-hook-form";
import InputMask, { BeforeMaskedStateChangeStates, InputState } from 'react-input-mask'

type InputMaskProps = {
  mask: string | Array<(string | RegExp)>
  beforeMaskedStateChange?: ((states: BeforeMaskedStateChangeStates) => InputState) | undefined
  alwaysShowMask?: boolean | undefined;
  maskChar?: string | null | undefined
}

type InputRoundedProperties = {
  label: string
  props?: TextFieldProps
  maskProps?: InputMaskProps
  name: string
  defaultValue?: any
  control: any
  rules?: any
  onChange?: Function
  mask: string | Array<(string | RegExp)>
  beforeMaskedStateChange?: ((states: BeforeMaskedStateChangeStates) => InputState) | undefined
  alwaysShowMask?: boolean | undefined
  maskChar?: string | null | undefined
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  autoFocus?: boolean
}

const defaultMask: InputMaskProps = {
  mask: "",
  maskChar: null
}

export const InputMasked = ({
  control,
  label,
  name,
  defaultValue,
  rules,
  maskProps,
  props,
  mask,
  alwaysShowMask,
  maskChar,
  onFocus,
  autoFocus,
  beforeMaskedStateChange,
}: InputRoundedProperties) => {
  const classes = useStyles()
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <InputMask
          maskChar={maskChar ? maskChar : defaultMask.maskChar}
          mask={mask ? mask : defaultMask.mask}
          value={value} onChange={onChange}
          beforeMaskedStateChange={beforeMaskedStateChange}
          alwaysShowMask={alwaysShowMask}
          disabled={props?.disabled ? props?.disabled : undefined}
          autoFocus={autoFocus}
          onFocus={onFocus}
          style={{ border: "none !important", borderRadius: "0px !important", outline: 0}}
          {...maskProps}
        >
          {
            ( inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                error={!!props?.error}
                helperText={props?.helperText}
                InputLabelProps={{
                  classes: { focused: classes.LabelTextFocused, shrink: classes.labelTextShrink}
                }}
                label={label}
                variant="outlined"
                type="text"
                fullWidth
                autoFocus={autoFocus}
                onFocus={onFocus}
                className={classes.inputCustom}
                {...inputProps}
                disabled={props?.disabled ? props?.disabled : undefined}
                InputProps={{
                  classes: { disabled: classes.textFieldDisabled}
                }}
              />
            )
          }
        </InputMask>
      )}
    />
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#FFFFFF !important',

    '& .MuiFilledInput-root': {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 3px #0000001A',
      border: '1px solid #D5D5D5',
      borderRadius: '10px',
      opacity: 1,
      margin: 2
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primary.light
    },
    '& .MuiInputBase-input': {
      textAlign: 'left',
      font: 'normal normal normal 16px/17px Muli',
      letterSpacing: '0px',
      color: '#575757',
      opacity: 1
    }
  },

  inputCustom: {
    '& input': {
      background: 'white',
      boxShadow: '0px 3px 3px #0000001A',
      border: '1px solid #D5D5D5 !important',
      borderRadius: '10px !important',
      padding: '27px 12px 10px !important'
    },

    '& :invalid': {
      boxShadow: '0px 3px 3px #0000001A',
    },

    '& div': {
      background: 'none !important',
    },

    '& label': {
      fontSize: '16px',
      font: 'normal normal normal 16px/17px Muli',
    },

    '& fieldset': {
      display: 'none'
    }
  },
  textFieldDisabled: {
    opacity: '1 !important',
  },

  LabelTextFocused: {
    transform: 'translate(12px, 10px) scale(0.75) !important',
    font: 'normal normal normal 16px/17px Muli',
  },
  labelTextShrink:{
    transform: 'translate(12px, 10px) scale(0.75) !important',
  }
}))

export const InputRoundedMask = (props: InputRoundedProperties) => {
  return (
    <CustomThemeProvider>
      <InputMasked {...props}/>
    </CustomThemeProvider>
  )
}



