import React from 'react'

export const PurchaseOrderIcon = ({
    width = '22px',
    height = '20.322px',
    id
}: any) => {
    if(width === null)
        width = '22px';
        height = '20.322px';
        return (
            <svg
                id={id}
                width={width}
                height={height}
                viewBox="0 0 22 20.322"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                
                <path id="ico_orden" data-name="ico orden" d="M11.983,19.322c-.089-.054-.185-.1-.279-.152a2.177,2.177,0,0,1-.567-.372,1.42,1.42,0,0,1-.4-1.121,1.533,1.533,0,0,1,.622-1.111,1.52,1.52,0,1,1,1.606,2.569,2.571,2.571,0,0,1-.275.122c-.054.021-.106.041-.158.065Zm-7.628,0a1.621,1.621,0,0,1-1.22-1.1A1.542,1.542,0,0,1,4.146,16.33a1.52,1.52,0,0,1,.44-.066,1.527,1.527,0,0,1,.5,2.972l-.121.041-.13.044Zm9.4-3.832H9.065c-1.409,0-2.867,0-4.3,0a1.657,1.657,0,0,1-1.5-.784A1.676,1.676,0,0,1,3.338,13l.19-.323,0,0c.214-.362.436-.736.637-1.112a.49.49,0,0,0,.027-.4c-.4-.843-.8-1.695-1.186-2.518C2.561,7.7,2.1,6.719,1.649,5.755a.37.37,0,0,0-.393-.24l-.244,0c-.2,0-.4-.005-.589-.01C.283,5.5.141,5.5,0,5.5V4H2.519c.055.121.113.245.168.364.129.275.262.559.373.846a.471.471,0,0,0,.523.342c1.752,0,3.531-.006,5.252-.007H9a6.472,6.472,0,0,0,3.6,5.776l-.023.043-.078.145a1.633,1.633,0,0,1-1.575.926h-.021c-.83-.005-1.665-.005-2.472-.005-.876,0-1.782,0-2.67-.005a.452.452,0,0,0-.492.3c-.08.185-.179.364-.274.536-.05.091-.1.184-.15.278-.09.175-.113.275-.076.336s.138.087.335.087c1.781.005,3.593.005,5.345.005H13.76v1.524h0ZM10,5.5A5.5,5.5,0,1,1,15.5,11,5.506,5.506,0,0,1,10,5.5Zm2.943-.162a.653.653,0,0,0-.075.51.686.686,0,0,0,.337.424L15.071,7.3a.748.748,0,0,0,.359.092.729.729,0,0,0,.623-.342l.006-.01c.005-.009.01-.018.014-.027l.011-.017,1.948-3.227a.736.736,0,0,0,.088-.576.657.657,0,0,0-.321-.409.694.694,0,0,0-.336-.085.8.8,0,0,0-.677.389L15.167,5.771l-1.243-.683A.742.742,0,0,0,13.566,5,.729.729,0,0,0,12.943,5.338Z" transform="translate(0.5 0.5)" fill="#6d6e71" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
               
            </svg>
        )
}