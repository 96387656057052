import React from 'react'

export const PackageIcon = ({
    width = '14px',
    height = '17.001px',
    id
}: any) => {
    if(width === null)
        width = '14px';
        height = '17.001px';
        return (
            <svg
                id={id}
                width={width}
                height={height}
                viewBox="0 0 14 17.001"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                
                <g id="ico_paquetes" data-name="ico paquetes" transform="translate(-17 -3.436)">
                    <path id="Unión_32" data-name="Unión 32" d="M22804.135-4740.563a1.143,1.143,0,0,1-1.135-1.146l.762-11.481a1.14,1.14,0,0,1,1.139-1.145h2.111c-.006-.076-.01-.152-.01-.229a3,3,0,0,1,3-3,3,3,0,0,1,3,3c0,.077,0,.153-.008.229h2.2a1.14,1.14,0,0,1,1.141,1.145l.674,11.481a1.143,1.143,0,0,1-1.139,1.146Zm7.848-13.772a1.775,1.775,0,0,0,.014-.229,2,2,0,0,0-2-2,2,2,0,0,0-2,2,2.212,2.212,0,0,0,.012.229Z" transform="translate(-22786 4761)" fill="#6d6e71"/>
                </g>
               
            </svg>
        )
}