import { makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { TextFieldProps } from '@material-ui/core'
import { Theme } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'
import { CustomThemeProvider } from '../../theme/CustomThemeProvider'

type InputRoundedProperties = {
  label: string
  props?: TextFieldProps
  name: string
  defaultValue?: any
  control: any
  rules?: any
  pattern?: RegExp
}

const InputRoundedComponent = ({
  label,
  props,
  name,
  defaultValue,
  control,
  rules,
  pattern
}: InputRoundedProperties) => {
  const classes = useStyles()
  const ALPHA_NUMERIC_DASH_REGEX = pattern ? pattern : /^[#.0-9a-zA-Z\s,-/&#<>()?¿!¡{}°_]+$/;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          // classes={{
          //   root: classes.root
          // }}
          onKeyDown={(event) => {
            if ( props?.type != "password") {
              if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }
          }}
          className={classes.inputCustom}
          label={label}
          variant='filled'
          InputProps={{
            disableUnderline: true,
            classes: { disabled: classes.textFieldDisabled }
          }}
          {...props}
        />
      )}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    //  background: "#FFFFFF 0% 0% no-repeat padding-box",
    backgroundColor: '#FFFFFF !important',

    '& .MuiFilledInput-root': {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 3px #0000001A',
      border: '1px solid #D5D5D5',
      borderRadius: '10px',
      opacity: 1,
      margin: 2
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primary.light
    },
    '& .MuiInputBase-input': {
      textAlign: 'left',
      font: 'normal normal normal 16px/17px Muli',
      letterSpacing: '0px',
      color: '#575757',
      opacity: 1
    }
  },

  inputCustom: {
    
    '& input': {
      background: 'white',
      boxShadow: '0px 3px 3px #0000001A',
      border: '1px solid #D5D5D5',
      borderRadius: '10px',
    },

    '& div': {
      background: 'none !important',
    },

    '& label': {
      fontSize: '16px',
      font: 'normal normal normal 16px/17px Muli',

    }
  },
  textFieldDisabled: {
    opacity: '1 !important'
  }
}))

export const InputRounded = (props: InputRoundedProperties) => {
  return (
    <CustomThemeProvider>
      <InputRoundedComponent {...props} />
    </CustomThemeProvider>
  )
}
