import { Theme } from '@material-ui/core'
import { Create } from '@material-ui/icons';
import {
  Box,
  Card,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core'
import React, { FormEventHandler, MouseEventHandler } from 'react'
import { ArrowIcon } from '../../assets/Icons/ArrowIcon'
import IconEspecialButton from '../Button/IconSpecialButton'

interface jsonColor {
  primaryColor?: string,
  secondaryColor?: string,
  buttonColor?: string,
  textButtonColor?: string,
}
type FormLayoutProps = {
  subheader: string
  handleSubmit: FormEventHandler<HTMLFormElement>
  handleIconClick: MouseEventHandler<HTMLElement>
  children: JSX.Element
  isEditing?: boolean
  enabledForm?: boolean
  setEnabledForm?: Function
  handleEditButton?: Function
  personalizationColor?: jsonColor
}

const FormLayout = ({
  children,
  handleEditButton,
  handleIconClick,
  handleSubmit,
  subheader,
  isEditing,
  setEnabledForm,
  enabledForm,
  personalizationColor,
}: FormLayoutProps) => {
  const classes = useStyles({personalizationColor : personalizationColor})
  return (
    <div>
      <Card className={classes.formContent}>
        <Grid container direction='row' className={classes.container}>
          <Grid
            container
            item
            lg={9}
            alignItems='center'
            style={{ paddingLeft: 20 }}
          >
            <Tooltip
              id='button-report'
              title='Atrás'
              style={{ float: 'left', margin: '5px 2px 17px 17px' }}
            >
              <IconButton area-label='Report' onClick={handleIconClick}>
                <ArrowIcon className={classes.previousArrow} />
              </IconButton>
            </Tooltip>
            <Typography
              className={classes.subtitle}
            >
              {subheader}
            </Typography>
          </Grid>
          <Grid container item lg={3} alignItems="center" justifyContent="flex-end">
            {isEditing
              ? (
                <IconEspecialButton
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={() => {
                    if (typeof handleEditButton === 'function') {
                      handleEditButton()
                    } else {
                      if (setEnabledForm !== undefined) setEnabledForm(!enabledForm)
                    }
                  }}
                  startIcon={<Create />}
                >
                  {enabledForm ? 'Cancelar edición' : 'Editar'}
                </IconEspecialButton>
              )
              : null
            }
          </Grid>
        </Grid>
        <form id='form-save' onSubmit={handleSubmit} autoComplete='off'>
          <Box mx={5}>{children}</Box>
        </form>
      </Card>
    </div>
  )
}

export default FormLayout

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingRight: '48px'
  },
  previousArrow: (props: any) =>({
    transform: 'rotate(180deg)',
    stroke: !!props.personalizationColor && props.personalizationColor.secondaryColor! !== "" ? props.personalizationColor.secondaryColor : theme.palette.secondary.main,
  }),
  nextArrow: (props: any) =>({
    stroke: !!props.personalizationColor && props.personalizationColor.secondaryColor! !== "" ? props.personalizationColor.secondaryColor : theme.palette.secondary.main,
  }),
  subtitle: (props: any) =>({
    color: props.personalizationColor.primaryColor! !== "" ? props.personalizationColor.primaryColor : theme.paletteUserMenu.Icon.primary.dark,
    fontWeight: 600,
    marginTop: '-10px',
    fontSize: '20px'
  }),
  formContent: {
    borderRadius: 10,
    backgroundColor: 'white'
  }
}))
