import { Button, Grid, Theme } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton';
import React, { SyntheticEvent, useEffect, useReducer, useState } from 'react'
import StyledMenu from '../../components/StyledMenu'
import AppsIcon from '@material-ui/icons/Apps'
import LinearProgress from '@material-ui/core/LinearProgress'
import useAsync from '../../hooks/useAsync'
import { generateTokens } from '../../actions/auth.action'
import { getMenuItems } from '../../actions/menu.action'

import useConfirmationDialog from '../../hooks/useAlertConfirm'
import { Menu } from '../../models/MenuModels'
import {
  menuReducer as reducer,
  initialState
} from '../../reducers/menu.reducer'
import { makeStyles } from '@material-ui/styles'
import { isTokenExpired, makeId } from '../../utils/utils'
import { Skeleton } from '@material-ui/lab'
import { CustomThemeProvider } from '../../theme/CustomThemeProvider'
import { SUITE_MENU, APP_THEME } from '../../const/constants'

type SuiteBarProps = {
  token: string
  appName: string
  scrollWidth?: number
}

const SuiteBarComponent = ({ token, appName, scrollWidth }: SuiteBarProps) => {
  const [contentHeight, setContentHeight] = React.useState<number>(60)
  const classes = useStyles({ contentHeight: contentHeight || 400, widthScroll: scrollWidth || 10 })
  type MessageAppProps = {
    open: boolean
    msg: string
    redirect?: string
    textButtonAccept?: string
  }
  const [anchorEl, setAnchorEl] = React.useState<EventTarget | null>(null)
  const [messageApp, setMessageApp] = React.useState<MessageAppProps>({
    open: false,
    msg: '',
    redirect: '',
    textButtonAccept: ''
  })
  const [, dispatch] = useReducer(reducer, initialState)
  const [menuList, setMenuList] = React.useState<Menu[]>([])
  const { result, error, execute, loading } = useAsync({
    asyncFunction: generateTokens
  })
  const {
    result: menuResult,
    error: menuError,
    execute: executeMenu,
    loading: loadingMenu
  } = useAsync({
    asyncFunction: getMenuItems
  })
  const { Dialog: ErrorDialog, onOpen: onErrorOpen } = useConfirmationDialog({
    bodyText: menuError,
    confirmationButtonText: 'Aceptar',
    onConfirmClick: () => closeMessage()
  })
  const { Dialog, onOpen, onClose } = useConfirmationDialog({
    bodyText: messageApp.msg,
    confirmationButtonText: messageApp.textButtonAccept,
    onConfirmClick: () => closeMessage()
  })
  const [theme, setTheme] = useState('')

  useEffect(() => {
    if (!menuList.length && !loadingMenu && token) {
      if (!isTokenExpired(token)) {
        executeMenu({
          token: `Bearer ${token}`,
          appName,
          idmenulocation: parseInt(SUITE_MENU)
        })
      }
    }
  }, [token])

  useEffect(() => {
    if (menuResult) {
      if (menuResult.statusCode === 200) {
        if (menuResult.message.records.length) {
          const menuResponse = menuResult.message.records[0]
          let menuL = JSON.parse(menuResponse.menuJson)
          setMenuList(menuL)
          setContentHeight(75 + (menuResponse.numberRows - 1) * 90)
        }
      }
      const localTheme = localStorage.getItem(APP_THEME)
      if (localTheme === 'dark') setTheme(localTheme)

      // setNumberColumn()
    } else if (menuError) {
      onErrorOpen()
    }
  }, [menuResult, menuError])

  useEffect(() => {
    if (result) {
      const response = result.message
      if (response.typeOperation === 'OPT01') {
        if (response.informationCode === 'A10') {
          window.open(response.urlApp, '_blank')
        } else if (response.informationCode === 'A20') {
          let totaCustLicen = [];
          if (!!response.enterpriseList) {
            totaCustLicen = response.enterpriseList.filter((datLis: any) => datLis.islicensed === true)
          }
          if (totaCustLicen.length > 0) {
            let msgDataLicen =
              'No dispones de licencia para el producto seleccionado en la empresa actual, puedes adquirir una licencia o cambiar la empresa por una de estas:\r\n'
            totaCustLicen.map((dat: any) => {
              return (msgDataLicen += '\r\n' + dat.name.slice(0, 28) + '...')
            })
            setMessageApp({
              redirect: response.urlApp,
              open: true,
              msg: msgDataLicen,
              textButtonAccept: 'Adquiérela'
            })
          } else {
            setMessageApp({
              redirect: response.urlApp,
              open: true,
              textButtonAccept: 'Adquiérela',
              msg: 'No dispones de licencia para el producto seleccionado en las empresas activas.'
            })
          }
        } else if (response.informationCode === 'A30') {
          setMessageApp({
            redirect: '',
            open: true,
            msg: 'El usuario no dispone un rol para acceder a esta aplicacion.',
            textButtonAccept: 'Aceptar'
          })
        }
      } else if (result.statusCode === 401) {
        setMessageApp({
          redirect: '',
          open: true,
          msg: response
        })
      }
      onOpen()
    } else if (error) {
      const errorResponse = error
      setMessageApp({
        redirect: '',
        open: true,
        msg: errorResponse
      })
      onErrorOpen()
    }
  }, [result, error])

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const closeMessage = () => {
    if (messageApp.redirect !== '') {
      setMessageApp({
        open: false,
        msg: ''
      })
      window.open(result.message.urlApp, '_blank')
    }
    setMessageApp({
      open: false,
      msg: ''
    })
    onClose()
  }

  const openApp = (appNameSelected: string) => {
    const body = {
      token: token,
      Option: 'Opt01',
      AppName: appNameSelected,
      currentApp: appName
    }

    execute(body, dispatch)
    handleClose()
  }

  const loaderSuiteMenu = () => {
    const list = new Array(6).fill(0)
    return (
      <Grid
        style={{ margin: 4 }}
        container
        spacing={2}
        direction='row'
        justifyContent='space-evenly'
        alignItems='center'
      >
        {list.map((item) => (
          <Grid
            key={makeId(7)}
            data-item={item}
            container
            item
            xs={4}
            spacing={1}
            justifyContent='flex-start'
            alignItems='center'
            className={classes.iconApp}
          >
            <Skeleton
              variant='rect'
              width={50}
              height={50}
              style={{ margin: 6 }}
            />
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <div>
      <IconButton color='default' onClick={handleClick} edge='end'>
        <AppsIcon style={{color: theme === 'dark' ? 'white': 'rgba(0, 0, 0, 0.54)'}}/>
      </IconButton>
      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        classes={classes}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        paddingtop={8}
        paddingbottom={8}
      >
        <div className={classes.titleApp}>
          {loading || loadingMenu ? <LinearProgress color='secondary' /> : ''}
        </div>
        <Grid
          className={classes.contentGrid}
        >
          {menuList.length
            ? menuList.map((menu: Menu) => (
              <span
                key={makeId(7)}
                className={classes.styleContainerImage}
              >
                <Button
                  onClick={() => openApp(menu.name)}
                  className={`${classes.buttonItem} ${classes.styleButtons}`}
                  disabled={!menu.isActivate}
                >
                  <img
                    alt='teg_con'
                    src={
                      theme === 'dark' ? menu.iconNameDark : menu.iconName
                    }
                    className={classes.styleImages}
                    style={{ opacity: menu.isActivate ? 1 : 0.4 }}
                  />
                </Button>
              </span>
            ))
            : loaderSuiteMenu()}
        </Grid>
      </StyledMenu>
      <Dialog />
      <ErrorDialog />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  contentGrid: {
    margin: '10px 0px 0px 0px',
    width: (props: any) => props.widthScroll + 330,
    maxHeight: (props: any) => props.contentHeight,
    overflowX: 'hidden',
  },
  styleContainerImage: {
    position: "relative",
    display: "inline-block",
  },
  styleButtons: {
    margin: "0px 7px 14px 7px"
  },
  styleImages: {
    width: "80px",
    height: "60px",
    objectFit: "contain"
  },
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: 0,
    overflowX: 'hidden',
    overflowY: 'scroll',
    boxShadow: '0px 3px 10px #0000004D',
    backgroundColor: theme.palette.background.paper,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '-16px',
      right: 0,
      width: 16,
      height: 15,
      backgroundColor: theme.palette.background.paper,
      borderColor: '#d3d4d5',
      borderStyle: 'solid',
      borderWidth: '1px 1px 0px 0px',
      borderRadius: 3,
      transform: 'translate(-50%, 50%) rotate(316deg)'
    }
  },
  root: {
    minWidth: 200,
    borderBlockEnd: 'none',
    borderBlockStart: '1px solid #BDBDBD',
    background: '#fafafa',
    margin: '10px 0 0 0',
    borderLeftStyle: 'none',
    borderRightStyle: 'none'
  },
  title: {
    fontSize: 10,
    alignItems: 'center',
    display: 'flex',
    marginTop: 4
  },
  titleApp: {
    background: ''
  },
  titleContent: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px'
  },
  iconApp: {
    width: '80px',
    textAlign: 'center',
    padding: '10px 10px 10px 10px',
    cursor: 'pointer'
  },
  sizeIcon: {
    width: 62
  },
  buttonItem: {
    border: 0,
    // background: '#F5F5F5',
    cursor: 'pointer'
  }
}))

export const SuiteBar = (props: SuiteBarProps) => {
  return (
    <CustomThemeProvider>
      <SuiteBarComponent {...props} />
    </CustomThemeProvider>
  )
}
