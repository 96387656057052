import React from 'react'
import Compressor from 'compressorjs'
import { useSnackbar } from 'notistack'
import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core'

type SelectFileButtonProps = {
  label: string
  setFile: Function
  message?: string
  messageState?: Function | undefined
  disabled?: boolean
  acceptedExtensions?: string
  maxFileSize?: number | boolean
  loader?: Function
}

export const SelectFileButton = ({
  label,
  setFile,
  disabled,
  acceptedExtensions,
  message,
  messageState,
  maxFileSize = false,
  loader
}: SelectFileButtonProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles()

  const validationFile = (ext: string, fileSize: number | boolean) => {
    let extensions: string[] | undefined = acceptedExtensions?.split(',')
    let allowed = true

    if (messageState !== undefined) {
      messageState('')
    }

    if (extensions?.indexOf(ext) === -1) {
      allowed = false
      if (messageState !== undefined) {
        messageState(message)
      }
    }

    if (fileSize === 0) {
      allowed = false
      enqueueSnackbar(`Archivo no válida`, {
        variant: 'error'
      })
    }

    if (maxFileSize && fileSize > 0 && fileSize > maxFileSize) {
      allowed = false
      enqueueSnackbar(`Tamaño maximo ${maxFileSize} MB`, {
        variant: 'error'
      })
    }

    return allowed
  }

  const settingImageInfo = (file: File) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setFile((oldImage: any) => ({
        ...oldImage,
        name: file.name,
        blob: reader.result
          ? reader.result.toString().replace(/^data:(.*,)?/, '')
          : '',
        extension: `.${file.type.split('/')[1]}`
      }))
    }
    reader.onerror = (error) => {
      console.error('Error: ', error)
    }
  }

  const handleFileSelected = (event: HTMLInputElement) => {
    let file: any = {}
    let fileSize: number = 0
    if (event.files) {
      fileSize = event.files ? event?.files?.[0].size / (1000 * 1024) : 0
      file = event.files[0]
      let imageAllowed = validationFile(file.type.split('/')[1], fileSize)

      if (acceptedExtensions == '') {
        settingImageInfo(file)
      } else if (imageAllowed) {
        if (fileSize >= 1 && fileSize <= 5) {
          !!loader && loader(true)
          new Compressor(file, {
            quality: 0.6,
            maxWidth: 1920,
            maxHeight: 1080,
            minWidth: 1280,
            minHeight: 720,
            convertSize: 1000000,
            success(compressedFile: File) {
              settingImageInfo(compressedFile)
              !!loader && loader(false)
            }
          })
        } else {
          settingImageInfo(file)
        }
      }
    }
  }

  return (
    <Button
      classes={{
        root: classes.root,
        disabled: classes.buttonDisabled
      }}
      variant='contained'
      component='label'
      disabled={disabled}
    >
      <Grid container alignItems='center'>
        <span className={`material-icons ${classes.icon}`}>insert_photo</span>
        <Typography className={classes.label}>{label}</Typography>
        <input
          type='file'
          hidden
          accept='image/*'
          onChange={(e) => {
            handleFileSelected(e.target)
          }}
        />
      </Grid>
    </Button>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'left',
    font: 'normal normal bold 12px/14px Muli',
    letterSpacing: '0px',
    opacity: 1,
    color: theme.palette.primary.light,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 1px 1px #00000033',
    border: '1px solid #D5D5D5',
    borderRadius: '3px'
  },
  icon: {
    color: theme.palette.primary.light,
    marginRight: 6
  },
  label: {
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    letterSpacing: '0px',
    color: '#6D6E71',
    opacity: 1
  },
  buttonDisabled: {
    opacity: '0.5 !important'
  }
}))
