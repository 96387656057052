import React, { useState } from 'react'
import { Theme, useTheme, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { OrderIcon } from '../IconsLibrary/OrderIcon'


const StyledButton = withStyles((theme: Theme) => ({
  root: (props:any) => ({
    background: theme.palette.common.white,
    borderRadius: 5,
    border: '1px solid #A8A8A8',
    height: 37,
    padding: '0 16px',
    boxShadow: 'none',
    color: '#6D6E71 !important',
    opacity: '1',
    '&&:hover': {
      background: props.personalizationcolor.secondaryColor !== "" ? props.personalizationcolor.secondaryColor : theme.palette.secondary.main,
      color: `white !important`,
      boxShadow: 'none',
      border: 'none'
    }
  }),
  label: {
    textTransform: 'capitalize'
  }
}))(Button)
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    background: '#FFFFFF !important'
  }
})((props: any) => (
  <Menu
    open={props.open}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))



type MenuOrderProps = {
  size: 'small' | 'medium' | 'large'
  disabled: boolean
  options: any
  selectItem: Function
  personalizationcolor?: jsonColor
}
const MenuOrder = ({ options, selectItem, disabled, size , personalizationcolor}: MenuOrderProps) => {
  
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedIndex, setSelectedIndex] = React.useState(null)
  const [active, setActive] = useState(false)
  const theme = useTheme()
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor:  personalizationcolor?.primaryColor !== '' ? personalizationcolor?.primaryColor : theme.palette.primary.main,
        color: theme.palette.common.white,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color:  theme.palette.common.white
        }
      },
      '& .MuiListItem-button': {
        '&:hover': {
          backgroundColor: personalizationcolor?.primaryColor !== '' ? personalizationcolor?.primaryColor : theme.palette.common.white
        }
      }
    }
  }))(MenuItem)
  
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleMenuItemClick = (_event: any, index: any) => {
    selectItem(index)
    setSelectedIndex(index)
    setAnchorEl(null)
  }

  return (
    <div>
      <StyledButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        size={size}
        disabled={disabled}
        personalizationcolor={personalizationcolor}
        onClick={handleClick}
        onMouseOver={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
        startIcon={
          <OrderIcon
            width={40}
            active={active}
            style={{
              color: active
                ? theme.palette.common.white
                : theme.palette.info.main
            }}
            className={{
              background: '#f1f1f1',
              '&:hover': {
                background: '#f1f1f1'
              }
            }}
          />
        }
      >
        Ordenar
      </StyledButton>
      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option: any, index: any) => (
          <StyledMenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}

export default MenuOrder
