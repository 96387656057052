import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { CustomThemeProvider } from '../../theme/CustomThemeProvider'

interface jsonColor {
  primaryColor?: string,
  secondaryColor?: string,
  buttonColor?: string,
  textButtonColor?: string,
}

type PrimaryButtonProps = {
  title: any
  props?: ButtonProps
  fullWidth: boolean
  personalizationColor?: jsonColor
}
const PrimaryButtonComponent = ({
  title,
  props,
  fullWidth,
  personalizationColor
}: PrimaryButtonProps) => {
  const classes = useStyles({
    personalizationColor: personalizationColor
  })
  return (
    <Button
      variant='contained'
      fullWidth={fullWidth}
      classes={{
        root: classes.root,
        disabled: classes.disabledButton,
      }}
      {...props}
    >
      {title}
    </Button>
  )
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: (props:any) => ({
      borderRadius: '5px',
      opacity: 1,
      background: props.personalizationColor !== undefined && props.personalizationColor.buttonColor! !== "" ? props.personalizationColor.buttonColor : `${theme.palettePrimaryButton.color} 0% 0% no-repeat padding-box`,
      fontSize: '14px',
      padding: '6px 20px 2px 20px',
      fontWeight: 'bold',
      textTransform: 'none',
      textShadow: '0px 2px 0px #00000040',
      color: props.personalizationColor !== undefined && props.personalizationColor.textButtonColor! !== "" ? props.personalizationColor.textButtonColor : theme.palettePrimaryButton.textColor,
      '&:hover': {
        background: props.personalizationColor !== undefined && props.personalizationColor.buttonColor! !== "" ? `${props.personalizationColor.buttonColor} !important` : `${theme.palettePrimaryButton.colorHover} 0% 0% no-repeat padding-box`,
        textShadow: '0px 2px 0px #00000040'
      }
    }),
    disabledButton: (props:any) => ({
      background: props.personalizationColor !== undefined && props.personalizationColor.buttonColor! !== "" ? `${props.personalizationColor.buttonColor} !important` : `${theme.palette.primary.main} 0% 0% no-repeat padding-box !important`,
      boxShadow: '0px 4px 3px #0000004D',
      borderRadius: '5px',
      opacity: 0.5,
      textTransform: "none",
      padding: "6px 20px 2px 20px",
      fontWeight: "bold",
      color: props.personalizationColor!== undefined && props.personalizationColor.textButtonColor! !== "" ? `${props.personalizationColor.textButtonColor} !important` : `${theme.palette.common.white} !important`
    }),
  }
})

export const PrimaryButton = (props: PrimaryButtonProps) => {
  return (
    <CustomThemeProvider>
      <PrimaryButtonComponent {...props} />
    </CustomThemeProvider>
  )
}
