import React from "react";

export const DashboardIcon = ({ width = "100%", color = '#009bdd', id = "" }) => {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 20 20"
    >
      <path
        id="Trazado_98"
        data-name="Trazado 98"
        d="M0-6.889H8.889V-18H0V-6.889ZM0,2H8.889V-4.667H0V2ZM11.111,2H20V-9.111H11.111V2Zm0-20v6.667H20V-18Z"
        transform="translate(0 18)"
        fill={color}
      />
    </svg>
  );
}