import axios, { AxiosRequestConfig } from 'axios'
import { Dispatch } from 'react'
import { config as endpoints } from '../config/config'
import {
  enterpriseError,
  enterprisesResponse
} from '../store/enterprise.actionCreators'

/**
 * endpoint para consulta de empresas por usuario
 * @param userName
 * @param appName
 */
export const getEnterprise = async (
  userId: number,
  app: string,
  token: string,
  dispatch: Dispatch<any>
) => {
  try {
    const config: AxiosRequestConfig = {
      url: '/api/customer/GetCustomerByUser',
      method: 'GET',
      baseURL: endpoints.API_SECURITY_ENDPOINT,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization:
          token === undefined
            ? ''
            : token.includes('Bearer ')
            ? token
            : `Bearer ${token}`,
        userId,
        app
      }
    }
    const { data, status } = await axios(config)
    if (status === 200) {
      dispatch(enterprisesResponse(data.result.customers))
    } else {
      dispatch(
        enterpriseError({
          message: data.result.information,
          statusCode: status
        })
      )
    }
  } catch (error) {
    dispatch(
      enterpriseError({
        message: error.response.data.result.information,
        statusCode: error.response.data.statusCode
      })
    )
  }
}

/**
 * endpoint para consulta de empresas por usuario
 * @param userName
 * @param appName
 */
export const getAllEnterpriseByApplication = async (
  serviceName: string,
  isValidLIcensed: boolean,
  token: string,
  dispatch: Dispatch<any>
) => {
  try {
    const config: AxiosRequestConfig = {
      url: 'License/GetLicensedCustomer',
      method: 'POST',
      baseURL: endpoints.API_LICENSE,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization:
          token === undefined
            ? ''
            : token.includes('Bearer ')
            ? token
            : `Bearer ${token}`
      },
      data: {
        ServiceName: serviceName,
        IsValidLicensed: isValidLIcensed
      }
    }
    const { data, status } = await axios(config)
    if (status === 200) {
      const listCustomers = data.result.records

      let dataResponse = listCustomers.filter(
        (item: any) => item.documentNumber !== '899999040'
      )

      dataResponse = dataResponse.map((item: any, index: number) => {
        return {
          id: index + 1,
          documentType: item.documentType,
          documentNumber: item.documentNumber,
          isAssigned: true,
          name: item.customerName,
          state: 0,
          isLicensed: item.isValidLicense
        }
      })

      dispatch(enterprisesResponse(dataResponse))
    } else {
      dispatch(
        enterpriseError({
          message: data.result.information,
          statusCode: status
        })
      )
    }
  } catch (error) {
    dispatch(
      enterpriseError({
        message: error.response.data.result.information,
        statusCode: error.response.data.statusCode
      })
    )
  }
}
