import { createTheme } from '@material-ui/core/styles';

const DarkTheme = createTheme({
  palette: {
    primary: {
      light: '#009BDD',
      main: '#16B1F3',
      dark: '#003582',
    },
    secondary: {
      dark: '#FFFFFF',
      main: '#FA5A00',
      light: '#FA5A0026',
    },
    info: {
      light: '#D5D5D5',
      main: '#FFFFFF',
      dark: '#C1C1C1',
    },
    error: {
      light: '#FA5A00',
      main: '#D80000',
      dark: '#D80000',
    },
    background: {
      paper: '#4C4C4C',
      default: '#FFFFFF'
    },
    action: {
      hover: 'rgba(255,255,255,0.12)',
      selected: 'rgba(255,255,255,0.12)'
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
      hint: '#FFFFFF'
    },
  },
  paletteUserMenu: {
    Icon: {
      primary: {
        light: "#009BDD",
        main: "#16B1F3",
        dark: "#1D71B8",
      }
    }
  },
  paletteDataTable: {
    border: {
      borderRadius: '10px'
    },
    table: {
      columnsBackgroundColor: "#009BDD",
      columnsLetterColor: "#FFFFFF",
      columnsArrowColor: "#FFFFFF 0% 0% no-repeat padding-box;",
      trashIconColor: "#009BDD"
    }
  },
  paletteViewLayout:{
    title: {
      color: "#003582"
    },
    border:{
      border: '1px solid #009BDD',
      borderRadius: '10px;'
    }
  },
  palleteFormLayout: {
    iconEspecialButton:{
      initial:{
        backgrounColor: 'white',
        border: '1px solid #A8A8A8',
        color: '#6D6E71'
      },
      hover: {
        backgroundColor: '#FA5A00',
        border: 'none',
        boxshadow: 'none',
        color: 'white'
      }
    }
  },
  palettePrimaryButton: {
    color: "#16B1F3",
    colorHover: "#1565C0",
    textColor: 'white'
  },
  typography: {
    allVariants: {
      fontFamily: 'muli',
      color: '#FFFFFF'
    },
    h6: {
      fontWeight: 'normal',
      fontFamily: 'muli',
      color: '#FFFFFF',
      fontSize: '14px'
    },
    subtitle1: {
      textAlign: 'left',
      font: 'normal normal 600 16px/20px muli',
      letterSpacing: '0px',
      fontWeight: 600,
      color: '#FFFFFF',
      opacity: 1,
    },
  }
})

export default DarkTheme;
