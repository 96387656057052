import {
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import React from 'react'
import { ArrowIcon } from '../../assets/Icons/ArrowIcon'
import { CustomThemeProvider } from '../../theme/CustomThemeProvider'
import { DRAWER_WIDTH } from '../../utils/const'
import { CircularTab } from '../Tabs/CircularTab'
import PageBackground from './PageBackground'

type ViewLayoutProps = {
  headerTitle: string
  children: JSX.Element
  handleIconClick?: Function | undefined
  indicators?: {
    tabs: number
    currentTab: number
  }
  personalizationColor?: jsonColor
}

const ViewLayoutComponent = ({
  headerTitle,
  children,
  indicators,
  handleIconClick,
  personalizationColor
}: ViewLayoutProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const drawerWidth = !isMobile ? DRAWER_WIDTH : 0
  const padding = !isMobile ? '0px 40px 40px 40px' : 0
  const classes = useStyles({
    drawerWidth: drawerWidth,
    padding: padding,
    isMobile,
    personalizationColor: personalizationColor
  })


  return (
    <div className={classes.contentPage}>
      <PageBackground />
      <div className={classes.externalCard}>
        <Grid container>
          <Grid container item lg={4} sm={8} md={8} xs={8} alignItems='center'>
            {handleIconClick ? (
              <Grid item lg={1} sm={1} md={1} xs={1}>
                <Tooltip
                  id='button-report'
                  title='Atrás'
                  style={{ float: 'left', margin: '26px 2px 17px 17px' }}
                >
                  <IconButton
                    area-label='Report'
                    onClick={() => handleIconClick()}
                  >
                    <ArrowIcon className={classes.previousArrow} />
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : null}
            <Grid item lg={11} sm={11} md={11} xs={11}>
              <div className={classes.title}>{headerTitle}</div>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent='center'>
          <Grid container item lg={12} md={12} sm={12} justifyContent='center'>
            <div className={classes.contentCard}>{children}</div>
          </Grid>

          {indicators && !isMobile ? (
            <div className={classes.indicators}>
              <CircularTab
                tabs={indicators ? indicators.tabs : 0}
                currentTab={indicators ? indicators.currentTab : 0}
              />
            </div>
          ) : null}
        </Grid>
      </div>
    </div>
  )
}

const ViewLayout = (props: ViewLayoutProps) => {
  return (
    <CustomThemeProvider>
      <ViewLayoutComponent {...props} />
    </CustomThemeProvider>
  )
}

export default ViewLayout

const useStyles = makeStyles((theme: Theme) => {
  return {
    title: (props: any) => ({
      fontSize: props.isMobile ? '22px' : '28px',
      color: props.personalizationColor.primaryColor !== "" ? props.personalizationColor.primaryColor : theme.paletteViewLayout.title.color,
      padding: '10px 0px 10px 25px',
      marginTop: 10,
      fontWeight: 'bold'
    }),
    externalCard: (props: any) => ({
      marginTop: props.isMobile ? 50 : 100,
      marginLeft: props.drawerWidth,
      padding: '0px 40px 50px',
      borderRadius: props.isMobile ? 0 : '10px',
      justifyContent: 'center',
      opacity: 1,
      width: props.isMobile ? '100%' : `calc(90% - ${props.drawerWidth}px)`,
      height: props.isMobile ? '93vh' : '',
      minWidth: '40% !important',
      boxShadow: '0px 5px 5px #00000026',
      background: theme.palette.background.default,
    }),
    contentCard: (props: any) => ({
      margin: '10px 25px',
      borderRadius: theme.paletteViewLayout.border.borderRadius,
      width: '100%',
      minWidth: '93% !important',
      border: props.personalizationColor.primaryColor !== "" ? `1px solid ${props.personalizationColor.primaryColor}` : theme.paletteViewLayout.border.border,
    }),
    indicators: (props: any) => ({
      width: '100%',
      borderRadius: '10px',
      //backgroundColor: "aquamarine",
      height: '10px',
      position: 'absolute',
      justifyContent: 'center',
      bottom: 20,
      left: props.drawerWidth / 2,
      marginTop: '100px'
    }),
    contentPage: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      alignItems: 'center',
      flexDirection: 'column'
    },
    previousArrow: (props: any) => ({
      transform: 'rotate(180deg)',
      stroke:  props.personalizationColor.secondaryColor! !== "" ? props.personalizationColor.secondaryColor : theme.palette.secondary.main,
    })
  }
})
