import { TextField, } from '@material-ui/core';
import React from 'react'
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
  .MuiInputBase-root-426 {
    border-radius: 10px !important;
    height: 40px !important;
    box-shadow: 0px 3px 2px #00000026 !important;
  }
`;
const InputSearch = (props: any) => {
  return (
    <StyledTextField
      style={{ width: '100%', padding: '10px 23px 10px 10px' }}
      {...props}
    />
  )
}

export default InputSearch
