import React from 'react'

export const ReportIcon = ({
    width = '11.998px',
    height = '18.013px',
    id
}: any) => {
    if(width === null)
        width = '11.998px';
        height = '18.013px';
        return (
            <svg
                id={id}
                width={width}
                height={height}
                viewBox="0 0 11.998 18.013"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                
                <g id="ico_informes" data-name="ico informes" transform="translate(-21.836 -7.285)">
                    <path id="Sustracción_22" data-name="Sustracción 22" d="M22818.828-4916.713h-10a1,1,0,0,1-1-1v-13a1,1,0,0,1,1-1h1l0,1.4a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2v-1.392l1-.006a1,1,0,0,1,1,1v13A1,1,0,0,1,22818.828-4916.713Zm-8.5-3.018a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5Zm0-3a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5Zm0-3a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5Z" transform="translate(-22785.996 4942.012)" fill="#6d6e71"/>
                    <path id="Rectángulo_1222" data-name="Rectángulo 1222" d="M0,0H6A0,0,0,0,1,6,0V1.15a1.4,1.4,0,0,1-1.4,1.4H1.4A1.4,1.4,0,0,1,0,1.15V0A0,0,0,0,1,0,0Z" transform="translate(24.831 10.285)" fill="#6d6e71"/>
                    <path id="Sustracción_14" data-name="Sustracción 14" d="M2,3.8a2,2,0,1,1,2-2A2,2,0,0,1,2,3.8Zm0-3a1,1,0,1,0,1,1A1,1,0,0,0,2,.8Z" transform="translate(25.831 7.484)" fill="#6d6e71"/>
                </g>
               
            </svg>
        )
}