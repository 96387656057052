import axios, { AxiosResponse } from 'axios'
import { config as endpoints } from '../../../config/config'
import { StandardResponse } from '../../../utils/types/types'

import {
  CreateImage,
  CreateParametersConfig,
  ICreatePersonalizationConfig,
  PersonalizationResponseDto,
  DeleteImage,
  IBasicData,
  ImageManagerResponse,
  SettingsResponseDto
} from '../types'

const REPOSITORIES_URL = '/Repository/repositories'
//const PARAMETRIZATION_UPDATE_URL = '/Parametrization/UpdateSetting'
const PARAMETRIZATION_NEW_URL = '/Parametrization/CreateSettings'
const PARAMETRIZATION_GET_URL = '/Parametrization'
const PERSONALIZATION_URL = '/Company/CreatePersonalization'
const PERSONALIZATION_UPDATE_URL = '/Company/UpdatePersonalization'
const PERSONALIZATION_GET_URL = '/Company/getPersonalization'
const IMAGE_MANAGER = '/documentmanager/api/DocumentManager'

/* const securityInstance = axios.create({
  baseURL: endpoints.API_SECURITY_ENDPOINT + '/api',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
  //  timeout: 15000
}) */

const biometricsInstance = axios.create({
  baseURL: endpoints.API_BIOMETRICS_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
  //  timeout: 15000
})

const imageManagerInstance = axios.create({
  baseURL: endpoints.IMAGE_MANAGER,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
  //  timeout: 15000
})

/**
 *  get information about possible repositories to save information
 * @param {string} token
 * @returns {Promise} response
 */

export const getRepositories = async (token: string): Promise<any> => {
  try {
    const response: StandardResponse<IBasicData[]> =
      await biometricsInstance.get(REPOSITORIES_URL, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    return response
  } catch (error) {
    return error
  }
}

/**
 *
 * @param body
 * @param token
 * @returns
 */
export const createParametrization = async (
  body: CreateParametersConfig,
  token: string
) => {
  try {
    const response: StandardResponse<string> = await biometricsInstance.post(
      PARAMETRIZATION_NEW_URL,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response
  } catch (error) {
    return error
  }
}

/**
 *
 * @param body
 * @param token
 * @returns
 */
export const createPersonalization = async (
  body: ICreatePersonalizationConfig,
  token: string
) => {
  try {
    const response: StandardResponse<string> = await biometricsInstance.post(
      PERSONALIZATION_URL,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response
  } catch (error) {
    return error
  }
}

export const saveOnImageManager = async (body: CreateImage): Promise<any> => {
  try {
    const response = await imageManagerInstance.post(IMAGE_MANAGER, body)
    return response
  } catch (error) {
    return error
  }
}
export const deleteOnImageManager = async (
  body: DeleteImage[]
): Promise<any> => {
  try {
    const response = await imageManagerInstance.post(
      IMAGE_MANAGER + '/eliminar',
      body
    )
    return response
  } catch (error) {
    return error
  }
}

export const getNameImage = async (
  location: string,
  name: string
): Promise<ImageManagerResponse> => {
  try {
    const response: ImageManagerResponse = await imageManagerInstance.get(
      IMAGE_MANAGER,
      {
        headers: {
          Location: location,
          Name: name,
          Repository: 2
        }
      }
    )
    return response
  } catch (error) {
    return error
  }
}

export const getPersonalization = async (
  token: String
): Promise<AxiosResponse<StandardResponse<PersonalizationResponseDto>>> => {
  try {
    const response = await biometricsInstance.get(PERSONALIZATION_GET_URL, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return response
  } catch (error) {
    return error
  }
}

export const getParametrization = async (
  token: String,
  companyId: string
): Promise<AxiosResponse<StandardResponse<SettingsResponseDto>>> => {
  try {
    const response = await biometricsInstance.get(PARAMETRIZATION_GET_URL, {
      headers: { Authorization: `Bearer ${token}`, companyId: companyId }
    })

    return response
  } catch (error) {
    return error
  }
}

/**
 *
 * @param body
 * @param token
 * @returns
 */
export const updateParametrization = async (
  body: CreateParametersConfig,
  token: string
) => {
  try {
    const response: StandardResponse<string> = await biometricsInstance.put(
      PARAMETRIZATION_GET_URL,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response
  } catch (error) {
    return error
  }
}

/**
 *
 * @param body
 * @param token
 * @returns
 */
export const updatePersonalization = async (
  body: ICreatePersonalizationConfig,
  token: string
) => {
  try {
    const response: StandardResponse<any> = await biometricsInstance.post(
      PERSONALIZATION_UPDATE_URL,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response
  } catch (error) {
    return error
  }
}
