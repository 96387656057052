import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import "../../ProgressBar/ProgressBar.css";
import { getImageFromList } from '../../../assets/Icons';
// import { StateLoad } from '../../store/types';

type ItemsListProps = {
    // list?: Array<StateLoad|undefined>
    list?: Array<any|undefined>
}

export const ItemsList = (props: ItemsListProps) => {    
    const classes = useStyles();

    const type = (name:string|undefined) => {
        if(name == "exitoso") return <img src={getImageFromList('successIcon')} alt="hands" className={'imageInfo'} />;
        else if(name == "erroneo") return <img src={getImageFromList('errorIcon')} alt="hands" className={'imageInfo'} />;
        else return <img src={getImageFromList('refreshIcon')} alt="hands" className={'imageInfo'} />;
    }
    
    return (
        <Grid container spacing={2} direction='column'>
            {props.list?.map((item, index) => (
                <Grid key={index} item>
                    <Typography className={classes.list}>
                        <Grid container direction="row" spacing={1}>
                            <Grid style={{paddingTop:"12px"}} item>
                                {type(item?.state)}
                            </Grid>
                            <Grid style={{paddingTop:"13px"}} item>
                                {item?.name}
                            </Grid>
                        </Grid>
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
}

const useStyles = makeStyles((theme: any) => ({
    list: {
        border: "1px solid #D5D5D5",
        height: "40px",        
        color: "#575756",        
        paddingLeft: "18px",
        fontSize: theme.typography.pxToRem(16),
        borderRadius: "6px"
    }
}));