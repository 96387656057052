import axios, { AxiosRequestConfig } from 'axios'
import { config as endpoints } from '../config/config'
import {
  generateTokensErrors,
  generateTokensResponse
} from '../store/auth.actionCreators'

export const generateTokens = async (data: any, dispatch: any) => {
  try {
    const config: AxiosRequestConfig = {
      url: '/api/login/GenerateTokens',
      baseURL: endpoints.API_AUTHENTICATION,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: data === undefined ? '' : data.token
      }
    }
    const { status, data: responseData } = await axios(config)
    if (status === 200) {
      dispatch(
        generateTokensResponse({
          message: responseData.result,
          statusCode: status
        })
      )
    }
    return { message: responseData.result, statusCode: status }
  } catch (error) {
    const { information } = error.response.data.result
    const { statusCode } = error.response.data
    dispatch(generateTokensErrors({ message: information, statusCode }))
    return { message: information, statusCode }
  }
}

interface UserData {
  user: string
  password: string
  appname: string
  page: string
}

/**
 *
 * @param userData
 */
export const loginUser = async (userData: UserData) => {
  const response = await axios.post(
    `${endpoints.API_AUTHENTICATION}/api/Login`,
    userData
  )
  return response
}

export /**
 * Validar sesion -> sesiones concurrentes
 * **/
const validateSessionStatus = async (token: string) => {
  try {
    const config: AxiosRequestConfig = {
      url: '/api/user/validateSession',
      baseURL: endpoints.API_SECURITY_ENDPOINT,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization:
          token === undefined
            ? ''
            : token.includes('Bearer ')
            ? token
            : `Bearer ${token}`
      },
      data: {}
    }
    const { status, data: responseData } = await axios(config)
    return { message: responseData.result, statusCode: status }
  } catch (error) {
    return { statusCode: error.response.status, message: 'Error de sesion' }
  }
}
