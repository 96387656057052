import axios, { AxiosRequestConfig } from 'axios';
import { config as endpoints } from '../config/config'

export const getMenuItems = async (data: any) => {
  try {
    const config: AxiosRequestConfig = {
      url: '/api/menu/GetMenuByUser',
      baseURL: endpoints.API_SECURITY_ENDPOINT,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: data === undefined ? '' : data.token,
        ...data,
      },
    };
    const { status, data: responseData } = await axios(config);
    return { message: responseData.result, statusCode: status };
  } catch (error) {
    const { information } = error.response.data.result;
    const { statusCode } = error.response.data;
    return { message: information, statusCode };
  }
};
