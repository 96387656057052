import { CustomError,  TokenState } from '../models/ActionsModels';
import {Enterprise } from '../models/EnterpriseModels';
import { GENERATE_TOKENS, GET_ENTERPRISES, GET_ERRORS } from '../actions/types';

export interface MenuState {
  enterprises: Enterprise[];
  tokenErrors: CustomError;
  tokens: TokenState;
}

export const initialState = {
  enterprises: [],
  tokenErrors: {
    message: '',
    statusCode: 0,
  },
  tokens: {
    message: {
      token: '',
      informationCode: '',
      typeOperation: '',
      refreshToken: '',
      urlApp: '',
    },
    statusCode: 0,
  },
};

/**
 *  accion de consulta de empresas
 * @param state
 * @param action
 */
export const menuReducer = (
  state: MenuState = initialState,
  action: any,
): MenuState => {
  switch (action.type) {
    case GET_ENTERPRISES:
      return {
        ...state,
        enterprises: action.payload,
      };
    case GENERATE_TOKENS:
      return {
        ...state,
        tokens: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        tokenErrors: action.payload,
      };
    default:
      return state;
  }
};
