import {
  CustomError,
  CustomErrorAction,
  TokenResponseAction,
  TokenState,
} from '../models/ActionsModels';
import { GENERATE_TOKENS, GET_ERRORS } from '../actions/types';

export const generateTokensResponse = (
  message: TokenState,
): TokenResponseAction => ({
  type: GENERATE_TOKENS,
  payload: message,
});

export const clearTokenResponse = (): TokenResponseAction => ({
  type: GENERATE_TOKENS,
  payload: {
    message: {
      token: '',
      informationCode: '',
      typeOperation: '',
      refreshToken: '',
      urlApp: '',
    },
    statusCode: 0,
  },
});

export const generateTokensErrors = (
  error: CustomError,
): CustomErrorAction => ({
  type: GET_ERRORS,
  payload: error,
});
