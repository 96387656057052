import { AxiosRequestConfig } from 'axios';
import { config as endpoints } from '../config/config'
import axios from 'axios';


export /**
 * User Action getUserById
 *
 * @method GET
 * @param {*} userId
 */
  const getUserByName = async (data: any) => {
    try {
      const config: AxiosRequestConfig = {
        url: '/api/user/UserByUserName',
        baseURL: endpoints.API_SECURITY_ENDPOINT,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: data === undefined ? '' : data.token,
          Username: data.Username,
        },
      };
      const { status, data: responseData } = await axios(config);
      return { message: responseData.result, statusCode: status };
    } catch (error) {
      const { information } = error.response.data.result;
      const { statusCode } = error.response.data;
      return { message: information, statusCode };
    }
  };

export /**
 * User Action getUserById
 *
 * @method GET
 * @param {*} userId
 */
  const getUsersByAppAndCustomer = async (data: any) => {
    try {
      const config: AxiosRequestConfig = {
        url: '/api/user/ListUsersAppCustomer',
        baseURL: endpoints.API_SECURITY_ENDPOINT,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: data === undefined ? '' : data.token,
          appName: data.appName,
          Last: data.lastInfo,
          Search: data.search,
          filterBy: data.filterBy,
        },
      };
      const { status, data: responseData } = await axios(config);
      let response: any;

      if (data.lastInfo === 0) {
        if (localStorage.getItem('getUserByAppAndCustomer') !== null || localStorage.getItem('getUserByAppAndCustomer') !== undefined) {
          localStorage.removeItem('getUserByAppAndCustomer');
        }
      }
      
      if (localStorage.getItem('getUserByAppAndCustomer') === null) {
        localStorage.setItem('getUserByAppAndCustomer', JSON.stringify(responseData.result));
        response = responseData.result;
      } else {
        if (localStorage.getItem('getUserByAppAndCustomer') !== null || localStorage.getItem('getUserByAppAndCustomer') !== undefined) {
          const getUsers = JSON.parse(localStorage.getItem('getUserByAppAndCustomer') || "[]");
          Array.prototype.push.apply(getUsers, responseData.result);
          response = getUsers;
          localStorage.setItem('getUserByAppAndCustomer', JSON.stringify(getUsers));
        }
      }
      return { message: response, statusCode: status, rows: responseData.rows, isSearch: data.search.length > 0 ? true : false };
    } catch (error) {
      const { information } = error.response.data.result;
      const { statusCode } = error.response.data;
      return { message: information, statusCode };
    }
  };

export /**
 * User Action inactivate ( inactivate  )
 *
 * @method POST
 * @param {*} userData
 */
  const inactivate = async (data: any) => {
    try {
      const config: AxiosRequestConfig = {
        url: '/api/user/Inactivate',
        baseURL: endpoints.API_SECURITY_ENDPOINT,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: data === undefined ? '' : data.token,
        },
        data: { id: data.id, idstate: data.idstate }
      };
      const { status, data: responseData } = await axios(config);
      return { message: responseData.result, statusCode: status };
    } catch (error) {
      const { information } = error.response.data.result;
      const { statusCode } = error.response.data;
      return { message: information, statusCode };
    }
  };

export /**
 * User Action createUser ( create/modify User  )
 *
 * @method POST
 * @param {history} history
 */
  const deleteUser = async (data: any) => {

    try {
      const config: AxiosRequestConfig = {
        url: '/api/user/Deletefromcomponent',
        baseURL: endpoints.API_SECURITY_ENDPOINT,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: data === undefined ? '' : data.token,
        },
        data: { id: data.id }
      };
      const { status, data: responseData } = await axios(config);
      return { message: responseData.result.information, statusCode: status };
    } catch (error) {
      const { information } = error.response.data.result;
      const { statusCode } = error.response.data;
      return { message: information, statusCode };
    }
  };

export /**
 * User Action GetUserExists
 *
 * @method GET
 * @param {history} history
 */
  const getUserExists = async (data: any) => {
    try {
      const config: AxiosRequestConfig = {
        url: '/api/user/getuserexists',
        baseURL: endpoints.API_SECURITY_ENDPOINT,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: data === undefined ? '' : data.token,
        },
        data
      };
      const { status, data: responseData } = await axios(config);
      return { message: responseData.result, statusCode: status };
    } catch (error) {
      const { information } = error.response.data.result;
      const { statusCode } = error.response.data;
      return { message: information, statusCode };
    }
  };

export /**
 * User Action ActivateUser
 *
 * @method POST
 * @param {history} history
 */
  const ActivateUser = async (data: any) => {
    try {
      const config: AxiosRequestConfig = {
        url: '/api/user/activateDeletedUserfromcomponent',
        baseURL: endpoints.API_SECURITY_ENDPOINT,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: data === undefined ? '' : data.token,
        },
        data: { id: data.id }
      };
      const { status, data: responseData } = await axios(config);
      return { message: responseData.result, statusCode: status };
    } catch (error) {
      const data = error.response.data.result;
      const { statusCode } = error.response.data;
      return { message: data, statusCode };
    }
  };

export /**
 * User Action ActivateUser
 *
 * @method POST
 * @param {history} history
 */
  const CreateAndModifyUser = async (data: CreateAndModifyUser) => {
    try {
      const config: AxiosRequestConfig = {
        url: data.action === 'create' ? `/api/user/verifyCreate` : `/api/user/${data.action}`,
        baseURL: endpoints.API_SECURITY_ENDPOINT,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: data === undefined ? '' : data.token,
        },
        data: {
          ...data.data,
          customer: data.customer,
          roles: data.roles
        }
      };
      const { status, data: responseData } = await axios(config);
      return { message: responseData.result, statusCode: status };
    } catch (error) {
      const { information } = error.response.data.result;
      const { statusCode } = error.response.data;
      return { message: information, statusCode };
    }
  };

export 
/**
 * User action load massive
 */
  const saveLoadMassiveUsers = async (data: loadMassiveUsersData) => {
    try {
      const config: AxiosRequestConfig = {
        url: '/api/user/saveLoadMassiveUsers',
        baseURL: endpoints.API_SECURITY_ENDPOINT,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: data === undefined ? '' : data.token,
        },
        data: {
          ...data,          
        }
      };
      const { status, data: responseData } = await axios(config);
      return { message: responseData.result, statusCode: status };
    } catch (error) {
      console.log('error', error);
      return {statusCode: error.response.status, message: "Error al guardar los datos"};
    }
  };

  export 
  /**
   * validate load massive user action
   */
  const validateLoadMassiveStatus = async (data: any | undefined, token: string) => {
    if (data !== undefined) {
      try {
        const config: AxiosRequestConfig = {
          url: '/api/user/CreateDeviceMasive',
          baseURL: endpoints.API_SECURITY_ENDPOINT,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: token === undefined ? "" : token.includes("Bearer ") ? token : `Bearer ${token}`,
          },
          data: data
        };
        const { status, data: responseData } = await axios(config);
        return { message: responseData.result, statusCode: status };
      } catch (error) {
        console.log('error', error);
        return {statusCode: error.response.status, message: {exitosos: 0, reemplazados: 0, errores: 0}};
      }
    } else {
      return undefined;
    }
  };

export
  /**
   * User Action GetMassiveUserTemplate obtiene el template para la carga masiva de usuarios
   * **/
  const getMassiveUserTemplateAC = async (token:string) => {
    try {
      const config: AxiosRequestConfig = {
        url: '/api/user/GetMassiveUserTemplate',
        baseURL: endpoints.API_SECURITY_ENDPOINT,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: token === undefined ? "" : token.includes("Bearer ") ? token : `Bearer ${token}`,
        },
        data: {token}
      };
      const { status, data: responseData } = await axios(config);
      if (responseData.statusCode === 200) {
        const mimeType =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const linkSource = `${mimeType}${responseData.result.downloadRoute}`;
        const downloadLink = document.createElement("a");
        const fileName =
          responseData.result.nameFile + "." + responseData.result.extensionFile;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
      return { message: responseData.result, statusCode: status };
    } catch (error) {
      return {statusCode: error.response.status, message: "Error al descargar la plantilla"};
    }
  };