export const GET_ENTERPRISES = 'GET_ENTERPRISES';
export const GET_ERRORS = 'GET_ERRORS';
export const GENERATE_TOKENS = 'GENERATE_TOKENS';
export const GET_MENU = 'GET_MENU';








