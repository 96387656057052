import {
  List,
  ListItem,
  ListItemIcon,
  Theme,
  Tooltip,
  useTheme
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useRef } from 'react'
import { getMenuItems } from '../../actions/menu.action'
import { Menu } from '../../models/MenuModels'
import { TOP_MENU } from '../../const/constants'
import useAsync from '../../hooks/useAsync'
import { TopMenuChildren } from './TopMenuChildren'
import { isTokenExpired, runFunction } from '../../utils/utils'
import { useHistory } from 'react-router-dom';
import { getIconFromList } from '../../components/IconsLibrary';

type TopMenuProps = {
  appName: string
  token: string
  functions: Array<any>
}

export const TopMenu = ({ appName, token, functions }: TopMenuProps) => {
  const [menu, setMenu] = React.useState([])
  const [state, setState] = React.useState(true)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [menuChildren, setMenuChildren] = React.useState<Menu[] | undefined>([])
  const menuKey = React.useRef('')
  const theme = useTheme()
  const history = useHistory()
  const contextFunctions = useRef({})
  const classes = useStyles()
  const [, setSelectedPath] = React.useState('')
  const { error, execute, loading, result } = useAsync({
    asyncFunction: getMenuItems
  })

  const confirmOpenUrl = (url: string) => !url.includes(window.location.origin)

  useEffect(() => {
    if (!menu.length && !loading && appName && token) {
      const bearerToken = token.includes('Bearer') ? token : `Bearer ${token}`
      if (!isTokenExpired(token)) {
        execute({
          token: bearerToken,
          appName,
          idmenulocation: parseInt(TOP_MENU, 10)
        })
      }
    }
  }, [token])

  useEffect(() => {
    if (functions !== undefined && functions.length !== 0) {
      functions.map(item => {
        contextFunctions.current[item.name] = item.function;
      });
    }
  }, [])

  useEffect(() => {
    if (result) {
      if (result.statusCode === 200) {
        if (result.message.records.length) {
          const menuResult = result.message.records[0].menuJson
          setMenu(JSON.parse(menuResult))
        }
      }
    } else if (error) {
      console.log(error)
    }
  }, [result, error])

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: Menu
  ) => {
    setAnchorEl(event.currentTarget)
    if (menuKey.current !== '') {
      menuKey.current = ''
      //  setAnchorEl(null)
    } else {
      menuKey.current = item.key
      setMenuChildren(item.children)
    }
    setState(!state)
    event.stopPropagation()
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  /**
   *
   * @param {number} index
   * @param {boolean} open
   * @param {string} url
   */
  const handleListItemClick = (_index: string, open: boolean, url: string) => {
    if (open) {
      window.open(url, '_blank')
    } else {
      history.push(url.replace(window.location.origin, ''));
      setSelectedPath(url)
    }
  }

  /**
     *
     * @param {string} name
     * @param {string} url
     */
  const createPersonalizedIcon = (name: string, url: string, id: string, sizeIcon: string) => {
    const IconGet = getIconFromList(name)?.icon
    if (IconGet !== undefined) {
      return (
        <IconGet
          id={`${id}-svg`}
          color={
            window.location.href.includes(url)
              ? theme.palette.primary.light
              : theme.palette.text.primary
          }
          width={sizeIcon}
        />
      )
    } else {
      return <div></div>
    }
  }

  return (
    <div>
      <List className={classes.list} disablePadding={true}>
        {menu.map((item: Menu) => {
          return (
            <ListItem
              onClick={(evt: any) => {
                if (item.isFunction) {
                  runFunction(item.functionName, contextFunctions.current)
                } else {
                  item.children?.length
                    ? handleClick(evt, item)
                    : handleListItemClick(
                      item.key,
                      confirmOpenUrl(item.url),
                      item.url
                    )
                }
              }}
              key={item.key}
              id={item.key}
            >
              <Tooltip title={item.name}>
                <ListItemIcon>
                  {
                    item.isPersonalizedIcon ? (
                      createPersonalizedIcon(item.iconName, item.url, item.key, item.sizeIcon)
                    ) : (
                      <span
                        className={`material-icons ${classes.icon}`}
                        style={{
                          fontSize: '24px',
                          color: `${window.location.href.includes(item.url)
                            ? theme.palette.primary.light
                            : ''
                            }`
                        }}
                      >
                        {item.children?.length ? (
                          <div>{item.iconName}</div>
                        ) : (
                          item.iconName
                        )}
                      </span>
                    )
                  }
                </ListItemIcon>
              </Tooltip>
            </ListItem>
          )
        })}
      </List>
      <TopMenuChildren
        menuKey='Top_Menu_Children'
        anchorEl={anchorEl}
        open={menuOpen}
        children={menuChildren ? menuChildren : []}
        ref={menuKey}
        functions={functions}
        handleClose={handleClose}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    cursor: 'pointer',
    color: theme.palette.text.primary
  },
  iconNAme: {},
  list: { display: 'flex', flexDirection: 'row', padding: 0 }
}))
