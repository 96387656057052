/**
 * @author Kolarte
 * @param token
 */

// eslint-disable-next-line consistent-return
export const parseJwt = (token: string) => {
  try {
    if (token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join('')
      )
      return JSON.parse(jsonPayload)
    }
  } catch (err) {
    return err
  }
}

export const validateEquals = (obj1: any, obj2: any) => {
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;
  if (obj1Length === 0 && obj2Length === 0) {
    return true;
  } else {
    if (obj1Length === obj2Length) {
      const keys = Object.keys(obj1);
      if (keys.length !== 0) {
        for (let i = 0; i < keys.length; i++) {
          if (typeof obj1[keys[i]] === "object" || typeof obj2[keys[i]] === "object") {
            if (obj1[keys[i]] !== null && obj1[keys[i]].length !== undefined) {
              if (obj1[keys[i]].length !== obj2[keys[i]].length) {
                return false;
              } else {
                obj1[keys[i]].sort();
                obj2[keys[i]].sort();
                for (let j = 0; j < obj1[keys[i]].length; j++) {
                  if (typeof obj1[keys[i]][j] === "object" || typeof obj2[keys[i]][j] === "object") {
                    if (!validateEquals(obj1[keys[i]][j], obj2[keys[i]][j])) {
                      return false;
                    }
                  } else if (obj1[keys[i]][j] !== obj2[keys[i]][j]) {
                    return false;
                  }
                }
              }
            } else {
              if (obj1[keys[i]] !== null && obj2[keys[i]] !== null) {
                if (!validateEquals(obj1[keys[i]], obj2[keys[i]])) {
                  return false;
                }
              }
            }
          } else if (obj1[keys[i]] !== obj2[keys[i]]) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
};

export const toPascalCase = (item: string) => {
  if (item) {
    return item.replace(
      /(\D)(\D*)/g,
      (_g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
    )
  } else {
    return ''
  }
}

export const toPascalCaseMoreWorks = (item: string) => {
  let workPascal = "";
  item.split(" ").map(item => { workPascal += toPascalCase(item) + " " });
  return workPascal;
}

export const isEmpty = (value: any) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0)

/**
 *
 * @param word
 * @param index
 * @param key
 * @returns
 */
export const findFinalOfWord = (
  word: string,
  index: number,
  key: string | undefined
) => {
  const finalIndex = regexIndexOf(word, key, index)
  if (finalIndex <= index && word.length <= index) {
    return word
  }
  return word.slice(0, finalIndex)
}

const regexIndexOf = (
  string: string,
  regex: string | undefined,
  startPos: number
) => {
  const indexOf = string.substring(startPos || 0).search(regex || '')
  return indexOf >= 0 ? indexOf + (startPos || 0) : indexOf
}

export const makeId = (length: number) => {
  var result = []
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
  }
  return result.join('')
}

export const isTokenExpired = (token: string) => {
  const decryptedToken = parseJwt(token)
  const expiration = decryptedToken.exp
  const expirationDate = new Date(expiration * 1000)
  const now = new Date()
  if (expirationDate > now) {
    return false
  } else {
    return true
  }
}

export function runFunction(fnName: string, ctx: any /*, args */) {
  var args = Array.prototype.slice.call(arguments, 2);
  return (ctx[fnName] !== undefined) && ctx[fnName].apply(ctx, args);
}

export const valueOrDefault = (value: any, defaultValue: any) => {
  let returnData = value;
  if ( typeof value === undefined || value == null || value == "" ) {
      returnData = defaultValue;
  }
  return returnData;
}
