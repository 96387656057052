import axios, { AxiosResponse } from 'axios'
import { config as endpoints } from '../../../config/config'
import { BasicResponse, StandardResponse } from '../../../utils/types/types'
import { IBasicData, IBasicDataUser, ICompanyBasicData, ICompanyBasicDataLicence, IDocumentType } from '../types'

const securityInstance = axios.create({
  baseURL: endpoints.API_SECURITY_ENDPOINT + '/api',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
  //  timeout: 15000
})

const licenseInstance = axios.create(
  {
    baseURL: endpoints.API_LICENSE,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  }
);

const biometricsInstance = axios.create(
  {
    baseURL: endpoints.API_BIOMETRICS_ENDPOINT,
    headers:{
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  }
);

const BASIC_DATA_UPDATE_URL = '/Customer/CustomerUpdate'
const BASIC_DATA_URL = '/Customer/BasicData'
const GET_BASIC_DATA_URL = '/Customer/GetCustomer'//'/Customer/BasicCustomerData'
const DEPARTMENTS_URL = '/Department/departments'
const CITIES_URL = '/City/citiesByDepartment'
const DOCUMENT_TYPE_URL = '/parameter/getAllDocumentTypes'//'/DocumentType/documentTypes'
const PERSON_TYPE_URL = '/PersonType/personTypes'
const STATE_CITIES_URL = "parameter/getListStatesAndCities";
const UPDATE_BIOMETRIC_BASIC_DATA = "company/CompanyUpdate";

export const createBasicData = async (
  data: ICompanyBasicData,
  token: string
) => {
  try {
    const response = await securityInstance.post(BASIC_DATA_URL, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  } catch (error) {
    return error
  }
}

// common endpoints

/**
 *  get information about names of states in colombia
 * @param {string} token
 * @returns {Promise} response
 */

export const getDepartments = async (token: string): Promise<any> => {
  try {
    const response: StandardResponse<IBasicData[]> = await securityInstance.get(
      DEPARTMENTS_URL,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response
  } catch (error) {
    return error
  }
}

/**
 * get information about names of cities in a specific state
 * @param token
 * @returns {Promise} response | error
 */
export const getCities = async (
  token: string,
  departmentId: number
): Promise<any> => {
  try {
    const response: StandardResponse<IBasicData[]> = await securityInstance.get(
      CITIES_URL,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          departmentId
        }
      }
    )
    return response
  } catch (error) {
    return error
  }
}

/**
 * get information about current document types in colombia
 * @param token
 * @returns {Promise} response | error
 */
export const getDocumentType = async (token: string): Promise<any> => {
  try {
    const response: StandardResponse<BasicResponse<IDocumentType[]>> = await licenseInstance.get(
      DOCUMENT_TYPE_URL,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    return response
  } catch (error) {
    return error
  }
}

/**
 * get information about type of person
 * @param token
 * @returns {Promise} response | error
 */
export const getPersonType = async (token: string): Promise<any> => {
  try {
    const response: StandardResponse<IBasicData[]> = await securityInstance.get(
      PERSON_TYPE_URL,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    return response
  } catch (error) {
    return error
  }
}

export const getCompanyData = async (
  id: number,
  token: string
): Promise<AxiosResponse<StandardResponse<BasicResponse<ICompanyBasicDataLicence>>>> => {
  try {
    const data = {
      "customerId": id,
      "DocumentType" : null,
      "DocumentNumber": null
    };
    const response: AxiosResponse<StandardResponse<BasicResponse<ICompanyBasicDataLicence>>> =
      await licenseInstance.post<StandardResponse<BasicResponse<ICompanyBasicDataLicence>>>(
        GET_BASIC_DATA_URL,data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )      
    return response
  } catch (error) {
    return error
  }
}

/**
 * update params on basic data
 * @param data
 * @param token
 * @returns
 */
export const updateBasicData = async (
  basicData: any,
  token: string
) => {
  try {
    const {
      data
    } = basicData;
    const body: IBasicDataUser = {
      documentType: data.documentTypeCode,  
      documentNumber: data.documentNumber,
      dv: data.dv,
      legalName: data.legalName,
      firstName: data.firstName,
      lastName: data.lastName,
      cellphoneNumber: data.cellphoneNumber,
      email: data.email,
      cityId: data.city,
      fullAddress: data.fullAddress, 
      legalDocumentType: data.legalDocumentTypeId,
      legalDocumentNumber: data.legalDocumentNumber,
      legalFirstName: data.legalFirstName,
      legalLastName: data.legalLastName,
      personTypeId: (data.personTypeId==1?'J':'N'),
      addressAdditionalInformation: data.addressAdditionalInformation
    };
    const response = await licenseInstance.post(BASIC_DATA_UPDATE_URL, body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  } catch (error) {
    return error
  }
}

/**
 *  get information about names of states and cities in colombia
 * @returns {Promise} response
 */
export const getListStatesAndCitiesService = async (): Promise<any> => {
  try {
    const response: AxiosResponse<StandardResponse<any>> = await licenseInstance.get(`${STATE_CITIES_URL}`, {});       
      return response;       
  } catch (error) {
    throw error;    
  }
};

export const updateBiometricBasicData = async (body: any, token: string): Promise<any> => {
  try {
    const response: AxiosResponse<StandardResponse<any>> = await biometricsInstance.post(`${UPDATE_BIOMETRIC_BASIC_DATA}`, body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response;
  } catch (error) {
    throw new Error(error);
    
  }
}
