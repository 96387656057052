import { Box, Grid, makeStyles, Tab, Tabs } from '@material-ui/core'
import React from 'react'
import { makeId } from '../../utils/utils'
import { TabComponentsProps, TabPanelProps } from '../../utils/types/types'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const TabComponent = ({
  content,
  tab,
  handleChangeTab,
  aria,
  personalizationColor
}: TabComponentsProps) => {
  const classes = useStyles({personalizationColor: personalizationColor})
  return (
    <div>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        aria-label={aria}
        classes={{
          indicator: classes.indicator,
          root: classes.root 
        }}
      >
        {content.map((item, index) => {
          const key = makeId(9)
          return (
            <Tab
              key={key}
              className={classes.tabsItem}
              classes={{
                selected: classes.selected
              }}
              label={item.label}
              {...a11yProps(index)}
            />
          )
        })}
      </Tabs>
      {content.map((item, index) => (
        <TabPanel value={tab} key={makeId(3)} index={index}>
          <Grid container item lg={12} justify='flex-start'>
            {item.tabContent}
          </Grid>
        </TabPanel>
      ))}
    </div>
  )
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  )
}

export default TabComponent

const useStyles = makeStyles((theme) => ({
  root: (props:any)=> ({
    textTransform: 'none',
    minWidth: 72,
    // fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: theme.palette.common.black,
    borderBottom: `1.4px solid ${theme.palette.info.light}`,
    '&$selected': {
      color: props.personalizationColor.secondaryColor! !=="" ? props.personalizationColor.secondaryColor! : theme.palette.secondary.main,
    }
  }),
  selected: (props:any)=>({
    color: props.personalizationColor.secondaryColor! !=="" ? props.personalizationColor.secondaryColor! : theme.palette.secondary.main
  }),
  tabsStyle: {
    borderBottom: `1px ${theme.palette.secondary.dark}`
  },
  indicator: (props:any)=>({
    backgroundColor: props.personalizationColor.secondaryColor! !=="" ? props.personalizationColor.secondaryColor! : theme.palette.secondary.main
  }),
  tabsItem:(props:any)=> ({
    fontSize: '1rem',
    textTransform: 'capitalize',
    marginTop: '0px',
    '&:hover': {
      color: props.personalizationColor.secondaryColor! !=="" ? props.personalizationColor.secondaryColor! : theme.palette.secondary.main,
      opacity: 1
    },
    '&:focus': {
      color: props.personalizationColor.secondaryColor! !=="" ? props.personalizationColor.secondaryColor! : theme.palette.secondary.main
    }
  })
}))
