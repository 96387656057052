
import dark from './DarkTheme'
import  light from './LightTheme'
import  orange from './OrangeTheme'
type ThemeProps =  {
  name: string
}

const themes = {
  dark,
  light,
  orange
}


export default function getTheme ({name}:ThemeProps){
  return themes[name]

}
