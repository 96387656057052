import {
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp, Launch } from '@material-ui/icons'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { findFinalOfWord } from '../../utils/utils'
import { Column, Config, DataTableProps } from './types'
import { makeStyles, withStyles } from '@material-ui/styles'

import IconEspecialButton from '../Button/IconSpecialButton'
import MenuOrder from '../Menu/MenuOrder'
import Search from '@material-ui/icons/Search'
import { TrashIcon } from '../IconsLibrary/TrashIcon'
// import SwitchForm from '../Inputs/SwitchForm'
import Switch from '@material-ui/core/Switch';
import InputSearch from '../Inputs/InputSearch'

export const DataTable: React.FC<DataTableProps> = ({
  columns,
  data,
  paramToDisable,
  havePagination,
  globalDisabled,
  validateToDisable,
  buttons,
  option,
  setOption,
  handleCellClick,
  handleOnLoadButton,
  personalizationColor,
  rows,
  handleChangePages,
  handleSearchUsers,
}) => {

  const classes = useStyles({
    personalizationColor: personalizationColor
  })
  // const theme = useTheme()

  //Filtro para paginación
  let filtered = []

  // manejo de estado
  const [selectedColumn, setSelectedColumn] = useState<any | null>(null)
  const [isDropdownUp, setDropdownUp] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState<string | undefined>('')
  const searchRef = useRef<{ value: string }>(null)
  const [isEditing, setEditing] = useState(false)
  const [currentData, setCurrentData] = useState<any>([])
  const [orderedColumns, setOrderedColumns] = useState<any>([])
  let component
  let config: Config = {
    cellSize: { lg: 2, md: 2, sm: 2, xs: 2 },
    justify: 'center',
    spacing: 2
  }

  const [currentFilter, setCurrentFilter] = useState({
    searchBar: true,
    option: false,
    dropdown: false
  })

  useEffect(() => {
    setCurrentData(data)
  }, [data])

  // metodos de renderización de la tabla
  const getDropDownState = (id: any) => {
    const column = orderedColumns.find(
      (item: any) => item.name === selectedColumn
    )
    const naturalOrder = !!column ? column.naturalOrder : false
    if (selectedColumn === id && !naturalOrder) {
      return (
        <ArrowDropUp
          id={id}
          className={classes.dropdownIcon}
          onClick={() => setDropdownUp(!isDropdownUp)}
        />
      )
    } else if (selectedColumn === id && naturalOrder) {
      return (
        <ArrowDropDown
          id={id}
          className={classes.dropdownIcon}
          onClick={() => setDropdownUp(!isDropdownUp)}
        />
      )
    } else {
      return (
        <ArrowDropDown
          id={id}
          className={classes.dropdownIcon}
          onClick={() => setDropdownUp(!isDropdownUp)}
        />
      )
    }
  }

  const SwitchComponent = (value: any, row: any, column: Column, personalizationColor: jsonColor ) => {
    const { component,haveIcon, handleIconClick } = column
    const { handleChange } = component

    const AntSwitch = withStyles((theme: any) => ({
      root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
      },
      switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary,
        '&$checked': {
          transform: 'translateX(12px)',
          color: theme.palette.common.white,
          '& + $track': {
            opacity: 1,
            backgroundColor: personalizationColor.primaryColor !== "" ? personalizationColor.primaryColor : theme.palette.primary.main,
            borderColor: personalizationColor.primaryColor !== "" ? `1px solid ${personalizationColor.primaryColor}` :`1px solid ${theme.palette.info.light}`,
          },
        },
      },
      thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
      },
      track: {
        border: `1px solid ${theme.palette.info.light}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.info.dark,
      },
      checked: {},
    }))(Switch);

    return (
      <Grid
        container
        direction='row'
        alignItems='center'
        alignContent='center'
        spacing={2}
        justifyContent={haveIcon ? 'flex-end' : 'center'}
        onMouseOver={() => {
          const trashButton = document.getElementById(row.id)
          if (!!trashButton) {
            trashButton.style.visibility = 'visible'
            trashButton.style.cursor = 'pointer'
          }
        }}
        onMouseLeave={() => {
          const trashButton = document.getElementById(row.id)
          if (!!trashButton) {
            trashButton.style.visibility = 'hidden'
          }
        }}
      >
        <Tooltip
          title={row.idstate === 6 ? "No es posible cambiar de estado a este usuario" :`Activar/Inactivar ${column.tooltipMessage}`}
          aria-label={`Activar/Inactivar ${column.name}`}
        >
          <AntSwitch
            checked={column.format(value)}
            name={column.tooltipMessage}
            disabled={globalDisabled}
            className={row.idstate === 6 ? classes.disabled_row : null!}
            onChange={(e: any) => {
              if(value !== 6){
                handleChange(e, row.id, column.id, row);
                setCurrentFilter({
                  searchBar: false,
                  option: false,
                  dropdown: false,
                });
              }
            }}
          />
        </Tooltip>
        {column.withIcon ? (
          <Tooltip
            title='Eliminar'
            aria-label='Eliminar'
            style={{ marginTop: 4, marginLeft: 2 }}
          >
            <TrashIcon
              id={row.id}
              style={{
                visibility: 'hidden',
                marginLeft: 4,
                justifyContent: 'center',
              }}
              color={personalizationColor?.primaryColor}
              width={'20px'}
              onClick={() => {
                handleIconClick(row.id)
              }}
            />
          </Tooltip>
        ) : null}
      </Grid>
    )
  }

  const typographyComponent = (value: any, row: any, column: Column) => {
    if (column.wordLength === undefined) column.key = undefined
    const result = !!column.key
      ? findFinalOfWord(value, column.wordLength, column.key)
      : value
    value = !!value ? value : ''

    return (
      <div>
        {value.length > column.wordLength ? (
          <Tooltip title={value}>
            <Typography
              className={classes.labels}
              onClick={() => {
                if (typeof handleCellClick === 'function')
                  handleCellClick(row, column)
              }}
              align={column.align}
              style={column.customClass ? column.customClass : undefined}
            >
              {column.format ? column.format(result) : result}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            className={classes.labels}
            onClick={() => {
              if (typeof handleCellClick === 'function')
                handleCellClick(row, column)
            }}
            align={column.align}
            style={!!column.customClass ? column.customClass : undefined}
          >
            {column.format ? column.format(result) : result}
          </Typography>
        )}
      </div>
    )
  }

  const getRowData = () => {
    if (currentFilter.searchBar) {
      filtered = filteredData
    } else if (currentFilter.option) {
      filtered = orderByOption
    } else if (currentFilter.dropdown) {
      filtered = orderedColumn
    } else {
      filtered = currentData
    }
    if (filtered.length === 0 && rows === 0) {
      return (
        <TableRow className={classes.row}>
          <TableCell
            colSpan={
              columns.length % 2 === 0 ? columns.length / 2 : columns.length / 2
            }
          />
          <TableCell>No se encontraron resultados</TableCell>
        </TableRow>
      )
    } else if(filtered.length === 0 && rows !== 0) {
      return (
        <TableRow className={classes.row}>
          <TableCell
            colSpan={
              columns.length % 2 === 0 ? columns.length / 2 : columns.length / 2
            }
          />
          <TableCell>Presiona la tecla Enter para filtar tu busqueda</TableCell>
        </TableRow>
      )
    } else {
      const filterCounter = havePagination
        ? filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : filtered
      return filterCounter.map((row: any, key: any) => {
        return (
          <TableRow
            hover
            role='checkbox'
            tabIndex={-1}
            key={key}
            className={
              paramToDisable !== undefined &&
                Boolean(row[paramToDisable]) === validateToDisable
                ? classes.disabled_row
                : globalDisabled
                  ? classes.disabled_row
                  : classes.styleCell
            }
          >
            {columns.map((column: Column) => {
              const value = row[column.id]
              return (
                <TableCell
                  classes={{ root: classes.root }}
                  key={column.id}
                  align={column.align}
                  size={'medium'}
                >
                  {stateSection(column, row, value)}
                </TableCell>
              )
            })}
          </TableRow>
        )
      })
    }
  }

  const stateSection = (column: Column, row: any, value: any) => {
    if (!!column.children) {
      return column.children(value, row)
    }

    if (!!column.component) {
      if (!!column.isVisible) {
        if (column.isVisible(row)) {
          return SwitchComponent(value, row, column, personalizationColor!)
        } else {
          return null
        }
      } else {
        return SwitchComponent(value, row, column, personalizationColor!)
      }
    }
    return typographyComponent(value, row, column)
  }

  const RecordNotFound = () => {
    return (
      <TableRow>
        <TableCell
          colSpan={
            columns.length % 2 === 0 ? columns.length / 2 : columns.length / 2
          }
        />
        <TableCell>No se encontraron resultados</TableCell>
      </TableRow>
    )
  }

  // metodos de control de paginación
  const handleChangePage = (_event: any, newPage: any, rowsPerPage: any) => {
  if (typeof handleChangePages === 'function') {
    handleChangePages(_event, newPage, rowsPerPage);
  }
  setPage(newPage);
  }

  const handleSearch = () => {
    if (typeof handleSearchUsers === 'function') {
      handleSearchUsers(search);
    }
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const orderedColumn = useMemo(() => {
    const haveBeenOrdered = orderedColumns.find(
      (item: any) => item.name === selectedColumn
    )
    if (haveBeenOrdered !== undefined) {
      let orderedData = []
      if (haveBeenOrdered.naturalOrder) {
        orderedData = data.sort((a, b) =>
          a[selectedColumn] === b[selectedColumn]
            ? 0
            : a[selectedColumn] > b[selectedColumn]
              ? 1
              : -1
        )
      } else {
        orderedData = data.sort((a, b) =>
          a[selectedColumn] === b[selectedColumn]
            ? 0
            : a[selectedColumn] < b[selectedColumn]
              ? 1
              : -1
        )
      }
      return orderedData
    } else {
      if (!!orderedColumns.length) {
        return data.sort((a, b) =>
          a[selectedColumn] === b[selectedColumn]
            ? 0
            : a[selectedColumn] > b[selectedColumn]
              ? 1
              : -1
        )
      } else {
        return data
      }
    }
  }, [data, orderedColumns, selectedColumn])

  const orderByDirection = (a: any, b: any, sortBy: any) => {
    let i = 0,
      result = 0;
    while (i < sortBy.length && result === 0) {
      const { prop, format = (value: any) => value, direction: sortByDirection } = sortBy[i];
      let direction;
      if (sortByDirection === "latest") {
        direction = -1;
      } else if (sortByDirection === "oldest") {
        direction = 1;
      } else {
        direction = sortByDirection;
      }
  
      const valueA = format(a[prop]);
      const valueB = format(b[prop]);
  
      result = direction * (valueA < valueB ? -1 : valueA > valueB ? 1 : 0);
      i++;
    }
    return result;
  };
  
  const orderByOption = useMemo(() => {
    const options = buttons.menuOrder?.options || [];
    const selectedOption = options[option];
  
    if (!selectedOption) {
      return currentData;
    }
  
    const selectedColumns = selectedOption.columns;
  
    return currentData.slice().sort((a: any, b: any) =>
      orderByDirection(a, b, selectedColumns)
    );
  }, [currentData, option, buttons.menuOrder]);

  const filteredData = useMemo(() => {
    return currentData.filter((item: any) => {
      for (const key in item) {
        if (
          item[key] !== undefined &&
          item[key] !== null &&
          typeof item[key] === 'string'
        ) {
          const filter =
            typeof item[key] === 'string' ? item[key].toLowerCase() : item[key]
          if (filter.includes(search?.toLowerCase())) {
            return true
          }
        }
      }
      return false
    })
  }, [currentData, search])

  if (!!buttons.children) {
    if (!!buttons.children().component) {
      component = buttons.children().component
    } else {
      component = buttons.children
    }
    if (!!buttons.children().config) {
      config = buttons.children().config
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        alignItems='center'
        direction='row'
        spacing={0}
        justifyContent={buttons.justify ? buttons.justify : 'flex-start'}
      >
        {buttons.searchBar ? (
          <Grid
            item
            lg={buttons.searchBar.cellSize.lg}
            md={buttons.searchBar.cellSize.md}
            sm={buttons.searchBar.cellSize.sm}
          >
            <InputSearch
              name='CustomersFilter'
              value={search}
              inputRef={searchRef}
              disabled={globalDisabled}
              onChange={() => {
                setSearch(
                  searchRef.current ? searchRef.current.value : undefined
                )
                setPage(0)
                setCurrentFilter({
                  searchBar: true,
                  option: false,
                  dropdown: false
                })
              }}
              onFocus={() => {
                setEditing(true)
              }}
              onBlur={() => {
                setEditing(false)
              }}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  if (filtered.length === 0) {
                    handleSearch();
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search className={classes.colorSearchAdornment} />
                  </InputAdornment>
                )
              }}
              variant='outlined'
              margin='dense'
              fullWidth
              classes={{root:classes.inputPropsColor}}
            />
          </Grid>
        ) : null}
        {buttons.menuOrder || buttons.exportButton ? (
          <Grid
            container
            item
            lg={3}
            md={3}
            sm={2}
            justifyContent={
              buttons.menuOrder ? buttons.menuOrder.justify : 'flex-start'
            }
            spacing={2}
          >
            {buttons.menuOrder ? (
              <Grid
                item
                container
                lg={buttons.menuOrder.cellSize.lg}
                md={buttons.menuOrder.cellSize.md}
                sm={buttons.menuOrder.cellSize.sm}
                justifyContent='center'
              >
                <MenuOrder
                  size='small'
                  disabled={globalDisabled}
                  personalizationcolor={personalizationColor}
                  options={
                    !!buttons.menuOrder
                      ? buttons.menuOrder.options.map((item) => item.label)
                      : []
                  }
                  selectItem={(opt: any) => {
                    setOption(opt)
                    setPage(0)
                    setSearch('')
                    setCurrentFilter({
                      searchBar: false,
                      option: true,
                      dropdown: false
                    })
                  }}
                />
              </Grid>
            ) : null}
            {buttons.exportButton ? (
              <Grid
                item
                lg={buttons.exportButton.cellSize.lg}
                md={buttons.exportButton.cellSize.md}
                sm={buttons.exportButton.cellSize.sm}
              >
                <IconEspecialButton
                  aria-controls='customized-menu'
                  aria-haspopup='true'
                  variant='contained'
                  size={'small'}
                  onClick={() => { }}
                  disabled={globalDisabled}
                  startIcon={<Launch />}
                >
                  Exportar
                </IconEspecialButton>
              </Grid>
            ) : null}
          </Grid>
        ) : null}
        {buttons.chargeButton?.cellSize && buttons.chargeButton?.show ? (
          <Grid
            item
            container
            lg={buttons.chargeButton.cellSize.lg}
            md={buttons.chargeButton.cellSize.md}
            sm={buttons.chargeButton.cellSize.sm}
          >            
             <IconEspecialButton
                className={classes.buttonMassiveCharge}
                aria-controls='customized-menu'
                aria-haspopup='true'
                variant='contained'
                size={'small'}
                onClick={() => !!handleOnLoadButton && handleOnLoadButton()}
                disabled={globalDisabled}
                startIcon={<Launch />}
                style={{textTransform: 'capitalize'}}
              >
                Carga Masiva
              </IconEspecialButton>
          </Grid>
        ) : null}
        {!!component ? (
          <Grid
            container
            item
            lg={config.cellSize.lg}
            md={config.cellSize.lg}
            sm={config.cellSize.lg}
            direction='row'
            alignItems='center'
            justifyContent={config.justify ? config.justify : 'flex-start'}
            spacing={config.spacing}
          >
            {component()}
          </Grid>
        ) : null}
      </Grid>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          aria-label='custom table'
          size='small'
          id='customTableScroll'
          className={classes.table}
        >
          <TableHead>
            <TableRow>
              {columns.map((column: any) => (
                <TableCell
                  key={column.id}
                  align='center'
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableCell}
                  onClick={() => {
                    if (column.haveDropDown) {
                      setSelectedColumn(column.id)
                      const haveBeenOrdered = orderedColumns.find(
                        (item: Column) => item.name === column.id
                      )
                      if (haveBeenOrdered !== undefined) {
                        setOrderedColumns([
                          ...orderedColumns.map((item: Column) =>
                            item === haveBeenOrdered
                              ? {
                                name: haveBeenOrdered.name,
                                naturalOrder: !haveBeenOrdered.naturalOrder
                              }
                              : item
                          )
                        ])
                      } else {
                        setOrderedColumns([
                          ...orderedColumns,
                          { name: column.id, naturalOrder: true }
                        ])
                      }
                      setCurrentFilter({
                        searchBar: false,
                        option: false,
                        dropdown: true
                      })
                    }
                  }}
                >
                  <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Typography className={classes.columnStyle}>
                      {column.label}
                    </Typography>
                    <Typography className={classes.columnStyle}>
                      {column.haveDropDown ? getDropDownState(column.id) : null}
                    </Typography>
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isEditing
              ? currentData.length > 0
                ? getRowData()
                : RecordNotFound()
              : currentData.length > 0
                ? getRowData()
                : RecordNotFound()}
          </TableBody>
        </Table>
      </TableContainer>
      {havePagination ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          style={{ display: 'flex', justifyContent: 'center' }}
          count={rows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newpage) => {handleChangePage(e, newpage, rowsPerPage);}}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from} - ${to} de ${count}`
          }
          labelRowsPerPage='Filas por página'
          nextIconButtonText='Siguiente'
          backIconButtonText='Anterior'
        />
      ) : null}
    </Paper>
  )
}

const useStyles = makeStyles((theme: Theme) => {
  return {
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `4px 0 0 4px`
      }
    },
    padding: '8px',
    '& .MuiTableCell-root': {
      padding: '6px'
    }
  },
  container: {
    maxHeight: 440,
    width: '100%'
  },
  card: {
    borderRadius: '10px',
    background: theme.palette.primary.main
  },
  paper: {
    width: '100% !important',
    padding: '10px 0px',
    background: theme.palette.common.white,
    borderRadius: theme.paletteDataTable.border.borderRadius,
    boxShadow: 'none'
  },
  table: {
    background: theme.palette.common.white,
    padding: '10px 10px 10px 10px',
    WebkitBoxShadow: '0px 0px 5px 0px rgba(186,180,186,1)',
    MozBoxShadow: '0px 0px 5px 0px rgba(186,180,186,1)',
    boxShadow: '0px 0px 5px 0px rgba(186,180,186,1)'
  },
  columnStyle: {
    fontSize: '14px',
    font: 'normal normal 600 14px/16px Muli',
    color: theme.paletteDataTable.table.columnsLetterColor
  },
  tableCell: (props: any) => ({
    height: '26px',
    fontSize: '14px',
    backgroundColor: props.personalizationColor.primaryColor !== "" ? props.personalizationColor.primaryColor : theme.paletteDataTable.table.columnsBackgroundColor, // Añade opacidad al tono
    color: theme.palette.common.white,
    padding: 2,
    '&:nth-child(1)': {
      borderRadius: '5px 0px 0px 5px'
    },
    '&:nth-last-child(1)': {
      borderRadius: '0px 5px 5px 0px'
    }
  }),
  // alignSwitch: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center'
  // },
  name: {
    color: 'blue'
  },
  hover: {
    '&:hover': {
      backgroundColor: 'rgb(7, 177, 77, 0.42)'
    }
  },
  labels: {
    font: 'normal normal normal 14px/19px Muli',
    letterSpacing: '0px',
    color: '#575756',
    cursor: 'pointer'
  },
  menuOrder: {
    '&&:hover': {
      background: theme.palette.secondary.main,
      color: `${theme.palette.text.secondary} !important`,
      border: 'none',
      boxShadow: 'none'
    }
  },
  row: {
    background: '#F6F6F6 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 4px #00000033',
    opacity: 1,
    height: '45px'
  },
  colorSearchAdornment: {
    color: '#C6C6C6'
  },
  dropdownIcon: {
    color: `${theme.paletteDataTable.table.columnsLetterColor} 0% 0% no-repeat padding-box`,
    opacity: 0.5,
    '&&:hover': {
      color: theme.paletteDataTable.table.columnsLetterColor,
      cursor: 'pointer',
      opacity: 1,
    }
  },
  disabled_row: {
    opacity: 0.4
  },
  styleCell: {
    '&&:hover': {
      backgroundColor: '#F6F6F6 !important'
    }
  },
  buttonMassiveCharge: (props:any) => ( {
    background: theme.palette.common.white,
    borderRadius: 5,
    border: '1px solid #A8A8A8',
    height: 37,
    padding: '0 14px',
    boxShadow: 'none',
    color: '#6D6E71 !important',
    opacity: '1',
    '&&:hover': {
      background: props.personalizationColor.secondaryColor !== "" ? props.personalizationColor.secondaryColor : theme.palette.secondary.main,
      color: `white !important`,
      boxShadow: 'none',
      border: 'none'
    }
  }),
  inputPropsColor: (props: any) => ({
    color:'white',
    "& .Mui-focused fieldset": {
      borderColor: props.personalizationColor.primaryColor! !== "" ?  `${props.personalizationColor.primaryColor} !important` :"#1D71B8 !important",
      borderWidth: '2px !important',
      borderStyle: 'solid !important',
    }
  })
}})