import React from 'react'

export const PaymentIcon = ({
    width = '14px',
    height = '17px',
    id
}: any) => {
    if(width === null)
        width = '14px';
        height = '17px';
        return (
            <svg
                id={id}
                width={width}
                height={height}
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                
                <path id="ico_pagos" data-name="ico pagos" d="M2.395,15.273A7,7,0,0,1,.071,11a8,8,0,0,0,13.858,0A7,7,0,0,1,2.395,15.273ZM0,7a7,7,0,1,1,7,7A7.008,7.008,0,0,1,0,7ZM4.477,9.223a2.67,2.67,0,0,0,.676.355,5.148,5.148,0,0,0,.836.23c.1.016.208.03.309.042v.943H7.7V9.819a3.191,3.191,0,0,0,.593-.162,1.964,1.964,0,0,0,.891-.646,1.842,1.842,0,0,0,.2-1.609,1.269,1.269,0,0,0-.339-.5,2.226,2.226,0,0,0-.657-.4,5.738,5.738,0,0,0-1.039-.3A5.469,5.469,0,0,1,6.575,6a.875.875,0,0,1-.342-.2.418.418,0,0,1-.086-.245.487.487,0,0,1,.26-.442A1.279,1.279,0,0,1,7.1,4.941a2.829,2.829,0,0,1,.869.142,3.073,3.073,0,0,1,.836.426l.5-1.12a2.043,2.043,0,0,0-.591-.346,4.388,4.388,0,0,0-.774-.229c-.087-.017-.163-.029-.236-.038V2.93H6.3v.888a2.683,2.683,0,0,0-.514.168,2.124,2.124,0,0,0-.866.695,1.644,1.644,0,0,0-.311.986,1.946,1.946,0,0,0,.1.623,1.194,1.194,0,0,0,.339.5,2.011,2.011,0,0,0,.662.394,5.336,5.336,0,0,0,1.07.3,5.431,5.431,0,0,1,.727.2,1,1,0,0,1,.349.212.412.412,0,0,1,.092.261.442.442,0,0,1-.267.386A1.684,1.684,0,0,1,6.9,8.68a3.5,3.5,0,0,1-.986-.142,3.217,3.217,0,0,1-.93-.434Z" fill="#6d6e71"/>
               
            </svg>
        )
}