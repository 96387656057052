import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

type ColorPickerProps = {
  colorCode?: string
  setColorCode: Function
  disabled?: boolean
}

export const ColorPicker = ({
  colorCode = '#FFFFFF',
  setColorCode,
  disabled
}: ColorPickerProps) => {
  const classes = useStyles()

  const setColorPicked = (evt: any) => {
    setColorCode(evt.target.value)
  }

  return (
    <Button
      variant='contained'
      classes={{
        root: classes.root,
        disabled: classes.buttonDisabled
      }}
      disabled={disabled}
      component='label'
    >
      <Grid container alignItems='center' spacing={1}>
        <div
          className={classes.colorSelector}
          style={{ backgroundColor: colorCode ? colorCode : 'grey' }}
        ></div>
        <Typography className={classes.typography}>{colorCode}</Typography>
        <input
          type='color'
          className={classes.input}
          onChange={setColorPicked}
        />
      </Grid>
    </Button>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    borderRadius: '10px',
    opacity: 1,
    width: '112px',
    height: '35px',
    position: 'relative'
  },
  colorSelector: {
    width: '25px',
    height: '20px',
    background: '0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    marginRight: 4
  },
  typography: {
    textAlign: 'left',
    font: 'italic normal normal 12px/17px Muli',
    letterSpacing: '0px',
    color: '#575757',
    opacity: 1
  },
  input: {
    visibility: 'hidden',
    left: '100%',
    position: 'absolute'
  },
  buttonDisabled: {
    opacity: '0.5 !important'
  }
}))
