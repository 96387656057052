import React from 'react'

export const PrivilegeIcon = ({
  style = {},
  width = '100%',
  className = '',
  viewBox = '0 0 32 32',
  color = '#009bdd',
  id
}: any) => {
  const fill = color
  return (
    <svg
      id={id}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={width}
      style={style}
      viewBox={viewBox}
      className={`svg-icon ${className || ''}`}
    >
      <g
        id='ico_privilegios_azul'
        data-name='ico privilegios azul'
        transform='translate(-442 -572)'
      >
        <g id='Grupo_472' data-name='Grupo 472' transform='translate(442 572)'>
          <path
            id='Trazado_773'
            data-name='Trazado 773'
            d='M462.842,582.263l-3.324-3.24a13.281,13.281,0,0,0-2.867-.367v0c-2.545,0-7.651,1.28-7.651,3.825v3.129h13.267Z'
            transform='translate(-449 -568.92)'
            fill={fill}
          />
          <path
            id='Trazado_774'
            data-name='Trazado 774'
            d='M455.31,578.955h.021a3.467,3.467,0,1,0-.021,0Z'
            transform='translate(-447.679 -572)'
            fill={fill}
          />
        </g>
        <path
          id='Trazado_775'
          data-name='Trazado 775'
          d='M464.114,575l2.149,4.355,4.806.7-3.477,3.39.821,4.786-4.3-2.26-4.3,2.26.821-4.786-3.478-3.39,4.806-.7Z'
          transform='translate(-3.223 1.389)'
          fill={fill}
        />
      </g>
    </svg>
  )
}
