import { Divider, Grid, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Search from '@material-ui/icons/Search'
import React from 'react'
import { Menu } from '../../models/MenuModels'
import { DrawMenu } from '../../components/DrawMenu'
import { SearchInput } from '../../components/Inputs/SearchInput'
import StyledMenu from '../../components/StyledMenu'

type TopMenuChildrenProps = {
  children: Array<Menu>
  anchorEl: EventTarget | null
  onClick?: Function
  functions: Array<any>
  handleClose: Function
  open: boolean
  menuKey: string
  isUnstable?: boolean
}

export const TopMenuChildren = React.forwardRef(
  ({ anchorEl, children, handleClose, open, functions }: TopMenuChildrenProps, ref) => {
    const classes = useStyles()
    const [enteredFilter, setEnteredFilter] = React.useState('')

    return (
      <div>
        <StyledMenu
          ref={ref}
          id='modal-menu-help'
          anchorEl={anchorEl}
          keepMounted
          classes={classes}
          open={open}
          onClose={handleClose}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          paddingtop={8}
          paddingbottom={8}
        >
          <div>
            <Grid
              container
              spacing={2}
              style={{ marginLeft: '8px' }}
              alignItems='flex-end'
            >
              <Grid item lg={1} md={1} xs={1}>
                <Search color='disabled' style={{ marginTop: '5px' }} />
              </Grid>
              <Grid item lg={11} md={11} xs={11}>
                <SearchInput
                  enteredFilter={enteredFilter}
                  setEnteredFilter={setEnteredFilter}
                  props={{
                    onBlur: () => setEnteredFilter('')
                  }}
                />
              </Grid>
            </Grid>
            <Divider classes={{ root: classes.divider }} />
            <DrawMenu
              menu={children.filter((item) =>
                item.name.toLowerCase().includes(enteredFilter.toLowerCase())
              )}
              ref={ref}
              functions={functions}
              isLargePadding={false}
            />
          </div>
        </StyledMenu>
      </div>
    )
  }
)

const useStyles = makeStyles((theme: Theme) => ({
  contentGrid: {
    margin: '10px',
    width: '280px',
    height: '300px',
    overflowX: 'hidden',
    paddingBottom: 18
  },
  divider: {
    backgroundColor: theme.palette.info.main,
    marginLeft: '5px',
    marginRight: '5px',
    height: '1px'
  },
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: 38,
    padding: 2,
    overflowX: 'unset',
    overflowY: 'unset',
    backgroundColor: theme.palette.background.paper,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '-16px',
      right: 0,
      width: 16,
      height: 15,
      backgroundColor: theme.palette.background.paper,
      borderColor: '#d3d4d5',
      borderStyle: 'solid',
      borderWidth: '1px 1px 0px 0px',
      borderRadius: 3,
      transform: 'translate(-50%, 50%) rotate(316deg)',
      clipPath:
        'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))'
    }
  },
  root: {
    minWidth: 200,
    borderBlockEnd: 'none',
    borderBlockStart: '1px solid #BDBDBD',
    background: '#fafafa',
    margin: '10px 0 0 0',
    borderLeftStyle: 'none',
    borderRightStyle: 'none'
  },
  title: {
    fontSize: 10,
    alignItems: 'center',
    display: 'flex',
    marginTop: 4
  },
  titleApp: {
    background: ''
  },
  titleContent: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px'
  },
  iconApp: {
    width: '80px',
    textAlign: 'center',
    padding: '10px 10px 10px 10px',
    cursor: 'pointer'
  },
  sizeIcon: {
    width: 62
  },
  buttonItem: {
    border: 0,
    // background: '#F5F5F5',
    cursor: 'pointer'
  }
}))
