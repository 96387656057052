import { makeStyles, Modal, Theme } from '@material-ui/core'
import React from 'react'

interface CustomModalProps {
  open: boolean
  onClose: Function
  children: JSX.Element
}

export const CustomModal = ({ onClose, open, children }: CustomModalProps) => {
  const classes = useStyles()
  return (
    <Modal
      open={open}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      onClose={() => onClose()}
      className={`${classes.modal}`}>
      <div
        // style={{ top: '30%', left: '40%' }} 
        className={classes.paperModal}>
        {children}
      </div>
    </Modal>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "relative",
    width: 400,
    title: {
      justifyContent: 'left'
    },
    backgroundColor: theme.palette.background.paper,
    borderRadius: `8px`,
    boxShadow: theme.shadows[5],
    opacity: 1
  },
  modalEnterprises: {
    height: '10vh',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.paper
  }
}))
