import { Grid, IconButton, Tooltip, makeStyles, RadioGroup, FormControlLabel, Typography, TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react'
import { ArrowIcon } from '../../assets/Icons/ArrowIcon';
import ControlledInputRoundedForm from '../../components/Inputs/ControlledInputRounded';
import { isEmpty, validateEquals } from '../../../src/utils/utils';
import FormLayout from '../../components/Layouts/FormLayout';
import TabComponent from '../../components/Tabs/TabComponent';
import FullLoader from '../../components/Loader/FullLoader.component';
import StyledRadioButton from '../../components/Button/StyledRadioButton';
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { HelpOutline } from '@material-ui/icons';
import { PrimaryButton } from '../../components/Button/PrimaryButton'
import { ActivateUser, CreateAndModifyUser, getUserExists } from '../../actions/user.action';
import { validateSessionStatus } from '../../actions/auth.action';
import useAsync from '../../hooks/useAsync';
import AlertConfirm from '../../components/Alert/AlertConfirm';
import { UserRole } from './UserRole';
import useConfirmationDialog from '../../hooks/useAlertConfirm';
// import { getAccessLevel } from '../../actions/accesslevel.action';
import { getBusinessUnit } from '../../actions/businessunit.action';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface businessunitInterface {
  id: number;
  code: string;
  name: string;
}

let isPrincipal = false

export const UserForm = (props: UserFormProps) => {

  /* #region  Props */
  const {
    user,
    setUser,
    setCardState,
    token,
    appName,
    customerInfo,
    exceuteGetAllUsers,
    useSharedRoles,
    rolesByFilter,
    currentPrivileges,
    personalizationColor
  } = props;
  /* #endregion */
  
  /* #region  States */
  const [tab, setTab] = useState(0);
  const [messageDialogData, setMessageDialogData] = useState("")
  const [dataAlertConfirm, setDataAlertConfirm] = useState<AlertConfirmUserFormProps>({
    message: (
      <div>
        Hiciste modificaciones
        <br />
        ¿Deseas actualizarlas?
      </div>
    ),
    cancelEdit: false,
    newTab: 0,
    back: false,
    changeTab: false
  });
  const [inputDuplicate, setInputDuplicate] = useState({
    userName: false,
    email: false,
  });
  const [viewErrors, setErrors] = useState("");
  const [isEditing, setIsEditing] = useState(user.id !== "" ? true : false);
  const [enabledForm, setEnabledForm] = useState(user.id !== "" ? false : true);
  const [inputExists, setInputExists] = useState({
    isUserName: true
  });
  const [msgActiveUser, setMsgActiveUser] = useState<AlertConfirmUserFormActivateUser>({
    open: false,
    botCancel: false,
    textButtonAccept: 'Aceptar',
    message: "",
  });
  const [typeNewUser, setTypeNewUser] = useState("newUser");
  const [deletedUser, setDeletedUser] = useState("");
  const [activeButtons, setActiveButtons] = useState(false);
  const [userData,] = useState<userProps>({
    id: user.id,
    names: user.names,
    surnames: user.surnames,
    email: user.email,
    document: user.document,
    userName: user.userName,
    phoneNumber: user.phoneNumber === "0" ? "" : user.phoneNumber,
    idbusinessunit: user.idbusinessunit,
    accessLevelId: user.accessLevelId
  });
  const [dataBusinessUnit, setDataBusinessUnit] = useState<businessunitInterface[]>([]);
  const searchInput = useRef(null);
  const [changeOption, setChangeOption] = useState(0);
  type typeCreateAndModify = {
    token: string,
    data: any,
    action: string,
    customer: any,
    roles: any
  }
  const [createAndModify, setCreateAndModify] = useState<typeCreateAndModify>({
    token: "",
    data: {},
    action: "",
    customer: [{}],
    roles: {}
  });

  /* #endregion */

  /* #region  Hooks */
  const classes = useStyles({personalizationColor:personalizationColor});

  const { Dialog: MessageDialog, onOpen: messageDialogOpen } = useConfirmationDialog({
    bodyText: messageDialogData,
  })

  const { Dialog, onOpen, onClose } = useConfirmationDialog({
    bodyText: dataAlertConfirm.message,
    cancelButtonText: 'No',
    confirmationButtonText: 'Si',
    onConfirmClick: () => handleAcceptAlert(),
    onCancelClick: () => handleCancelAlert()
  })

  const {
    result: resultGetUserExist,
    execute: executeGetUserExist,
    loading: loadingGetUserExist
  } = useAsync({
    asyncFunction: getUserExists
  })

  const {
    result: responseGetUserExist,
    execute: executeResponseGetUserExist,
  } = useAsync({
    asyncFunction: getUserExists
  })

  const {
    result: resultGetBusinessUnit,
    execute: executeGetBusinessUnit,
    loading: loadingGetBusinessUnit
  } = useAsync({
    asyncFunction: getBusinessUnit
  })

  const {
    result: resultActivateUser,
    execute: executeActivateUser,
    loading: loadingActivateUser
  } = useAsync({
    asyncFunction: ActivateUser
  })

  const {
    result: resultCreateAndModifyUser,
    execute: executeCreateAndModifyUser,
    loading: loadingCreateAndModifyUser
  } = useAsync({
    asyncFunction: CreateAndModifyUser
  })

  const {
    result: resultValidateSessionUser,
    execute: executeValidateSessionUser
  } = useAsync({
    asyncFunction: validateSessionStatus
  })

  // const {
  //   result: resultAccessLevel,
  //   // error: errorGetUserExist,
  //   execute: executeAccessLevel,
  //   loading: loadingAccessLevel
  // } = useAsync({
  //   asyncFunction: getAccessLevel
  // })

  /* #endregion */

  /* #region  Ref */
  const currentRolesSelected = useRef([]);
  const validationForChange = useRef(false);
  const originalInfo = useRef({
    originalData: {
      id: user.id,
      names: user.names,
      surnames: user.surnames,
      email: user.email,
      document: user.document,
      userName: user.userName,
      phoneNumber: user.phoneNumber === "0" ? "" : user.phoneNumber,
      idbusinessunit: user.idbusinessunit,
      accessLevelId: user.accessLevelId
    },
    originalRoles: []
  });
  /* #endregion */

  /* #region  React hook form */
  const requiredMessage = "Campo obligatorio.";
  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    names: Yup.string().max(100).required(requiredMessage),
    userName: Yup.string()
      .max(100)
      .test(
        "duplicate",
        "El nombre de usuario ingresado ya se encuentra utilizado",
        () => !inputDuplicate.userName
      )
      .required(requiredMessage),
    surnames: Yup.string().max(100).required(requiredMessage),
    document: Yup.string().max(13).required(requiredMessage),
    phoneNumber: Yup.string(),
    
    idbusinessunit: Yup.number().test("isNotZero", requiredMessage, (value) => currentPrivileges?.privileges.includes(`${currentPrivileges.app}_BUSINESSUNIT_REQUIRED`) ? value !== 0 : true).required(requiredMessage),
    // accessLevelId: Yup.number().test("isNotZero", requiredMessage, (value) => value !== 0).required(requiredMessage),
    email: Yup.string()
      .max(100)
      .email("El correo no posee el formato correcto.")
      .test(
        "duplicate",
        "El correo electrónico ingresado ya se encuentra utilizado",
        () => !inputDuplicate.email
      )
      .required(requiredMessage),
  });

  const {
    formState: { errors },
    trigger,
    getValues,
    setValue,
    setError,
    control,
    clearErrors,
  } = useForm({
    defaultValues: {
      ...userData
    },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  /* #endregion */

  /* #region  useEffect */
  // useEffect(() => {
  //   if (resultAccessLevel === undefined) {
  //     executeAccessLevel(token)
  //   }
  // }, []);

  useEffect(() => {
    if (resultGetBusinessUnit === undefined) {
      executeValidateSessionUser(token);
      executeGetBusinessUnit({
        token,
        documentNumber: customerInfo ? customerInfo.customerDocumentNumber : ""
      })
    }
  }, []);

  // useEffect(() => {
  //   if (resultAccessLevel !== undefined) {
  //     setDataAccessLevel(resultAccessLevel.message)
  //   }
  // }, [resultAccessLevel]);

  useEffect(() => {
    if (resultGetBusinessUnit !== undefined) {
      setDataBusinessUnit(resultGetBusinessUnit.message)
    }
  }, [resultGetBusinessUnit]);


  useEffect(() => {
    if (resultGetUserExist !== undefined) {
      if (resultGetUserExist.message.informationCode === "A20") {
        setDeletedUser(resultGetUserExist.message.users[0].id);
        setActiveButtons(true);
        setMsgActiveUser({
          open: true,
          textButtonAccept: "Aceptar",
          botCancel: true,
          message:
            "El nombre de usuario y/o correo ingresados ya ha sido utilizado por un usuario que ha sido eliminado. Selecciona una de las siguientes acciones para Continuar",
        });
        setInputDuplicate((state) => ({ ...state, [inputExists.isUserName ? 'userName' : 'email']: false }));
        clearErrors(inputExists.isUserName ? 'userName' : 'email');
      } else if (resultGetUserExist.message.informationCode === "A10") {
        setValue('userName', resultGetUserExist.message.users[0].username);
        setValue('email', resultGetUserExist.message.users[0].email);
        setValue('names', resultGetUserExist.message.users[0].names);
        setValue('surnames', resultGetUserExist.message.users[0].surnames);
        setValue('document', resultGetUserExist.message.users[0].document);
        setValue('phoneNumber', resultGetUserExist.message.users[0].phoneNumber);
        setValue('idbusinessunit', resultGetUserExist.message.users[0].idBusinessUnit);
        setEnabledForm(false);
      } else if (resultGetUserExist.message.informationCode === "A21") {
        setInputDuplicate((state) => ({ ...state, [inputExists.isUserName ? 'userName' : 'email']: true }));
        setError(inputExists.isUserName ? 'userName' : 'email', {
          type: "manual",
          message:
            inputExists.isUserName
              ? "El nombre de usuario ingresado ya se encuentra utilizado"
              : "El correo electrónico ingresado ya se encuentra utilizado",
        });
      }
      else {
        setInputDuplicate((state) => ({ ...state, [inputExists.isUserName ? 'userName' : 'email']: false }));
        clearErrors(inputExists.isUserName ? 'userName' : 'email');
      }
    }
  }, [resultGetUserExist]);

  useEffect(() => {
    if (responseGetUserExist !== undefined) {
      if (responseGetUserExist.message.informationCode === "A10") {
        if (responseGetUserExist.message.users[0].usersCustomer[0].principal === true) {
          isPrincipal = true;
        } else {
          isPrincipal = false;
        }
      }
    }
  }, [responseGetUserExist]);

  useEffect(() => {
    if (resultActivateUser !== undefined) {
      if (resultActivateUser.message.informationCode === "A10") {
        currentRolesSelected.current = [];

        const values = {
          ...resultActivateUser.message.user,
          phoneNumber:
            resultActivateUser.message.user.phoneNumber === "0"
              ? ""
              : resultActivateUser.message.user.phoneNumber,
        };
        setUser((state: any) => ({
          ...state,
          ...values,
        }));
        originalInfo.current = {
          originalData: values,
          originalRoles: []
        }
        for (const key in userData) {
          if (key === "phoneNumber") {
            setValue(
              key,
              resultActivateUser.message.user[key] === "0"
                ? ""
                : resultActivateUser.message.user[key]
            );
          } else {
            setValue(key, resultActivateUser.message.user[key]);
          }
        }
        setIsEditing(true);
        setEnabledForm(false);
        setActiveButtons(false);
        setInputDuplicate({ userName: false, email: false });
        clearErrors("userName");
        clearErrors("email");
        setMsgActiveUser(state => ({
          ...state,
          open: true,
          botCancel: false,
          message: resultActivateUser.message.information,
        }));
      } else {
        setMessageDialogData(resultActivateUser.message.information)
        messageDialogOpen();
        setValue(inputExists.isUserName ? 'userName' : 'email', "");
      }
      setTimeout(() => {
        setMsgActiveUser(state => ({
          ...state,
          open: false,
          botCancel: false,
          message: "",
        }));
      }, 1500);
    }
  }, [resultActivateUser]);


  useEffect(() => {
    if (resultCreateAndModifyUser !== undefined) {
      if (resultCreateAndModifyUser.statusCode === 201 || resultCreateAndModifyUser.statusCode === 200) {
        if (resultGetUserExist !== undefined) {
          if (resultGetUserExist.message.informationCode === "A10") {
            setMessageDialogData('¡El usuario fue actualizado con éxito!')
          } else {
            setMessageDialogData(user.id === ""
            ? "El usuario fue creado con éxito"
            : tab < 2
              ? " Los datos del usuario han sido actualizados"
              : '¡El usuario fue actualizado con éxito!')
          }
        } else {
          setMessageDialogData(user.id === ""
          ? "El usuario fue creado con éxito"
          : tab < 2
            ? " Los datos del usuario han sido actualizados"
            : '¡El usuario fue actualizado con éxito!')
        }
        messageDialogOpen();
        if (user.id === '') {
          setTimeout(() => {
            restartFields()
          }, 1500);
        } else if (tab === 0) {
          originalInfo.current.originalData = JSON.parse(
            JSON.stringify({ ...getValues() })
          );

        } else if (tab === 1) {
          if (!dataAlertConfirm.changeTab && !dataAlertConfirm.back && !dataAlertConfirm.cancelEdit) {
            setTimeout(() => {
              restartFields()
            }, 1500);
          } else {
            originalInfo.current.originalRoles = JSON.parse(
              JSON.stringify(currentRolesSelected.current)
            );
            activeValidations();
          }
        }
      } else {
        setErrors(resultCreateAndModifyUser.message)
      }
    }
  }, [resultCreateAndModifyUser]);

  useEffect(() => {
    return () => {
      restartFieldsAux()
    };
  }, []);

  useEffect(() => {
    if (resultValidateSessionUser !== undefined) {
      if (resultValidateSessionUser.statusCode == 200) {
        if (changeOption === 1) {
          executeActivateUser({
            id: deletedUser,
            token
          });
        }
        if (changeOption === 2) {
          executeGetUserExist({ userName: getValues().userName, token });
        }
        if (changeOption === 3) {
          executeGetUserExist({ email: getValues().email, token });
        }
        if (changeOption === 4) {
          executeCreateAndModifyUser(createAndModify);
        }
      } else {
        setInterval(() => { window.location.reload(); }, 5000);
      }
    }
  }, [resultValidateSessionUser]);

  useEffect(() => {
    if (!open) {
      setDataBusinessUnit([]);
    }
  }, [open]);
  /* #endregion */


  const restartFields = () => {
    setUser({ id: "", document: "", userName: "", names: "", surnames: "", email: "", phoneNumber: "", idbusinessunit: 0, accessLevelId: 2 })
    setCardState(false);
    currentRolesSelected.current = []
    exceuteGetAllUsers()
    clearErrors()
  }

  const restartFieldsAux = () => {
    setUser({ id: "", document: "", userName: "", names: "", surnames: "", email: "", phoneNumber: "", idbusinessunit: 0, accessLevelId: 2 })
    setCardState(false);
    currentRolesSelected.current = []
    clearErrors()
  }

  const activeValidations = () => {
    if (dataAlertConfirm.changeTab) {
      setTab(dataAlertConfirm.newTab);
    }

    if (dataAlertConfirm.back) {
      if (tab === 0) {
        setTimeout(() => {
          restartFields()
        }, 1500);
      } else {
        setTab(0);
      }
    }

    if (dataAlertConfirm.cancelEdit) {
      setEnabledForm(false);
    }
  };

  const handleAcceptAlert = async () => {
    await executeResponseGetUserExist({ userName: getValues().userName, token });
    saveUser();
    onClose()
  }

  const handleCancelAlert = () => {
    if (tab === 0) {
      for (const key in userData) {
        setValue(key, originalInfo.current.originalData[key]);
      }
    } else {
      validationForChange.current = true;
      currentRolesSelected.current = [];
      originalInfo.current.originalRoles = JSON.parse(
        JSON.stringify(currentRolesSelected.current)
      );
    }
    activeValidations()
    setDataAlertConfirm((state) => ({
      ...state,
      changeTab: false,
      newTab: 0,
      back: false,
      cancelEdit: false
    }))
    onClose()
  }

  const handleChangeTab = async (event: any, newTab: any) => {

    event.preventDefault();
    setErrors('')
    
    if (user.id === "") {
      const currentErrors = await trigger();
      if (!currentErrors) {
        return;
      }
      setTab(newTab)
    } else if (enabledForm) {
      if (!compareObjects()) {
        const currentErrors = await trigger();
        if (!currentErrors) {
          return;
        }
        setDataAlertConfirm((state) => ({
          ...state,
          changeTab: true,
          newTab: newTab,
          back: false,
          cancelEdit: false
        }))
        onOpen()
      } else {
        setTab(newTab)
      }
    } else {
      setTab(newTab)
    }
  }

  const backButton = async () => {
    if (tab === 0) {
      if (user.id === "") {
        restartFields();
      } else if (enabledForm) {
        if (!compareObjects()) {
          const currentErrors = await trigger();
          if (!currentErrors) {
            restartFields();
          }
          setDataAlertConfirm((state) => ({
            ...state,
            changeTab: false,
            newTab: 0,
            back: true,
            cancelEdit: false
          }))
          onOpen()
        } else {
          restartFields()
        }
      } else {
        restartFields()
      }
    } else {
      if (user.id === "") {
        setTab(0);
      } else if (enabledForm) {
        if (!compareObjects()) {
          setDataAlertConfirm((state) => ({
            ...state,
            changeTab: false,
            newTab: 0,
            back: true,
            cancelEdit: false
          }))
          onOpen()
        } else {
          setTab(0);
        }
      } else {
        setTab(0);
      }
    }
  }

  const compareObjects = () => {
    let response = false;
    switch (tab) {
      case 0:
        response = validateEquals(
          { ...getValues() },
          originalInfo.current.originalData
        );
        break;
      case 1:
        response = validateEquals({
          role: currentRolesSelected.current.sort((a: any, b: any) => {
            if (a.name > b.name) {
              return 1
            }

            if (a.name < b.name) {
              return -1
            }
            return 0
          }),
        }, {
          role: originalInfo.current.originalRoles.sort((a: any, b: any) => {
            if (a.name > b.name) {
              return 1
            }

            if (a.name < b.name) {
              return -1
            }
            return 0
          })
        });
        break;
      default:
        break;
    }
    return response;
  }

  const saveUser = async () => {
    if (user.id === "") {
      setErrors('')
      const currentErrors = await trigger();
      if (!currentErrors) {
        return;
      }
      const countRoleAssigned = currentRolesSelected.current.filter((x: any) => x.assigned)
      if (countRoleAssigned.length <= 0) {
        setErrors("*Se requiere mínimo un privilegio");
        return;
      } else {
        setErrors('')
      }
      if (resultGetUserExist !== undefined) {
        if (resultGetUserExist.message.informationCode === "A10") {
          executeCreateAndModifyUser({
            token,
            data: {
              id: resultGetUserExist.message.users[0].id,
              idbusinessunit: getValues().idbusinessunit,
              names: getValues().names,
              surnames: getValues().surnames,
              email: getValues().email,
              document: getValues().document,
              userName: getValues().userName,
              phoneNumber: getValues().phoneNumber,
              accessLevelId: getValues().accessLevelId,
            },
            action: 'modify',
            customer: resultGetUserExist !== undefined && resultGetUserExist.message.informationCode === "A10" ? [{
              documentType: customerInfo ? customerInfo.customerTypeDocument : "",
              assigned: resultGetUserExist.message.users[0].usersCustomer[0].principal === true ? true : true,
              documentNumber: customerInfo ? customerInfo.customerDocumentNumber : "",
              principal: resultGetUserExist.message.users[0].usersCustomer[0].principal === true ? true : false,
              name: customerInfo ? customerInfo.Name : ""
            }] : null,
            roles: currentRolesSelected.current.map((item: any) => { return { role: item.name, assigned: item.assigned } })
          })
        }
        else {
          executeCreateAndModifyUser({
            token,
            data: {
              ...getValues()
            },
            action: 'create',
            customer: [{
              documentType: customerInfo ? customerInfo.customerTypeDocument : "",
              assigned: true,
              documentNumber: customerInfo ? customerInfo.customerDocumentNumber : "",
              principal: true,
              name: customerInfo ? customerInfo.Name : ""
            }],
            roles: countRoleAssigned.map((item: any) => { return { role: item.name, assigned: item.assigned } })
          })
        }
      }

    } else {
      if (tab === 0) {
        const currentErrors = await trigger();
        if (!currentErrors) {
          return;
        }
        executeValidateSessionUser(token);
        setChangeOption(4);
        setCreateAndModify({
          token,
          data: {
            ...getValues()
          },
          action: 'modify',
          customer: [{
            documentType: customerInfo ? customerInfo.customerTypeDocument : "",
            assigned: isPrincipal === true ? true : true,
            documentNumber: customerInfo ? customerInfo.customerDocumentNumber : "",
            principal: isPrincipal === true ? true : false,
            name: customerInfo ? customerInfo.Name : ""
          }],
          roles: null
        })
      } else {
        const countRoleAssigned = currentRolesSelected.current.filter((x: any) => x.assigned).length
        if (countRoleAssigned <= 0) {
          setErrors("*Se requiere mínimo un privilegio");
          return;
        } else {
          setErrors('')
        }
        executeValidateSessionUser(token);
        setChangeOption(4);
        setCreateAndModify({
          token,
          data: {
            id: getValues().id
          },
          action: 'modify',
          customer: [{
            documentType: customerInfo ? customerInfo.customerTypeDocument : "",
            assigned: isPrincipal === true ? true : true,
            documentNumber: customerInfo ? customerInfo.customerDocumentNumber : "",
            principal: isPrincipal === true ? true : false,
            name: customerInfo ? customerInfo.Name : ""
          }],
          roles: currentRolesSelected.current.map((item: any) => { return { role: item.name, assigned: item.assigned } })
        })
      }
    }
  }

  const addSpaces = (text: string, breakpoint: number, spacesQty: number) => {
    if (breakpoint < 0) {
      throw new Error("Invalid break position");
    }
    let leftPart = text.substring(0, breakpoint);
    let rightPart = text.substring(breakpoint);
    [...Array(spacesQty)].map(() => {
      return (leftPart += " ");
    });
    return leftPart + rightPart;
  };

  const removeSpaces = (text: string) => {
    let rightPart = text.substring(6);
    return "(+57) " + rightPart.replace(/ /g, "");
  };

  const replaceIllegalCharacter = (value: any) => {
    var chars = value.split("");
    let response = "";
    for (const letra of chars) {
      if (letra.match("^[0-9\r]*$") !== null) {
        response += letra;
      }
    }
    return response;
  };

  const onChangePhoneNumber = (e: any) => {
    if (e.target.value.includes("(+57) ")) {
      let rightPart = e.target.value.substring(6);
      setValue("phoneNumber", "(+57) " + replaceIllegalCharacter(rightPart));
    } else {
      setValue("phoneNumber", "(+57) ");
    }
  };

  const onBlurPhoneNumber = () => {
    if (getValues().phoneNumber === "(+57) ") {
      // setStateShrink((state) => ({ ...state, phoneNumber: false }));
      setValue("phoneNumber", "");
    } else {
      if (getValues().phoneNumber.length === 14) {
        setValue("phoneNumber", addSpaces(getValues().phoneNumber, 7, 1));
      }

      if (getValues().phoneNumber.length === 16) {
        setValue("phoneNumber", addSpaces(getValues().phoneNumber, 9, 1));
      }
    }
  };

  const onFocusPhoneNumber = () => {
    // setStateShrink((state) => ({ ...state, phoneNumber: true }));
    if (
      getValues().phoneNumber === "" ||
      getValues().phoneNumber === undefined
    ) {
      setValue("phoneNumber", "(+57) ");
    } else {
      if (getValues().phoneNumber.includes("(+57) ")) {
        setValue("phoneNumber", removeSpaces(getValues().phoneNumber));
      } else {
        setValue("phoneNumber", "(+57) " + getValues().phoneNumber);
      }
    }
  };

  const closeMessage = () => {
    if (typeNewUser === "newUser") {
      setValue(inputExists.isUserName ? 'userName' : 'email', "");
    } else {
      executeValidateSessionUser(token);
      setChangeOption(1);
    }
    setMsgActiveUser(state => ({
      ...state,
      open: false,
      botCancel: false,
      message: "",
    }));
    setActiveButtons(false);
  }

  const handleChange = (event: any) => {
    setTypeNewUser(event.target.value);
  };

  const defineSubHeader = () => {
    let subheader = "";
    if (user.id !== "") {
      switch (tab) {
        case 0:
          subheader = enabledForm
            ? "Editar datos básicos"
            : "Detalle del usuario";
          break;
        case 1:
          subheader = enabledForm ? "Editar usuario" : "Detalles del usuario";
          break;
        case 2:
          subheader = enabledForm ? "Editar usuario" : "Detalles del usuario";
          break;
        default:
          subheader = "Nuevo usuario";
          break;
      }
    } else {
      subheader = "Nuevo usuario";
    }
    return subheader;
  };

  const handleChangeEdit = (response: boolean) => {
    if (!response) {
      if (!compareObjects()) {
        setDataAlertConfirm((state) => ({
          ...state,
          changeTab: false,
          newTab: 0,
          back: false,
          cancelEdit: true
        }))
        onOpen()
      } else {
        setEnabledForm(response);
      }
    } else {
      setEnabledForm(response);
    }
  }

  return (
    <FormLayout
      subheader={defineSubHeader()}
      handleSubmit={(e) => e.preventDefault()}
      handleIconClick={() => {
        backButton()
      }}
      isEditing={isEditing}
      enabledForm={enabledForm}
      setEnabledForm={handleChangeEdit}
      personalizationColor={personalizationColor}
    >
      <div>
        <TabComponent
          aria='user tabs'
          tab={tab}
          handleChangeTab={handleChangeTab}
          content={[
            {
              label: 'Datos',
              tabContent: (
                <Grid container>
                  <Grid item container direction='row' spacing={2}>
                    <Grid item lg={5} md={10} sm={12}>
                      <ControlledInputRoundedForm
                        name="userName"
                        label="Nombre de usuario"
                        personalizationColor = {personalizationColor}
                        inputProps={{ maxLength: 100 }}
                        pattern={/[^a-zA-Z0-9._\-@]/g}
                        disabled={!enabledForm}
                        fullWidth
                        control={control}
                        error={errors.userName}
                        helperText={errors.userName?.message}
                        onBlur={() => {
                          if (
                            !isEmpty(getValues().userName) &&
                            getValues().userName !== originalInfo.current.originalData.userName
                          ) {
                            setInputExists({ isUserName: true });
                            executeValidateSessionUser(token);
                            setChangeOption(2);
                          } else {
                            clearErrors("userName");
                            setInputDuplicate((state) => ({
                              ...state,
                              userName: false,
                            }));
                          }
                        }}
                      />
                    </Grid>
                    <Grid item lg={5} md={10} sm={12}>
                      <ControlledInputRoundedForm
                        name="email"
                        label="Correo electrónico"
                        personalizationColor = {personalizationColor}
                        inputProps={{ maxLength: 100 }}
                        disabled={!enabledForm}
                        pattern={/[^a-zA-Z0-9._\-@]/g}
                        fullWidth
                        control={control}
                        error={errors.email}
                        helperText={errors.email?.message}
                        onBlur={async () => {
                          const currentErrorsEmail = await trigger("email");
                          if (!currentErrorsEmail) {
                            return;
                          }
                          if (
                            !isEmpty(getValues().email) &&
                            getValues().email !== originalInfo.current.originalData.email
                          ) {
                            setInputExists({ isUserName: false });
                            executeValidateSessionUser(token);
                            setChangeOption(3);
                          } else {
                            clearErrors("email");
                            setInputDuplicate((state) => ({
                              ...state,
                              email: false,
                            }));
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container direction='row' spacing={2}>
                    <Grid item lg={5} md={10} sm={12}>
                      <ControlledInputRoundedForm
                        name="names"
                        label="Nombres"
                        personalizationColor = {personalizationColor}
                        fullWidth
                        control={control}
                        pattern={/[^a-zA-ZÑñÁÉÍÓÚáéíóú\s]/g}
                        disabled={!enabledForm}
                        inputProps={{ maxLength: 100 }}
                        error={errors.names}
                        helperText={errors.names?.message}
                      />
                    </Grid>
                    <Grid item lg={5} md={10} sm={12}>
                      <ControlledInputRoundedForm
                        name="surnames"
                        label="Apellidos"
                        personalizationColor = {personalizationColor}
                        pattern={/[^a-zA-ZÑñÁÉÍÓÚáéíóú\s]/g}
                        inputProps={{ maxLength: 100 }}
                        disabled={!enabledForm}
                        fullWidth
                        control={control}
                        error={errors.surnames}
                        helperText={errors.surnames?.message}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container direction='row' spacing={2}>
                    <Grid item lg={5} md={10} sm={12}>
                      <ControlledInputRoundedForm
                        name="document"
                        label="Número de documento"
                        personalizationColor = {personalizationColor}
                        disabled={!enabledForm}
                        pattern={/[^a-zA-Z0-9]/g}
                        inputProps={{ maxLength: 13 }}
                        fullWidth
                        control={control}
                        error={errors.document}
                        helperText={errors.document?.message}
                      />
                    </Grid>
                    <Grid item lg={6} md={10} sm={12}>
                      <Grid container direction="row" alignItems="center" spacing={1} lg={11}>
                        <Grid container item lg={11} md={11} sm={11}>
                          <ControlledInputRoundedForm
                            name="phoneNumber"
                            label="Teléfono fijo o celular"
                            personalizationColor = {personalizationColor}
                            disabled={!enabledForm}
                            inputProps={{ maxLength: 16 }}
                            fullWidth
                            control={control}
                            error={errors.phoneNumber}
                            helperText={errors.phoneNumber?.message}
                            onChange={(e: any) => onChangePhoneNumber(e)}
                            onFocus={onFocusPhoneNumber}
                            onBlur={onBlurPhoneNumber}
                          />
                        </Grid>
                        <Grid container item lg={1} md={1} sm={1}>
                          <Tooltip
                            title={
                              <div>
                                Teléfono Fijo: Ej. 1 1234567
                                <br />
                                Celular: Ej. 123 1234567
                              </div>
                            }
                          >
                            <HelpOutline className={classes.helpIcon} />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container direction='row' spacing={2} >
                    <Grid item lg={5} md={10} sm={12}>
                      <Controller
                        name="idbusinessunit"
                        control={control}
                        render={({ field: { onChange, value}}) => (
                          <Autocomplete
                            id="idbusinessunit"
                            options={dataBusinessUnit}
                            style={{ marginTop: '10px'}}
                            getOptionLabel={(option) => option.code  + " - " + option.name}
                            value={dataBusinessUnit.find((option) => option.id === value) || undefined}
                            disabled={ resultGetUserExist && resultGetUserExist.message.informationCode === "A10" ? enabledForm : !enabledForm }
                            onChange={(_, newValue) => {
                              onChange(newValue);
                              setValue("idbusinessunit", newValue?.id!);
                            }}
                            noOptionsText={'No se encuentran opciones'}
                            disableClearable
                            renderInput={(params:any) => {
                              return(
                                <TextField
                                  {...params}
                                  inputRef={searchInput}
                                  variant="outlined"
                                  label="Unidad de negocio"
                                  control={control}
                                  error={errors.idbusinessunit}
                                  helperText={errors.idbusinessunit?.message}
                                  disableAnimation
                                  classes={{root:classes.inputPropsColor}}
                                />
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {/* <Grid item lg={5} md={10} sm={12}>
                      <ControlledInputRoundedForm
                        name="accessLevelId"
                        select
                        disabled={!enabledForm}
                        label="Nivel de acceso"
                        fullWidth
                        control={control}
                        error={errors.accessLevelId}
                        helperText={errors.accessLevelId?.message}
                      >
                        {
                          dataAccessLevel.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))
                        }
                      </ControlledInputRoundedForm>
                    </Grid> */}
                  </Grid>
                  {!!user.id ? (
                    <Grid container item lg={10} md={10} sm={12} justify="center" style={{ marginTop: '15px' }}>
                      <PrimaryButton
                        title={"Actualizar"}
                        props={{
                          disabled: !enabledForm,
                          onClick: async () => {
                            if (!compareObjects()) {
                              await executeResponseGetUserExist({ userName: getValues().userName, token });
                              saveUser()
                            } else {
                              setMessageDialogData('No hay cambios para guardar')
                              messageDialogOpen();
                            }
                          }
                        }}
                        fullWidth={false}
                        personalizationColor={personalizationColor}
                      />

                    </Grid>
                  ) : null}
                  <Grid
                    container
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    direction="row-reverse"
                  >
                    <Tooltip
                      id="button-next"
                      title="Siguiente"
                      style={{ float: "right", marginTop: "18px" }}
                    >
                      <IconButton onClick={(e: any) => handleChangeTab(e, 1)}>
                        <ArrowIcon className={classes.nextArrow} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )
            },
            {
              label: 'Roles',
              tabContent: (
                <Grid container>
                  <UserRole
                    isEnabled={ resultGetUserExist && resultGetUserExist.message.informationCode === "A10" ? !enabledForm : enabledForm }
                    userId={ resultGetUserExist && resultGetUserExist.message.informationCode === "A10" ? resultGetUserExist.message.users[0].id : user.id }
                    token={token}
                    appName={appName}
                    currentRolesSelected={currentRolesSelected}
                    originalInfo={originalInfo}
                    validationForChange={validationForChange}
                    useSharedRoles={useSharedRoles}
                    rolesByFilter={rolesByFilter}
                    personalizationColor={personalizationColor}
                  />

                  <Grid container item lg={12} md={12} sm={12} justifyContent="center">
                    <PrimaryButton
                      title={user.id === "" ? 'Guardar' : 'Actualizar'}
                      props={{
                        disabled: resultGetUserExist && resultGetUserExist.message.informationCode === "A10" ? enabledForm : !enabledForm,
                        onClick: async (e: any) => {
                          e.preventDefault()
                          if (user.id === "") {
                            await executeResponseGetUserExist({ userName: getValues().userName, token });
                            saveUser()
                          } else {
                            if (!compareObjects()) {
                              await executeResponseGetUserExist({ userName: getValues().userName, token });
                              saveUser();
                            } else {
                              setMessageDialogData('No hay cambios para guardar')
                              messageDialogOpen();
                            }
                          }
                        }
                      }}
                      fullWidth={false}
                      personalizationColor={personalizationColor}
                    />

                  </Grid>
                  <Grid item lg={12} md={12} sm={12}>
                    {viewErrors !== "" && (
                      <div className={classes.errorText}>
                        <Typography color="error" variant="subtitle2">
                          {viewErrors}
                        </Typography>
                      </div>
                    )}
                  </Grid>
                </Grid>
              )
            }
          ]}
          personalizationColor={personalizationColor}
        />
        <AlertConfirm
          isOpen={msgActiveUser.open}
          bodyText={msgActiveUser.message}
          onConfirmClick={closeMessage}
          isSecondStyle={activeButtons ? true : undefined}
          confirmationButtonText={"Aceptar"}
          additionalButtons={
            activeButtons ? (
              <RadioGroup
                aria-label="optionUserNew"
                name="optionUserNew"
                value={typeNewUser}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="newUser"
                  control={
                    <StyledRadioButton checked={typeNewUser === "newUser"} />
                  }
                  label="Crear nuevo usuario."
                />
                <FormControlLabel
                  value="actUser"
                  control={<StyledRadioButton />}
                  label="Reactivar usuario."
                />
                <br />
              </RadioGroup>
            ) : (
              ""
            )
          }
        />
        <FullLoader open={
          loadingGetUserExist ||
          loadingActivateUser ||
          loadingCreateAndModifyUser ||
          // loadingAccessLevel ||
          loadingGetBusinessUnit}
          viewLoader={true} />
        <MessageDialog />
        <Dialog />
      </div>
    </FormLayout >
  )
}

const useStyles = makeStyles((theme: any) => {
  return {
    buttonSave: {
      margin: "10px 15px",
      flex: 1,
      float: "right",
      boxShadow: `0px 4px 3px ${theme.palette.common.grey}`,
      borderRadius: 10,
    },
    errorText: {
      padding: "10px 15px",
    },
    nextArrow: (props: any) => ({
      stroke:  props.personalizationColor.secondaryColor! !== "" ? props.personalizationColor.secondaryColor : theme.palette.secondary.main,
    }),
    helpIcon: (props: any) => ({
      color: props.personalizationColor.secondaryColor! !== "" ? props.personalizationColor.secondaryColor : theme.palette.secondary.main,
    }),
    inputPropsColor: (props: any) => ({
      color:'white',
      "& .Mui-focused fieldset": {
        borderColor: props.personalizationColor.primaryColor! !== "" ?  `${props.personalizationColor.primaryColor} !important` :"#1D71B8 !important",
        borderWidth: '2px !important',
        borderStyle: 'solid !important',
      }
    })
  }
});