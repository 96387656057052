import React from 'react'

export const MenuSettingsIcon = ({
  style = {},
  width = '100%',
  className = '',
  color = '#009bdd',
  id
}: any) => {
  const fill = color

  return (
    <svg
      id={id}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={width}
      style={style}
      viewBox='0 0 25.85 15.764'
      className={`svg-icon ${className || ''}`}
    >
      <g
        id='Capa_1'
        data-name='Capa 1'
        transform='translate(-161.207 -393.627)'
      >
        <path
          id='Trazado_781'
          data-name='Trazado 781'
          d='M173.99,403.9l-.538-.755.783-1.371-.5-.145H162.657a1.45,1.45,0,0,0,0,2.9h11.86A7.8,7.8,0,0,1,173.99,403.9Z'
          transform='translate(0 3.596)'
          fill={fill}
        />
        <path
          id='Trazado_782'
          data-name='Trazado 782'
          d='M172.6,399.73l.152-.915,1.521-.416-.416-.773h-11.2a1.45,1.45,0,0,0,0,2.9h9.879A7.718,7.718,0,0,1,172.6,399.73Z'
          transform='translate(0 1.798)'
          fill={fill}
        />
        <path
          id='Trazado_783'
          data-name='Trazado 783'
          d='M175.844,396.329l.754-.538,1.29.735h.1l.432-1.47.916-.136a7.965,7.965,0,0,1,2.134-.007,1.433,1.433,0,0,0-1.416-1.286H162.657a1.45,1.45,0,0,0,0,2.9h12.953C175.689,396.464,175.76,396.388,175.844,396.329Z'
          fill={fill}
        />
        <path
          id='Sustracción_1'
          data-name='Sustracción 1'
          d='M172.639,407.276h0a6.516,6.516,0,0,1-1.418-1.447l.878-1.542a5.187,5.187,0,0,1-.331-.818l-1.7-.5a6.552,6.552,0,0,1,.019-2.026l1.713-.468c.043-.139.1-.274.152-.407a3.248,3.248,0,0,1,.187-.393l-.846-1.571a6.522,6.522,0,0,1,1.447-1.419l1.541.878a5.377,5.377,0,0,1,.818-.329l.5-1.7a6.51,6.51,0,0,1,2.026.017l.468,1.713q.209.067.409.152a3.354,3.354,0,0,1,.393.187l1.568-.847a6.485,6.485,0,0,1,1.419,1.445L181,399.74a5.19,5.19,0,0,1,.33.818l1.7.5a6.5,6.5,0,0,1-.019,2.025l-1.712.47c-.045.139-.1.274-.154.407a3.248,3.248,0,0,1-.187.393l.851,1.56a6.553,6.553,0,0,1-1.445,1.421l-1.548-.87a4.92,4.92,0,0,1-.818.33l-.5,1.7a6.5,6.5,0,0,1-2.025-.019l-.468-1.712c-.138-.045-.274-.1-.407-.152a3.486,3.486,0,0,1-.393-.188l-1.558.852Zm4.718-7.166a2.071,2.071,0,1,0,1.26,1.922A2.07,2.07,0,0,0,177.357,400.11Z'
          transform='translate(3.95 0.824)'
          fill={fill}
        />
      </g>
    </svg>
  )
}
