import React from 'react'

export const UserIcon = ({
  style = {},
  width = '100%',
  className = '',
  viewBox = '0 0 32 32',
  color = '#009bdd',
  id
}: any) => {
  const fill = color
  return (
    <svg
      id={id}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={width}
      style={style}
      viewBox={viewBox}
      className={`svg-icon ${className || ''}`}
    >
      <path
        id='ico_usuarios'
        data-name='ico usuarios'
        d='M19.262,13.333a2.976,2.976,0,1,0-2.976-2.976A2.967,2.967,0,0,0,19.262,13.333Zm-8.929-1.19A3.571,3.571,0,1,0,6.762,8.571,3.557,3.557,0,0,0,10.333,12.143Zm8.929,3.571c-2.179,0-6.548,1.1-6.548,3.274v2.679h13.1V18.988C25.809,16.809,21.44,15.714,19.262,15.714Zm-8.929-1.19C7.56,14.524,2,15.917,2,18.69v2.976h8.333V18.988c0-1.012.393-2.786,2.821-4.131A14.623,14.623,0,0,0,10.333,14.524Z'
        transform='translate(-2 -5)'
        fill={fill}
      />
    </svg>
  )
}
